import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Firmware } from '../models/firmware.model';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  private firmwaresUri = `${environment.endPointApi}/firmware`

  constructor(
    private http: HttpClient,
  ) { }

  getFirmwares(options): Observable<Firmware[]> {
    const query = this.buildQuery(options);
    return this.http.get<Firmware[]>(`${this.firmwaresUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Firmware[]>('getFirmwares', []))
    );
  }

  getFirmware(id: string): Observable<Firmware> {
    const uri = `${this.firmwaresUri}/crud/${id}`;
    return this.http.get<Firmware>(uri).pipe(
      catchError(this.handleError<Firmware>('getFirmware', null))
    );
  }

  postFirmware (contenedor: Firmware): Observable<any> {
    const uri = `${this.firmwaresUri}/crud`;
    return this.http.post<Firmware>(uri, contenedor).pipe(
      catchError(this.handleError<Firmware>('postFirmware', null))
    );
  }

  putFirmware(id: string, contenedor: Firmware): Observable<any> {
    const uri = `${this.firmwaresUri}/crud/${id}`;
    return this.http.put<Firmware>(uri, contenedor).pipe(
      catchError(this.handleError<Firmware>('postFirmware ', null))
    );
  }

  getFirmwareCount(): Observable<any> {
    const uri = `${this.firmwaresUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getFirmwareCount', {total: 0}))
    );
  }

  deleteFirmware(id: string): Observable<any> {
    const uri = `${this.firmwaresUri}/crud/${id}`;
    return this.http.delete<Firmware>(uri).pipe(
      catchError(this.handleError<Firmware>('deleteFirmware', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
