import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from 'src/app/auth/auth.service';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuariosService } from '../usuarios.service';

@Component({
  selector: 'app-dashboard-usuarios',
  templateUrl: './dashboard-usuarios.component.html',
  styleUrls: ['./dashboard-usuarios.component.scss']
})
export class DashboardUsuariosComponent implements OnInit {

  usuarios: Array<Usuario> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;

  constructor(
    private authService: AuthService,
    private usuariosService: UsuariosService,
    private toastService: ToastService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
  }

  getUsuarios(): void {

    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.usuariosService.getUsuarioCount().subscribe(
      resumen => this.total = resumen.total
    );
    this.usuariosService.getUsuarios(options).subscribe(
      usuarios => this.usuarios = usuarios
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getUsuarios();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Usuarios',
        content: { heading: nombre, description: '¿Desea eliminar este usuario?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.usuariosService.deleteUsuario(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El usuario fue eliminado correctamente');
              this.getUsuarios();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
