import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';

import { Electronica } from '../models/electronica.model';

@Injectable({
  providedIn: 'root'
})
export class ElectronicasService {

  private electronicasUri = `${environment.endPointApi}/electronica`;

  constructor(
    private http: HttpClient,
  ) { }

  getBasicData() {
    const uri = `${this.electronicasUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  getElectronicasCount(): Observable<any> {
    const uri = `${this.electronicasUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getElectronicasCount', {total: 0}))
    );
  }

  getElectronicas(options): Observable<Electronica[]> {
    const query = this.buildQuery(options);
    return this.http.get<Electronica[]>(`${this.electronicasUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Electronica[]>('getElectronicas', []))
    );
  }

  getElectronica(id: string): Observable<Electronica> {
    const uri = `${this.electronicasUri}/crud/${id}`;
    return this.http.get<Electronica>(uri).pipe(
      catchError(this.handleError<Electronica>('getElectronica', null))
    );
  }

  putElectronica(id: string, electronica: Electronica): Observable<any> {
    const uri = `${this.electronicasUri}/crud/${id}`;
    return this.http.put<Electronica>(uri, electronica).pipe(
      catchError(this.handleError<Electronica>('putElectronica', null))
    );
  }

  postElectronica(electronica: Electronica): Observable<any> {
    const uri = `${this.electronicasUri}/crud`;
    return this.http.post<Electronica>(uri, electronica).pipe(
      catchError(this.handleError<Electronica>('postElectronica', null))
    );
  }

  deleteElectronica(id: string): Observable<any> {
    const uri = `${this.electronicasUri}/crud/${id}`;
    return this.http.delete<Electronica>(uri).pipe(
      catchError(this.handleError<Electronica>('deleteElectronica', null))
    );
  }

  getElectronicasAvailablesByProyecto(proyecto: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.electronicasUri}/rest/availables/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getElectronicasAvailablesByProyecto', []))
    );
  }

  getElectronicasByProyecto(proyecto: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.electronicasUri}/rest/proyecto/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getElectronicasByProyecto', []))
    );
  }

  getElectronicasByFirmware(firmware: string, options: any): Observable<any[]> {
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${this.electronicasUri}/rest/firmware/${firmware}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getElectronicasByFirmware', []))
    );
  }

  udpateFirmwareVersion(version: any, anillos: Array<String>) {
    const uri = `${this.electronicasUri}/rest/firmware/${version.version}?ip=${version.ip}&port=${version.port}`;
    return this.http.put<any>(uri, anillos).pipe(
      catchError(this.handleError<any>('udpateFirmwareVersion', null))
    );
  }

  cleanFirmwareVersion(version: any, anillos: Array<String>) {
    const uri = `${this.electronicasUri}/rest/firmware/${version.version}/clean`;
    return this.http.put<any>(uri, anillos).pipe(
      catchError(this.handleError<any>('cleanFirmwareVersion', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
