import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Proyecto } from 'src/app/models/proyecto.model';
import { ProyectosService } from '../proyectos.service';

@Component({
  selector: 'app-dashboard-proyectos',
  templateUrl: './dashboard-proyectos.component.html',
  styleUrls: ['./dashboard-proyectos.component.scss']
})
export class DashboardProyectosComponent implements OnInit {

  proyectos: Array<Proyecto> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;

  constructor(
    private proyectosService: ProyectosService,
    private toastService: ToastService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
  }

  getProyectos(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.proyectosService.getProyectoCount().subscribe(
      resumen => this.total = resumen.total
    );
    this.proyectosService.getProyectos(options).subscribe(
      proyectos => this.proyectos = proyectos
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getProyectos();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Proyectos',
        content: { heading: nombre, description: '¿Desea eliminar este proyecto?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.proyectosService.deleteProyecto(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El proyecto fue eliminado correctamente');
              this.getProyectos();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

}
