<table mdbTable small="true"hover="true">
  <thead>
    <tr>
      <th [mdbTableSort]="productos" sortBy="producto">Productos <mdb-icon fas icon="sort"></mdb-icon></th>
      <th [mdbTableSort]="productos" sortBy="bonus" class="text-center">Bonificado <mdb-icon fas icon="sort"></mdb-icon></th>
      <th [mdbTableSort]="productos" sortBy="total" class="text-right">Total <mdb-icon fas icon="sort"></mdb-icon></th>
    </tr>
  </thead>
  <tbody>
    <tr mdbTableCol *ngFor="let p of productos">
      <td>
        <a routerLink="/{{ uri }}-producto/{{ p.producto }}/{{ uri === 'contenedores' ? contenedor : usuario }}"
          [queryParams]="{inicio: inicio, fin: fin}">
          {{ p.nombre || p.producto }}
        </a>
      </td>
      <td class="text-center">{{p.bonus ? 'Si' : 'No'}}</td>
      <td class="text-right">{{p.total}}</td>
    </tr>
  </tbody>
</table>
<app-pagination [total]="total" limit=10 (changePage)="onChangePage($event)"></app-pagination>
