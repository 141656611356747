<section class="my-3">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <mdb-card cascade="true" narrower="true">
          <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
            <h3 class="white-text mx-3">Usuario</h3>
          </div>
          <mdb-card-body>
            <form class="border border-light p-4" [formGroup]="formUsuario" (ngSubmit)="saveUsuario()">
              <div class="form-row">
                <div class="col col-lg-4">
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                    <label for="nombre">Nombre</label>
                    <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="apellidos" class="form-control" formControlName="apellidos">
                    <label for="apellidos">Apellidos</label>
                    <mdb-error *ngIf="apellidos.invalid && (apellidos.dirty || apellidos.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="apellidos.valid && (apellidos.dirty || apellidos.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="correo" class="form-control" formControlName="correo">
                    <label for="correo">Correo</label>
                    <mdb-error *ngIf="correo.invalid && (correo.dirty || correo.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="correo.valid && (correo.dirty || correo.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="password" id="password" class="form-control" formControlName="password">
                    <label for="password">Contraseña</label>
                    <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="password.valid && (password.dirty || password.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form" [hidden]="!isSuperAdmin">
                    <mdb-select-2 label="Proyecto" id="proyecto" formControlName="proyecto">
                      <mdb-select-option *ngFor="let proyecto of proyectos" [value]="proyecto.uuid">{{proyecto.nombre}}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                  <div class="md-form">
                    <mdb-select-2 [multiple]=true id="grupos" label="Grupos" formControlName="grupos">
                      <mdb-select-option *ngFor="let grupo of grupos" [value]="grupo.uuid">{{grupo.nombre}}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                  <div class="md-form" [hidden]="!isSuperAdmin">
                    <mdb-checkbox formControlName="admin">Admin</mdb-checkbox>
                  </div>
                  <div class="md-form" [hidden]="!isSuperAdmin">
                    <mdb-checkbox formControlName="client">Cliente</mdb-checkbox>
                  </div>
                </div>
              </div>
              <button mdbBtn color="danger" routerLink="/usuarios"  mdbWavesEffect size="sm">Cancelar</button>
              <button *ngIf="formUsuario.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
              <button *ngIf="formUsuario.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
            </form>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </section>
