import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-anillo-sensores',
  templateUrl: './anillo-sensores.component.html',
  styleUrls: ['./anillo-sensores.component.scss']
})
export class AnilloSensoresComponent implements OnInit {

  @Input() anillo: any;
  iconNames = [ 'user', 'prescription-bottle', 'fill-drip', 'battery-full', 'battery-full', 'barcode', 'wave-square', 'box', 'network-wired' ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  gotoAnillo() {
    this.router.navigate([`/anillos-anillo/${this.anillo.serial}`]);
  }

  getIconSensor(sensor: number){
    return this.iconNames[sensor];
  }

  getSensorColor(ok: number, total: number) {
    let icon='';
    const avg = total ? ok / total : 0;
    if(avg <= 0.2)
      icon = 'mr-1 red-text';
    else if(avg > 0.2 && avg <= 0.5)
      icon = 'mr-1 amber-text';
    else if(avg > 0.5 && avg <= 0.9)
      icon = 'mr-1 orange-text';
    else
      icon = 'mr-1 green-text';
    return icon;
  }
}
