import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UsuariosService } from '../usuarios.service';
import { ResumenDiario } from 'src/app/models/resumen-diario.model';

@Component({
  selector: 'app-usuarios-bonus-chart',
  templateUrl: './usuarios-bonus-chart.component.html',
  styleUrls: ['./usuarios-bonus-chart.component.scss']
})
export class UsuariosBonusChartComponent implements OnInit {

  @Input() name: string;
  @Input() producto: string;
  @Input() usuario: string;
  @Input() contenedor: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'bar';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(82, 182, 154, .75)',
      borderColor: 'rgba(82, 182, 154, .75)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(153, 217, 140, .75)',
      borderColor: 'rgba(153, 217, 140, .75)',
      borderWidth: 0,
    },
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Bonificados por día' },
    scales: {
      yAxes: [{
        ticks: { beginAtZero: true, precision: 0 },
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor(
    private usuariosService: UsuariosService,
  ) { }

  ngOnInit(): void {
    this.name = this.name ? `${this.name}.productosByDiaChart` : 'productosByDiaChart'
    this.loadUsers()
  }

  loadUsers() {
    const options: any = {};
    if (this.producto)
      options.producto = this.producto;
    if (this.contenedor)
      options.contenedor = this.contenedor;
    if (this.usuario)
      options.usuario = this.usuario;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    this.usuariosService.getUsuarioResumenBonus(options).subscribe(
      usuarios => this.createChartByDia(usuarios)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadUsers();
  }

  createChartByDia(usuarios: ResumenDiario[]) {
    this.chartLabels = usuarios.map(row => row.dia);
    this.chartDatasets = [
      {
        label: 'Bonificado',
        data: usuarios.map(row => row.bonus),
        backgroundColor: 'rgba(1, 0, 0, 0.5)'
      },
      {
        label: 'No Bonificado',
        data: usuarios.map(row => row.nobonus),
        backgroundColor: 'rgba(0, 0, 1, 0.5)'
      },
    ];
  }

}
