import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavComponent } from './comunes/nav/nav.component';
import { BreadcrumbComponent } from './comunes/breadcrumb/breadcrumb.component';
import { DashboardProductoComponent } from './productos/dashboard-producto/dashboard-producto.component';
import { DashboardContenedoresComponent } from './contenedores/dashboard-contenedores/dashboard-contenedores.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/authinterceptor';
import { UsuariosHoraChartComponent } from './usuarios/usuarios-hora-chart/usuarios-hora-chart.component';
import { UsuariosDiarioChartComponent } from './usuarios/usuarios-diario-chart/usuarios-diario-chart.component';
import { UsuariosTopListComponent } from './usuarios/usuarios-top-list/usuarios-top-list.component';
import { ProductosTopListComponent } from './productos/productos-top-list/productos-top-list.component';
import { DashboardUsuarioComponent } from './usuarios/dashboard-usuario/dashboard-usuario.component';
import { DashboardProductosComponent } from './productos/dashboard-productos/dashboard-productos.component';
import { DashboardUsuariosComponent } from './usuarios/dashboard-usuarios/dashboard-usuarios.component';
import { DashboardContenedorComponent } from './contenedores/dashboard-contenedor/dashboard-contenedor.component';
import { ContenedoresTopListComponent } from './contenedores/contenedores-top-list/contenedores-top-list.component';
import { ModalComponent } from './comunes/modal/modal.component';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContenedorComponent } from './contenedores/contenedor/contenedor.component';
import { ToastModule } from 'ng-uikit-pro-standard';
import { ProductoComponent } from './productos/producto/producto.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { HereMapComponent } from './here-map/here-map.component';
import { SearchComponent } from './comunes/search/search.component';
import { ProductosErrorChartComponent } from './productos/productos-error-chart/productos-error-chart.component';
import { PaginationComponent } from './comunes/pagination/pagination.component';
import { RegisterComponent } from './register/register.component';
import { JwtModule } from '@auth0/angular-jwt';
import { MapContenedoresComponent } from './contenedores/map-contenedores/map-contenedores.component';
import { DashboardFirmwaresComponent } from './firmware/dashboard-firmwares/dashboard-firmwares.component';
import { FirmwareComponent } from './firmware/firmware/firmware.component';
import { DashboardFirmwareComponent } from './firmware/dashboard-firmware/dashboard-firmware.component';
import { BateriaInfoChartComponent } from './contenedores/bateria-info-chart/bateria-info-chart.component';
import { BateriaMinmaxChartComponent } from './contenedores/bateria-minmax-chart/bateria-minmax-chart.component';
import { LlenadoMinmaxChartComponent } from './contenedores/llenado-minmax-chart/llenado-minmax-chart.component';
import { UsuariosBonusChartComponent } from './usuarios/usuarios-bonus-chart/usuarios-bonus-chart.component';
import { DashboardAnillosComponent } from './anillos/dashboard-anillos/dashboard-anillos.component';
import { DashboardGruposComponent } from './grupos/dashboard-grupos/dashboard-grupos.component';
import { DashboardProyectosComponent } from './proyectos/dashboard-proyectos/dashboard-proyectos.component';
import { AnilloComponent } from './anillos/anillo/anillo.component';
import { ProyectoComponent } from './proyectos/proyecto/proyecto.component';
import { GrupoComponent } from './grupos/grupo/grupo.component';
import { DashboardIncidenciasComponent } from './incidencias/dashboard-incidencias/dashboard-incidencias.component';
import { IncidenciaComponent } from './incidencias/incidencia/incidencia.component';
import { DashboardAnilloComponent } from './anillos/dashboard-anillo/dashboard-anillo.component';
import { AnillosSensorChartComponent } from './anillos/anillos-sensor-chart/anillos-sensor-chart.component';
import { ContenedorBonificadosChartComponent } from './contenedores/contenedor-bonificados-chart/contenedor-bonificados-chart.component';
import { ContenedorEstatusChartComponent } from './contenedores/contenedor-estatus-chart/contenedor-estatus-chart.component';
import { ContenedorIncentivablesChartComponent } from './contenedores/contenedor-incentivables-chart/contenedor-incentivables-chart.component';
import { DashboardElectronicasComponent } from './electronicas/dashboard-electronicas/dashboard-electronicas.component';
import { ElectronicasComponent } from './electronicas/electronicas/electronicas.component';

import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { CommonModule } from '@angular/common';
import { AnilloSensoresComponent } from './anillos/anillo-sensores/anillo-sensores.component';
import { ConfigurationDashboardComponent } from './configuration/configuration-dashboard/configuration-dashboard.component';
import { ConfigurationModalComponent } from './configuration/configuration-modal/configuration-modal.component';
import { IncidenciasModalComponent } from './incidencias/incidencias-modal/incidencias-modal.component';

PlotlyModule.plotlyjs = PlotlyJS;

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    NavComponent,
    BreadcrumbComponent,
    DashboardProductoComponent,
    DashboardContenedoresComponent,
    LoginComponent,
    UsuariosHoraChartComponent,
    UsuariosDiarioChartComponent,
    UsuariosTopListComponent,
    ProductosTopListComponent,
    DashboardUsuarioComponent,
    DashboardProductosComponent,
    DashboardUsuariosComponent,
    DashboardContenedorComponent,
    ContenedoresTopListComponent,    
    ModalComponent,
    ContenedorComponent,
    ProductoComponent,
    UsuarioComponent,
    HereMapComponent,
    SearchComponent,
    ProductosErrorChartComponent,
    PaginationComponent,
    RegisterComponent,
    MapContenedoresComponent,
    DashboardFirmwaresComponent,
    FirmwareComponent,
    DashboardFirmwareComponent,
    BateriaInfoChartComponent,
    BateriaMinmaxChartComponent,
    LlenadoMinmaxChartComponent,
    UsuariosBonusChartComponent,
    DashboardAnillosComponent,
    DashboardGruposComponent,
    DashboardProyectosComponent,
    AnilloComponent,
    ProyectoComponent,
    GrupoComponent,
    DashboardIncidenciasComponent,
    IncidenciaComponent,
    DashboardAnilloComponent,
    AnillosSensorChartComponent,
    ContenedorBonificadosChartComponent,
    ContenedorEstatusChartComponent,
    ContenedorIncentivablesChartComponent,
    DashboardElectronicasComponent,
    ElectronicasComponent,
    AnilloSensoresComponent,
    ConfigurationDashboardComponent,
    ConfigurationModalComponent,
    IncidenciasModalComponent,
  ],
  imports: [
    CommonModule,
    PlotlyModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:3000'],
      }
    }),
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
  ],
  providers: [
    MDBSpinningPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  entryComponents: [ModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
