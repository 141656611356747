<mdb-card class="chart-settings">
  <mdb-card-body class="pb-1">
    <div class="row py-0">
      <div class="col-lg-3 col-sm-12">
        <mdb-select label="Rango de fechas" [options]="dateOptionsSelect" placeholder="Elige periodo de tiempo" (selected)="onSelectChange($event.value)"></mdb-select>
      </div>
      <div class="col-lg-3 col-sm-5 px-4 datepickers">
        <div class="md-form mr-2">
          <mdb-date-picker name="inicio" #datePickerInicio
            label="Desde"
            [options]="myDatePickerOptions"
            [placeholder]="'Fecha Inicial'"
            [locale]="'es'"
            [(ngModel)]="inicio"
            required
            (dateChanged)="onInicioDatePicker($event)"
            >
          </mdb-date-picker>
        </div>
      </div>
      <div class="col-lg-3 col-sm-5 px-4 datepickers">
        <div class="md-form">
          <mdb-date-picker name="fin" #datePickerFin
            label="Hasta"
            [options]="myDatePickerOptions"
            [placeholder]="'Fecha Final'"
            [locale]="'es'"
            [(ngModel)]="fin"
            required
            (dateChanged)="onFinDatePicker($event)"
            >
          </mdb-date-picker>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1 col-sm-2">
        <button mdbBtn type="button" color="success" mdbWavesEffect (click)="exportAsCSV()">
          CSV <mdb-icon fas icon="file-export"></mdb-icon>
        </button>
      </div>
    </div>
    <div *ngIf="additionalOptions" class="row">
      <div class="col-lg-2 col-md-4">
        <mdb-select *ngIf="opcion1"
          [label]="opcion1.label"
          [options]="opcion1.options"
          (selected)="onOpcion1SelectChange($event.value)">
        </mdb-select>
      </div>
      <div class="col-lg-2 col-md-4">
        <mdb-select *ngIf="opcion2"
          [label]="opcion2.label"
          [options]="opcion2.options"
          (selected)="onOpcion2SelectChange($event.value)">
        </mdb-select>
      </div>
      <div class="col-lg-2 col-md-4">
        <mdb-select *ngIf="opcion3"
          [label]="opcion3.label"
          [options]="opcion3.options"
          (selected)="onOpcion3SelectChange($event.value)">
        </mdb-select>
      </div>
      <div class="col-lg-2 col-md-4">
        <mdb-select *ngIf="opcion4"
          [label]="opcion4.label"
          [options]="opcion4.options"
          (selected)="onOpcion4SelectChange($event.value)">
        </mdb-select>
      </div>
      <div class="col-lg-2 col-md-4">
        <mdb-select *ngIf="opcion5"
          [label]="opcion5.label"
          [options]="opcion5.options"
          (selected)="onOpcion5SelectChange($event.value)">
        </mdb-select>
      </div>
    </div>
  </mdb-card-body>
</mdb-card>
<!-- <mdb-select [options]="dateOptionsSelect" placeholder="Elegir rango" (selected)="onChange($event.value)"></mdb-select> -->