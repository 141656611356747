<section class="my-3">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <mdb-card cascade="true" narrower="true">
          <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
            <h3 class="white-text mx-3">Telecarga</h3>
          </div>
          <mdb-card-body>
            <form class="border border-light p-4" [formGroup]="formFirmware" (ngSubmit)="saveFirmware()">
              <div class="form-row">
                <div class="col col-lg-6">
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                    <label for="nombre">Nombre</label>
                    <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="version" class="form-control" formControlName="version">
                    <label for="version">ID</label>
                    <mdb-error *ngIf="version.invalid && (version.dirty || version.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="version.valid && (version.dirty || version.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="ip" class="form-control" formControlName="ip">
                    <label for="ip">IP</label>
                    <mdb-error *ngIf="ip.invalid && (ip.dirty || ip.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="ip.valid && (ip.dirty || ip.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                  <div class="md-form">
                    <input mdbInput mdbValidate type="text" id="puerto" class="form-control" formControlName="puerto">
                    <label for="puerto">Puerto</label>
                    <mdb-error *ngIf="puerto.invalid && (puerto.dirty || puerto.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                    <mdb-success *ngIf="puerto.valid && (puerto.dirty || puerto.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                  </div>
                </div>
              </div>
              <button mdbBtn color="danger" routerLink="/firmwares"  mdbWavesEffect size="sm">Cancelar</button>
              <button *ngIf="formFirmware.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
              <button *ngIf="formFirmware.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
            </form>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </section>
