<header>
  <section class="view intro intro-2">
    <div class="full-bg-img rgba-stylish-strong mask flex-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">
            <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
                <mdb-card-body>
                  <!--Header-->
                  <div class="form-header primary-color">
                    <h3><mdb-icon fas icon="user" class="mt-2 mb-2"></mdb-icon> ecoReader</h3>
                  </div>
                  <!--Body-->
                  <form [formGroup]="formLogin" (ngSubmit)="login()">
                    <div class="md-form">
                      <input type="text" mdbInput mdbValidate formControlName="usuario" id="usuario" class="form-control">
                      <label for="usuario" class="">Usuario</label>
                      <mdb-error *ngIf="usuario.invalid && (usuario.dirty || usuario.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo Requerido</mdb-error>
                      <mdb-success *ngIf="usuario.valid && (usuario.dirty || usuario.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                    </div>
                    <div class="md-form">
                      <input type="password" mdbInput mdbValidate id="clave" formControlName="clave" class="form-control">
                      <label for="clave" class="">Contraseña</label>
                      <mdb-error *ngIf="clave.invalid && (clave.dirty || clave.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo requerido</mdb-error>
                      <mdb-success *ngIf="clave.valid && (clave.dirty || clave.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                    </div>
                    <div class="text-center">
                      <button *ngIf="formLogin.invalid" class="waves-effect waves-light primary-color text-white" mdbBtn
                        (click)="showValidacion()" size="lg" mdbWavesEffect type="button">
                          Ingresar
                      </button>
                      <button *ngIf="formLogin.valid" class="waves-effect waves-light primary-color text-white" mdbBtn
                        size="lg" mdbWavesEffect type="submit">
                        Ingresar
                      </button>
                    </div>
                  </form>
                </mdb-card-body>
              </mdb-card>
          </div>
          </div>
        </div>
    </div>
  </section>
</header>
