import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

import { Electronica } from 'src/app/models/electronica.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ElectronicasService } from '../electronicas.service';
import { AnillosService } from 'src/app/anillos/anillos.service';
import { GruposService } from 'src/app/grupos/grupos.service';

import 'dayjs/locale/es'
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';


@Component({
  selector: 'app-electronicas',
  templateUrl: './electronicas.component.html',
  styleUrls: ['./electronicas.component.scss']
})
export class ElectronicasComponent implements OnInit {
  id: string;
  formElectronica: FormGroup;
  proyectos: Array<any>;
  grupos: Array<any>;
  anillos: Array<any>;

  constructor(
    private electronicasService: ElectronicasService,
    private gruposService: GruposService,
    private anillosService: AnillosService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formElectronica = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      serial: new FormControl('', [Validators.required]),
      anillo: new FormControl(''),
      proyecto: new FormControl('', [Validators.required]),
      grupos: new FormControl(''),
      tarjeta: new FormControl(''),
      firmware: new FormControl(''),
      database: new FormControl(''),
      fecha: new FormControl(''),
    });
    this.formElectronica.get('proyecto').valueChanges.subscribe((proyecto: string) => {
      this.gruposService.getGruposByProyecto(proyecto).subscribe((grupos: Array<any>) => {
        this.grupos = grupos;
      });
      this.anillosService.getAnillosAvailablesByProyecto(proyecto).subscribe((anillos: Array<any>) => {
        this.anillos = [{serial: '', nombre: '', contenedor: ''}].concat(anillos);
      });
    });
  }

  loadBasicData() {
    this.electronicasService.getBasicData().subscribe( (data: any) => {
      this.proyectos = data.proyectos;
      this.grupos = data.grupos;
      this.anillos = data.anillos;
      this.formElectronica.get('proyecto').setValue(data.proyecto || '', { emitEvent: false });
    });
  }

  loadFormData() {
    this.electronicasService.getElectronica(this.id).subscribe(
      (res: any) => {
        if (res?.electronica === undefined)
        this.toastService.warning('Electronica no existe en base de datos');
        else {
          const electronica: Electronica = res.electronica;
          const now = electronica.fecha ? dayjs(electronica.fecha) : dayjs();
          const fecha = now.format('YYYY-MM-DDTHH:mm');
          this.proyectos = res.proyectos;
          this.anillos = [{uuid: '', nombre: ''}].concat(res.anillos);
          this.grupos = res.grupos;
          this.formElectronica.get('nombre').setValue(electronica.nombre);
          this.formElectronica.get('serial').setValue(electronica.serial);
          this.formElectronica.get('anillo').setValue(electronica.anillo);
          this.formElectronica.get('tarjeta').setValue(electronica.tarjeta);
          this.formElectronica.get('firmware').setValue(electronica.firmware);
          this.formElectronica.get('database').setValue(electronica.database);
          this.formElectronica.get('proyecto').setValue(electronica.proyecto, {emitEvent: false});
          this.formElectronica.get('grupos').setValue(electronica.grupos);
          this.formElectronica.get('fecha').setValue(fecha);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveElectronica() {
    const fecha = this.formElectronica.get('fecha').value;
    const serial = this.formElectronica.get('serial').value;
    const electronica: Electronica = new Electronica (
      this.formElectronica.get('nombre').value,
      serial ? serial.padStart(16, '0') : ''.padStart(16, '0'),
      this.formElectronica.get('anillo').value,
      this.formElectronica.get('tarjeta').value,
      this.formElectronica.get('firmware').value,
      this.formElectronica.get('database').value,
      this.formElectronica.get('proyecto').value,
      this.formElectronica.get('grupos').value,
      dayjs(fecha).toDate(),
    );
    if (this.id)
      this.updateElectronica(electronica);
    else
      this.createElectronica(electronica);
  }

  createElectronica(electronica: Electronica) {
    this.electronicasService.postElectronica(electronica).subscribe(
      (doc: any) => {
        if (doc.error)
          this.toastService.error(`Electrónica existe actualmente`);
        else
          this.toastService.success(`Electrónica creado correctamente`);
        this.router.navigate(['/electronicas']);
      },
      (error: any) => {
        console.log(`Electrónica - Create: ${error}`);
        this.toastService.error("No se pudo crear la electrónica");
      }
    );
  }

  updateElectronica(electronica: Electronica) {
    this.electronicasService.putElectronica(this.id, electronica).subscribe(
      (prev: any) => {
        this.toastService.success(`Electrónica actualizada correctamente`);
        this.router.navigate(['/electronicas']);
      },
      (error: any) => {
        console.log(`Electronica - Update: ${error}`);
        this.toastService.error("No se pudo crear la electronica");
      }
    );
  }

  showValidacion() {
    this.formElectronica.controls.nombre.markAsTouched();
    this.formElectronica.controls.serial.markAsTouched();
    this.formElectronica.controls.proyecto.markAsTouched();
  }

  get nombre() { return this.formElectronica.get('nombre'); }
  get serial() { return this.formElectronica.get('serial'); }
  get proyecto() { return this.formElectronica.get('proyecto'); }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
