import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ReciclosService } from 'src/app/reciclos/reciclos.service';

import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { RecicloEstatus } from 'src/app/models/reciclo-estatus.model';

@Component({
  selector: 'app-contenedor-estatus-chart',
  templateUrl: './contenedor-estatus-chart.component.html',
  styleUrls: ['./contenedor-estatus-chart.component.scss']
})
export class ContenedorEstatusChartComponent implements OnInit {

  @Input() datamatrix: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'bar';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(101, 166, 3, .85)',
      borderColor: 'rgba(101, 166, 3, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(171, 217, 4, .85)',
      borderColor: 'rgba(171, 217, 4, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(215, 242, 119, .85)',
      borderColor: 'rgba(215, 242, 119, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(174, 191, 111, .65)',
      borderColor: 'rgba(174, 191, 111, .95)',
      borderWidth: 0,
    },
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Resumen de Estatus por Día' },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          precision: 0
        },
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor(
    private reciclosService: ReciclosService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadData();
  }

  loadData(): void {
    const options = { inicio: this.inicio, fin: this.fin};
    this.reciclosService.getReciclosEstatusByDatamatrix(this.datamatrix, options).subscribe(
      reciclos => this.createChart(reciclos)
    );
  }

  createChart(reciclos: Array<RecicloEstatus>) {
    const ok = [];
    const timeout = [];
    const noentregado = [];
    const retirado = [];
    const calibracion = [];
    const labels = [];
    const data = {};
    reciclos.forEach(reciclo => {
      data[`${reciclo.fecha}`] = { 
        ok: reciclo.ok,
        timeout: reciclo.timeout,
        noentregado: reciclo.noentregado,
        retirado: reciclo.retirado,
        calibracion: reciclo.calibracion,
      };
    });
    let dia = dayjs(this.inicio);
    while (dia.toISOString() < this.fin) {
      const fecha = dia.format('YYYYMMDD');
      const hasProperty = data.hasOwnProperty(fecha);
      ok.push(hasProperty ? data[fecha].ok : 0);
      timeout.push(hasProperty ? data[fecha].timeout : 0);
      noentregado.push(hasProperty ? data[fecha].noentregado : 0);
      retirado.push(hasProperty ? data[fecha].retirado : 0);
      calibracion.push(hasProperty ? data[fecha].calibracion : 0);
      labels.push(dia.format('ddd DD'));
      dia = dia.add(1, 'day');
    }
    this.chartLabels = labels;
    this.chartDatasets = [
      {label: 'OK', data: ok},
      {label: 'Timeout', data: timeout},
      {label: 'No entregado', data: noentregado},
      {label: 'Retirado', data: retirado},
      {label: 'Calibración', data: calibracion},
    ];
  }
}
