<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="onNoClick()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
</div>
<div class="modal-body">
  <p>{{ content.description }}</p>
  <div class="md-form">
    <form class="border border-light p-4" [formGroup]="formConfiguration">
      <input mdbInput mdbValidate type="text" id="nombreSintoma" class="form-control" formControlName="nombreSintoma" [readonly]="content.action === 'delete'">
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" mdbBtn color="danger" class="waves-light" aria-label="Close" (click)="onNoClick()" mdbWavesEffect>Cancelar</button>
  <button *ngIf="content.action === 'create'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onCreateClick()">Crear</button>
  <button *ngIf="content.action === 'edit'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onSaveClick()">Guardar</button>
  <button *ngIf="content.action === 'delete'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onYesClick()">Borrar</button>
  
</div>
