import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AuthService } from 'src/app/auth/auth.service';
import { Incidencia } from 'src/app/models/incidencia.model';
import { IncidenciasService } from '../incidencias.service';
import { ContenedoresService } from 'src/app/contenedores/contenedores.service';
import { GruposService } from 'src/app/grupos/grupos.service';
import { AnillosService } from 'src/app/anillos/anillos.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { ElectronicasService } from 'src/app/electronicas/electronicas.service';
import { ConfigurationService } from 'src/app/configuration/configuration.service';
import { IncidenciasModalComponent } from '../incidencias-modal/incidencias-modal.component';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';

import 'dayjs/locale/es'
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-incidencia',
  templateUrl: './incidencia.component.html',
  styleUrls: ['./incidencia.component.scss']
})
export class IncidenciaComponent implements OnInit {

  id: string;
  formIncidencia: FormGroup;
  responsables: Array<any>;
  proyectos: Array<any>;
  grupos: Array<any>;
  contenedores: Array<any>;
  anillos: Array<any>;
  estados: Array<any>;
  electronicas: Array<any>;
  fallas: Array<any>;
  correcciones: Array<any>;
  entradas: Array<any>;
  modalRef: MDBModalRef;

  constructor(
    private modalService: MDBModalService,
    private incidenciasService: IncidenciasService,
    private contenedoresService: ContenedoresService,
    private gruposService: GruposService,
    private anillosService: AnillosService,
    private usuariosService: UsuariosService,
    private authService: AuthService,
    private electronicasService: ElectronicasService,
    private configurationService: ConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formIncidencia = new FormGroup({
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      estado: new FormControl('', [Validators.required]),
      responsable: new FormControl(''),
      proyecto: new FormControl('', [Validators.required]),
      grupo: new FormControl(''),
      contenedor: new FormControl(''),
      anillo: new FormControl(''),
      electronica: new FormControl(''),
      falla: new FormControl(''),
      correccion: new FormControl(''),
      fecha: new FormControl(''),
    });
    this.configurationService.getConfiguraciones().subscribe(doc => {
      if (doc.error) {
        console.log(doc.error);
      } else {
        this.fallas = doc.find(v => v.tipo === 'sintoma')?.data || [];
        this.correcciones = doc.find(v => v.tipo === 'actuacion')?.data || [];
      }
    });
    this.formIncidencia.get('proyecto').valueChanges.subscribe((proyecto: any) => {
      this.gruposService.getGruposByProyecto(proyecto).subscribe((grupos: Array<any>) => {
        this.grupos = grupos;
      });
      this.contenedoresService.getContenedoresByProyecto(proyecto).subscribe((contenedores: Array<any>) => {
        this.contenedores = [{serial: '', nombre: '', anillo: '', grupos:  []}].concat(contenedores);
      });
      this.anillosService.getAnillosByProyecto(proyecto).subscribe((anillos: Array<any>) => {
        this.anillos = [{serial: '', nombre: '', contenedor: '', grupos: []}].concat(anillos);
      });
      this.usuariosService.getUsuariosByProyecto(proyecto).subscribe((usuarios: Array<any>) => {
        this.responsables = [{uuid: '', nombre: '', apellidos: ''}].concat(usuarios);
      });
      this.electronicasService.getElectronicasByProyecto(proyecto).subscribe((electronicas: Array<any>) => {
        this.electronicas = [{serial: '', nombre: '', anillo: '', grupos: []}].concat(electronicas);
      })
    });
    /*
    this.formIncidencia.get('contenedor').valueChanges.subscribe((serial: any) => {
      const contenedor = this.contenedores.find(v => v.serial === serial);
      const anillo = this.anillos.find(v => v.serial === contenedor.anillo);
      const electronica = this.anillos.find(v => v.serial === contenedor.anillo);
      this.formIncidencia.get('grupo').setValue(contenedor.grupos, {emitEvent: false});
      this.formIncidencia.get('anillo').setValue(anillo?.serial || '', {emitEvent: false});
      this.formIncidencia.get('electronica').setValue(electronica?.serial || '', {emitEvent: false});
    });
    this.formIncidencia.get('anillo').valueChanges.subscribe((serial: any) => {
      const anillo = this.anillos.find(v => v.serial === serial);
      const contenedor = this.contenedores.find(v => v.anillo === serial);
      const electronica = this.anillos.find(v => v.serial === serial);
      this.formIncidencia.get('grupo').setValue(anillo.grupos, {emitEvent: false});
      this.formIncidencia.get('contenedor').setValue(contenedor?.serial || '', {emitEvent: false});
      this.formIncidencia.get('electronica').setValue(electronica?.serial || '', {emitEvent: false});
    });
    this.formIncidencia.get('electronica').valueChanges.subscribe((serial: any) => {
      const electronica = this.electronicas.find(v => v.serial === serial);
      const anillo = this.anillos.find(v => v.serial === serial);
      const contenedor = this.contenedores.find(v => v.anillo === serial);
      this.formIncidencia.get('grupo').setValue(electronica.grupos, {emitEvent: false});
      this.formIncidencia.get('contenedor').setValue(contenedor?.serial || '', {emitEvent: false});
      this.formIncidencia.get('anillo').setValue(anillo?.serial || '', {emitEvent: false});
    });
    */
  }

  loadBasicData() {
    this.incidenciasService.getBasicData().subscribe( (data: any) => {
      this.estados = [{id: 'nuevo', nombre: 'Nuevo'}];
      this.proyectos = data.proyectos;
      this.grupos = data.grupos;
      this.responsables = [{uuid: '', nombre: '', apellidos: ''}].concat(data.usuarios);
      this.contenedores =  [{serial: '', nombre: '', anillo: '', grupos: ''}].concat(data.contenedores);
      this.anillos = [{serial: '', nombre: '', contenedor: '', grupos: ''}].concat(data.anillos);
      this.electronicas = [{serial: '', nombre: '', anillo: '', grupos: ''}].concat(data.electronicas);
      this.formIncidencia.get('proyecto').setValue(data.proyecto || '', { emitEvent: false });
      this.formIncidencia.get('grupo').setValue(data.grupo || '', { emitEvent: false });
      this.formIncidencia.get('estado').setValue('nuevo', { emitEvent: false });
      const now = dayjs();
      const fecha = now.format('YYYY-MM-DDTHH:mm');
      this.formIncidencia.get('fecha').setValue(fecha, { emitEvent: false });
    });
  }

  loadFormData() {
    this.incidenciasService.getIncidencia(this.id).subscribe(
      (res: any) => {
        if (res?.incidencia === undefined)
          this.toastService.warning('Incidencia no existe en base de datos');
        else {
          const incidencia: Incidencia = res.incidencia;
          this.entradas = incidencia['entradas'] || [];
          this.estados = (incidencia.estado === 'nuevo' ? [{id: 'nuevo', nombre: 'Nuevo'}] : []).concat([{id: 'tratamiento', nombre: 'En tratamiento'}, {id: 'cerrado', nombre: 'Cerrado'}]);
          this.responsables = [{uuid: '', nombre: '', apellidos: ''}].concat(res.usuarios);
          this.proyectos = res.proyectos;
          this.anillos = [{serial: '', nombre: '', contenedor: '', grupos: ''}].concat(res.anillos);
          this.grupos = res.grupos;
          this.contenedores = [{serial: '', nombre: '', anillo: '', grupos: ''}].concat(res.contenedores);
          this.electronicas = [{serial: '', nombre: '', anillo: '', grupos: ''}].concat(res.electronicas);
          const now = dayjs(incidencia?.fecha);
          const fecha = now.format('YYYY-MM-DDTHH:mm');
          this.formIncidencia.get('fecha').setValue(fecha, { emitEvent: false });
          this.formIncidencia.get('titulo').setValue(incidencia.titulo);
          this.formIncidencia.get('descripcion').setValue(incidencia.descripcion);
          this.formIncidencia.get('estado').setValue(incidencia.estado);
          this.formIncidencia.get('responsable').setValue(incidencia.responsable);
          this.formIncidencia.get('contenedor').setValue(incidencia.contenedor, {emitEvent: false});
          this.formIncidencia.get('anillo').setValue(incidencia.anillo, {emitEvent: false});
          this.formIncidencia.get('electronica').setValue(incidencia.electronica, {emitEvent: false});
          this.formIncidencia.get('proyecto').setValue(incidencia.proyecto, {emitEvent: false});
          this.formIncidencia.get('grupo').setValue(incidencia.grupos, {emitEvent: false});
          this.formIncidencia.get('falla').setValue(incidencia.fallas, {emitEvent: false});
          this.formIncidencia.get('correccion').setValue(incidencia.correcciones, {emitEvent: false});
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveIncidencia() {
    const fechaString = this.formIncidencia.get('fecha').value;
    const fecha = dayjs(fechaString).toDate();
    const incidencia: Incidencia = new Incidencia (
      this.formIncidencia.get('titulo').value,
      this.formIncidencia.get('descripcion').value,
      this.formIncidencia.get('estado').value,
      fecha,
      this.formIncidencia.get('responsable').value,
      this.formIncidencia.get('contenedor').value,
      this.formIncidencia.get('anillo').value,
      this.formIncidencia.get('electronica').value,
      this.formIncidencia.get('proyecto').value,
      this.formIncidencia.get('grupo').value,
      this.formIncidencia.get('falla').value || [],
      this.formIncidencia.get('correccion').value || [],
    );
    if (this.id)
      this.updateIncidencia(incidencia);
    else
      this.createIncidencia(incidencia);
  }

  createIncidencia(incidencia: Incidencia) {
    this.incidenciasService.postIncidencia(incidencia).subscribe(
      (doc: any) => {
        this.toastService.success(`Incidencia creada correctamente`);
        this.router.navigate(['/incidencias']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear la incidencia");
      }
    );
  }

  updateIncidencia(incidencia: Incidencia) {
    this.incidenciasService.putIncidencia(this.id, incidencia).subscribe(
      (doc: any) => {
        this.toastService.success(`Incidencia actualizada correctamente`);
        this.router.navigate(['/incidencias']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo actualizar la incidencia");
      }
    );
  }

  showValidacion() {
    this.formIncidencia.controls.titulo.markAsTouched();
    this.formIncidencia.controls.descripcion.markAsTouched();
    this.formIncidencia.controls.estado.markAsTouched();
    this.formIncidencia.controls.proyecto.markAsTouched();
  }

  openEditModal(entrada_id: string, descripcion: string, fecha: string, fallas: Array<string>, correcciones: Array<string> ) {
    const options = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-full-height modal-top',
      containerClass: 'modal-fluid',
      animated: true,
      data: {
        heading: 'Entradas',
        content: { 
          description: 'Editar entrada:', action: 'edit',
          descripcion: descripcion,
          fallas: this.fallas,
          correcciones: this.correcciones,
          fecha: fecha,
          fallaSelected: fallas,
          correccionSelected: correcciones,
        }
      }
    }
    this.modalRef = this.modalService.show(IncidenciasModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        const fallasString = this.fallas.filter(v => result?.fallas.includes(v.id)).map(v => v.nombre);
        const correccionesString = this.correcciones.filter(v => result?.correcciones.includes(v.id)).map(v => v.nombre);
        this.incidenciasService.editEntrada(this.id, entrada_id, {
          fecha: result.fecha,
          descripcion: result.descripcion,
          correcciones: correccionesString,
          fallas: fallasString,
        }).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La entrada fue actualizada correctamente');
              this.loadFormData();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      }
      this.modalRef.hide();
    });
  }

  openDeleteModal(entrada_id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Incidencias',
        content: { heading: nombre, description: '¿Desea eliminar esta entrada?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.incidenciasService.deleteEntrada(this.id, entrada_id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La entrada fue eliminada correctamente');
              this.loadFormData();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      }
      this.modalRef.hide();
    });
  }

  createAccion() {
    const options = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-full-height modal-top',
      containerClass: 'modal-fluid',
      animated: true,
      data: {
        heading: 'Entradas',
        content: { description: 'Nueva entrada:', action: 'create', fallas: this.fallas, correcciones: this.correcciones }
      }
    }
    this.modalRef = this.modalService.show(IncidenciasModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        const fallasString = this.fallas.filter(v => result?.fallas.includes(v.id)).map(v => v.nombre);
        const correccionesString = this.correcciones.filter(v => result?.correcciones.includes(v.id)).map(v => v.nombre);
        this.incidenciasService.putIncidenciaEntrada(this.id, {
          fecha: result.fecha,
          descripcion: result.descripcion,
          correcciones: correccionesString,
          fallas: fallasString,
        }).subscribe(doc => {
          if (doc) {
            this.toastService.success('La entrada fue creada correctamente');
            this.loadFormData();
          } else {
            this.toastService.error(`No pudo realizarse la operación solicitada`);
          }
        });
      }
      this.modalRef.hide();
    });
  }

  get titulo() { return this.formIncidencia.get('titulo'); }
  get descripcion() { return this.formIncidencia.get('descripcion'); }
  get estado() { return this.formIncidencia.get('estado'); }
  get responsable() { return this.formIncidencia.get('responsable'); }
  get contenedor() { return this.formIncidencia.get('contenedor'); }
  get anillo() { return this.formIncidencia.get('anillo'); }
  get proyecto() { return this.formIncidencia.get('proyecto'); }
  get grupo() { return this.formIncidencia.get('grupo'); }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }

  getFechaEntrada(fecha: string) {
    const fechaJS = dayjs(fecha);
    return fechaJS.format('DD/MM/YYYY HH:mm');
  }
}
