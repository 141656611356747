<div style="display: block;">
    <canvas mdbChart
        [chartType]="chartType"
        [datasets]="chartDatasets"
        [labels]="chartLabels"
        [colors]="chartColors"
        [legend]=true
        [options]="chartOptions">
    </canvas>
</div>
