import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  get isAdmin() {
    return this.authService.isAdmin() || this.authService.isSuperAdmin();
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isNoClient() {
    return !this.authService.isClient();
  }

  get name() {
    return this.authService.username();
  }

  logout() {
    this.authService.logout();
  }

}
