import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatasetChartJs } from '../models/dataset-chart-js.model';
import { ProductoResumen } from '../models/producto-resumen.model';
import { Producto } from '../models/producto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  private productosUri = `${environment.endPointApi}/producto`;

  constructor(
    private http: HttpClient,
  ) { }

  getProductos(options: any): Observable<Producto[]> {
    const query = this.buildQuery(options);
    return this.http.get<Producto[]>(`${this.productosUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Producto[]>('getProductos', []))
    )
  }

  postProducto(producto: Producto): Observable<any> {
    const uri = `${this.productosUri}/crud`;
    return this.http.post<Producto>(uri, producto).pipe(
      catchError(this.handleError<Producto>('postProducto', null))
    );
  }

  getProducto(id: string): Observable<Producto> {
    const uri = `${this.productosUri}/crud/${id}`;
    return this.http.get<Producto>(uri).pipe(
      catchError(this.handleError<Producto>('getProducto', null))
    );
  }

  getProductoBySKU(sku: string): Observable<Producto> {
    const uri = `${this.productosUri}/sku/${sku}`;
    return this.http.get<Producto>(uri).pipe(
      catchError(this.handleError<Producto>('getProducto', null))
    );
  }

  putProducto(id: string, producto: Producto): Observable<any> {
    const uri = `${this.productosUri}/crud/${id}`;
    return this.http.put<Producto>(uri, producto).pipe(
      catchError(this.handleError<Producto>('putProducto', null))
    );
  }

  deleteProducto(id: string): Observable<any> {
    const uri = `${this.productosUri}/crud/${id}`;
    return this.http.delete<Producto>(uri).pipe(
      catchError(this.handleError<Producto>('deleteProducto', null))
    );
  }

  getProductoCount(): Observable<any> {
    const uri = `${this.productosUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getProductoCount', {total: 0}))
    );
  }

  getProductoResumenCount(options: any): Observable<any> {
    const uri = `${this.productosUri}/resumencount`;
    const query = this.buildQuery(options);
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Number>('getProductoResumenCount', 0))
    );
  }

  getProductoResumen(options: any): Observable<ProductoResumen[]> {
    const uri = `${this.productosUri}/resumen`;
    const query = this.buildQuery(options);
    return this.http.get<ProductoResumen[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getProductoResumen', []))
    );
  }

  getProductoErrorResumen(options: any): Observable<DatasetChartJs> {
    const uri = `${this.productosUri}/resumenerrorpordias`;
    const query = this.buildQuery(options);
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getProductoErrorResumen', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
