import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Proyecto } from 'src/app/models/proyecto.model';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

  private proyectosUri = `${environment.endPointApi}/proyecto`;

  constructor(
    private http: HttpClient,
  ) { }

  getProyectos(options: any): Observable<Proyecto[]> {
    const query = this.buildQuery(options);
    return this.http.get<Proyecto[]>(`${this.proyectosUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Proyecto[]>('getProyectos', []))
    )
  }

  getProyectoCount(): Observable<any> {
    const uri = `${this.proyectosUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getProyectoCount', {total: 0}))
    );
  }

  postProyecto(proyecto: Proyecto): Observable<Proyecto> {
    const uri = `${this.proyectosUri}/crud`
    return this.http.post<Proyecto>(uri, proyecto).pipe(
      catchError(this.handleError<Proyecto>('postProyecto', null))
    );
  }

  getProyecto(id: string): Observable<Proyecto> {
    const uri = `${this.proyectosUri}/crud/${id}`
    return this.http.get<Proyecto>(uri).pipe(
      catchError(this.handleError<Proyecto>('getProyecto', null))
    );
  }  

  putProyecto(id: string, proyecto: Proyecto): Observable<any> {
    const uri = `${this.proyectosUri}/crud/${id}`;
    return this.http.put<Proyecto>(uri, proyecto).pipe(
      catchError(this.handleError<Proyecto>('putProyecto', null))
    );
  }

  deleteProyecto(id: string): Observable<any> {
    const uri = `${this.proyectosUri}/crud/${id}`;
    return this.http.delete(uri).pipe(
      catchError(this.handleError<any>('deleteProyecto', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
