import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResumenHora } from '../models/resumen-hora.model';
import { ResumenDiario } from '../models/resumen-diario.model';
import { UsuarioResumen } from '../models/usuario-resumen.model';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private usuariosUri = `${environment.endPointApi}/usuario`

  constructor(
    private http: HttpClient,
  ) { }

  getUsuarios(options: any): Observable<Usuario[]> {
    const query = this.buildQuery(options);
    return this.http.get<Usuario[]>(`${this.usuariosUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Usuario[]>('getUsuarios', []))
    )
  }

  getBasicData() {
    const uri = `${this.usuariosUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  getUsuariosByProyecto(proyecto: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.usuariosUri}/rest/usuarios/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getUsuariosByProyecto', []))
    );
  }

  postUsuario(usuario: Usuario): Observable<Usuario> {
    const uri = `${this.usuariosUri}/crud`
    return this.http.post<Usuario>(uri, usuario).pipe(
      catchError(this.handleError<Usuario>('postUsuario', null))
    );
  }

  getUsuario(id: string): Observable<Usuario> {
    const uri = `${this.usuariosUri}/crud/${id}`
    return this.http.get<Usuario>(uri).pipe(
      catchError(this.handleError<Usuario>('getUsuario', null))
    );
  }

  getUsuarioByUUID(uuid: string): Observable<Usuario> {
    const uri = `${this.usuariosUri}/uuid/${uuid}`
    return this.http.get<Usuario>(uri).pipe(
      catchError(this.handleError<Usuario>('getUsuario', null))
    );
  }

  putUsuario(id: string, usuario: Usuario): Observable<any> {
    const uri = `${this.usuariosUri}/crud/${id}`;
    return this.http.put<Usuario>(uri, usuario).pipe(
      catchError(this.handleError<Usuario>('putUsuario', null))
    );
  }

  deleteUsuario(id: string): Observable<any> {
    const uri = `${this.usuariosUri}/crud/${id}`;
    return this.http.delete(uri).pipe(
      catchError(this.handleError<any>('deleteUsuario', null))
    );
  }

  getUsuarioCount(): Observable<any> {
    const uri = `${this.usuariosUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getUsuarioCount', {total: 0}))
    );
  }

  getUsuarioResumen(options: any): Observable<UsuarioResumen[]> {
    const uri = `${this.usuariosUri}/resumen`;
    const query = this.buildQuery(options);
    return this.http.get<UsuarioResumen[]>(`${uri}?${query}`).pipe(
      catchError(this.handleError<any[]>('getUsuarios', []))
    );
  }

  getUsuarioResumenCount(options: any): Observable<any> {
    const uri = `${this.usuariosUri}/resumencount`;
    const query = this.buildQuery(options);
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Number>('getUsuarioResumenCount', 0))
    );
  }

  getUsuarioResumenHora(options: any): Observable<ResumenHora[]> {
    const uri = `${this.usuariosUri}/resumenhora`;
    const query = this.buildQuery(options);
    return this.http.get<ResumenHora[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getUsuarios', []))
    );
  }

  getUsuarioResumenDiario(options: any): Observable<ResumenDiario[]> {
    const uri = `${this.usuariosUri}/resumendiario`;
    const query = this.buildQuery(options);
    return this.http.get<ResumenDiario[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getUsuarios', []))
    );
  }

  getUsuarioResumenBonus(options: any): Observable<ResumenDiario[]> {
    const uri = `${this.usuariosUri}/resumenbonus`;
    const query = this.buildQuery(options);
    return this.http.get<ResumenDiario[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getUsuarios', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
