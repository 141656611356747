import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-anillos-sensor-chart',
  templateUrl: './anillos-sensor-chart.component.html',
  styleUrls: ['./anillos-sensor-chart.component.scss']
})
export class AnillosSensorChartComponent implements OnInit {
  @Input() data: Array<any>;
  @Input() titulo: Array<any>;

  chartType: string = 'line';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(166, 114, 138, .85)',
      borderColor: 'rgba(166, 114, 138, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba( 121, 151, 242, .85)',
      borderColor: 'rgba( 121, 151, 242, .95)',
      borderWidth: 0,
    },
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: '' },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          precision: 0
        },
        stacked: false,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor() { }

  ngOnInit(): void {
    const minimo = {
      label: 'Minimo',
      data: this.data.map(d => d.minimo),
      fill: false,
    };
    const maximo = {
      label: 'Maximo',
      data: this.data.map(d => d.maximo),
      fill: false,
    };
    const labels = this.data.map(d => d.hora);
    this.chartLabels = labels;
    this.chartDatasets = [minimo, maximo];
    this.chartOptions.title.text = this.titulo;
  }

}
