import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Grupo } from 'src/app/models/grupo.models';

@Injectable({
  providedIn: 'root'
})
export class GruposService {

  private gruposUri = `${environment.endPointApi}/grupo`;

  constructor(
    private http: HttpClient,
  ) { }

  getGrupos(options: any): Observable<Grupo[]> {
    const query = this.buildQuery(options);
    return this.http.get<Grupo[]>(`${this.gruposUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Grupo[]>('getGrupos', []))
    )
  }

  getGrupoCount(): Observable<any> {
    const uri = `${this.gruposUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getGrupoCount', {total: 0}))
    );
  }

  getGruposByProyecto(proyecto: String) {
    const uri = `${this.gruposUri}/rest/byproyecto/${proyecto}`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getGruposByProyecto',  []))
    );
  }

  getBasicData() {
    const uri = `${this.gruposUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  postGrupo(grupo: Grupo): Observable<Grupo> {
    const uri = `${this.gruposUri}/crud`
    return this.http.post<Grupo>(uri, grupo).pipe(
      catchError(this.handleError<Grupo>('postGrupo', null))
    );
  }

  getGrupo(id: string): Observable<Grupo> {
    const uri = `${this.gruposUri}/crud/${id}`
    return this.http.get<Grupo>(uri).pipe(
      catchError(this.handleError<Grupo>('getGrupo', null))
    );
  }  

  putGrupo(id: string, grupo: Grupo): Observable<any> {
    const uri = `${this.gruposUri}/crud/${id}`;
    return this.http.put<Grupo>(uri, grupo).pipe(
      catchError(this.handleError<Grupo>('putGrupo', null))
    );
  }

  deleteGrupo(id: string): Observable<any> {
    const uri = `${this.gruposUri}/crud/${id}`;
    return this.http.delete(uri).pipe(
      catchError(this.handleError<any>('deleteGrupo', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
