import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ecoembes';
  login = false;
  currentUrl: string;
  private specialPages: any[] = [
    '/login',
    '/register'
  ];
  specialPage: boolean;

  constructor (
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((route: any) => {
      if (route.routerEvent) {
        this.currentUrl = route.routerEvent.urlAfterRedirects;
      } else {
        this.currentUrl = route.urlAfterRedirects;
      }
      this.specialPage = this.specialPages.indexOf(this.currentUrl) !== -1;
    });
  }
  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }
}
