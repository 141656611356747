<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
          <h3 class="white-text mx-3">Incidencia</h3>
        </div>
        <mdb-card-body>
          <form class="border border-light p-4" [formGroup]="formIncidencia" (ngSubmit)="saveIncidencia()">
            <div class="form-row">
              <div class="col col-lg-4">
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="titulo" class="form-control" formControlName="titulo">
                  <label for="titulo">Título</label>
                  <mdb-error *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="titulo.valid && (titulo.dirty || titulo.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <textarea mdbInput mdbValidate type="text" id="descripcion" class="form-control md-textarea" rows="8" formControlName="descripcion"></textarea>
                  <label for="descripcion">Descripción</label>
                  <mdb-error *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="descripcion.valid && (descripcion.dirty || descripcion.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
              </div>
              <div class="col col-lg-4">
                <div class="md-form">
                  <mdb-select-2 label="Proyecto" id="proyecto" formControlName="proyecto" [hidden]="!isSuperAdmin">
                    <mdb-select-option *ngFor="let proyecto of proyectos" [value]="proyecto.uuid">{{proyecto.nombre}}</mdb-select-option>
                  </mdb-select-2>
                  <mdb-error *ngIf="proyecto.invalid && (proyecto.dirty || proyecto.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="proyecto.valid && (proyecto.dirty || proyecto.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Responsable" id="responsable" formControlName="responsable">
                    <mdb-select-option *ngFor="let responsable of responsables" [value]="responsable.uuid">{{responsable.apellidos}} {{responsable.nombre}}</mdb-select-option>
                  </mdb-select-2>
                  <mdb-error *ngIf="responsable.invalid && (responsable.dirty || responsable.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="responsable.valid && (responsable.dirty || responsable.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <mdb-select-2 [multiple]=true label="Grupo" id="grupo" formControlName="grupo" hidden>
                    <mdb-select-option *ngFor="let grupo of grupos" [value]="grupo.uuid">{{grupo.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Contenedor" id="contenedor" formControlName="contenedor">
                    <mdb-select-option *ngFor="let contenedor of contenedores" [value]="contenedor.serial">{{contenedor.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Anillo" id="anillo" formControlName="anillo">
                    <mdb-select-option *ngFor="let anillo of anillos" [value]="anillo.serial">{{anillo.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Electrónica" id="electronica" formControlName="electronica">
                    <mdb-select-option *ngFor="let electronica of electronicas" [value]="electronica.serial">{{electronica.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
              <div class="col col-lg-4">
                <div class="md-form">
                  <mdb-select-2 label="Estado" id="estado" formControlName="estado">
                    <mdb-select-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nombre}}</mdb-select-option>
                  </mdb-select-2>
                  <mdb-error *ngIf="estado.invalid && (estado.dirty || estado.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="estado.valid && (estado.dirty || estado.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <mdb-select-2 [multiple]=true label="Averias" id="falla" formControlName="falla">
                    <mdb-select-option *ngFor="let falla of fallas" [value]="falla.id">{{falla.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div *ngIf="false" class="md-form">
                  <mdb-select-2 [multiple]=true label="Actuaciones" id="correccion" formControlName="correccion">
                    <mdb-select-option *ngFor="let correccion of correcciones" [value]="correccion.id">{{correccion.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <input type="datetime-local" name="fecha" id="fecha" formControlName="fecha">
                </div>
              </div>
            </div>
            <button mdbBtn color="danger" routerLink="/incidencias" mdbWavesEffect size="sm">Cancelar</button>
            <button *ngIf="formIncidencia.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
            <button *ngIf="formIncidencia.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
          </form>
          <mdb-card *ngIf="id">
            <mdb-card-header class="mb-3 bg-light">
              Entradas 
              <button mdbBtn color="green" outline="true" rounded="true"
                size="sm" type="button" class="px-2" mdbWavesEffect (click)="createAccion()">
                <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
              </button>
            </mdb-card-header>
            <mdb-card-body>
              <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Autor</th>
                    <th>Description</th>
                    <th *ngIf="false">Averias</th>
                    <th>Actuaciones</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol *ngFor="let e of entradas">
                    <td>{{getFechaEntrada(e.fecha)}}</td>
                    <td>{{e.usuario}}</td>
                    <td>{{e.descripcion}}</td>
                    <td *ngIf="false">{{e.fallas.join(', ')}}</td>
                    <td>{{e.correcciones.join(', ')}}</td>
                    <td class="text-right">
                      <a class="teal-text" mdbTooltip="Editar" placement="top" (click)="openEditModal(e._id, e.descripcion, e.fecha, e.fallas, e.correcciones)">
                        <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                      </a>
                      <a *ngIf="isAdmin" class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openDeleteModal(e._id, e.descripcion)">
                        <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  
</section>
