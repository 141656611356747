<div class="row mt-2">
  <div class="col">
    <app-breadcrumb contenedor="{{contenedor}}"></app-breadcrumb>
  </div>
</div>

<div class="row mb-2">
  <div class="col panel-title">
    <h2 class="px-4 py-3 white-text z-depth-1-half eco-secondary lighten-1" style="border-radius: 5px;">
      <span>
        {{ container?.nombre }}
      </span>
    </h2>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <app-search (changeRangeDated)="onRangeDated($event)" (exportCSV)="onExportCSV()"></app-search>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 col-sm-12">
    <mdb-card class="px-2 my-2">
      <div class="view overlay waves-light">
        <mdb-card-body>
          <app-contenedor-bonificados-chart [datamatrix]="contenedor" [inicio]="startDate" [fin]="endDate">
          </app-contenedor-bonificados-chart>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
  <div class="col-lg-4 col-sm-12">
    <mdb-card class="px-2 my-2">
      <div class="view overlay waves-light">
        <mdb-card-body>
          <app-contenedor-incentivables-chart [datamatrix]="contenedor" [inicio]="startDate" [fin]="endDate">
          </app-contenedor-incentivables-chart>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
  <div class="col-lg-4 col-sm-12">
    <mdb-card class="px-2 my-2">
      <div class="view overlay waves-light">
        <mdb-card-body>
          <app-contenedor-estatus-chart [datamatrix]="contenedor" [inicio]="startDate" [fin]="endDate">
          </app-contenedor-estatus-chart>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
  <!--
  <div class="col-lg-4 col-sm-12">
    <div class="flex">
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-usuarios-diario-chart
              usuario="{{usuario}}"
              contenedor="{{contenedor}}"
              producto="{{producto}}"
              [inicio]="startDate"
              [fin]="endDate"
              >
            </app-usuarios-diario-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-productos-error-chart
              usuario="{{ usuario }}"
              contenedor="{{ contenedor }}"
              producto="{{ producto }}"
              [inicio]="startDate"
              [fin]="endDate">
            </app-productos-error-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-usuarios-hora-chart
              usuario="{{usuario}}"
              contenedor="{{contenedor}}"
              producto="{{producto}}"
              [inicio]="startDate"
              [fin]="endDate"
              >
            </app-usuarios-hora-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
    </div>
  </div>
  <div class="col-lg-4 col-sm-12">
    <div class="flex">
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-usuarios-bonus-chart
              usuario="{{usuario}}"
              contenedor="{{contenedor}}"
              producto="{{producto}}"
              [inicio]="startDate"
              [fin]="endDate"
              >
            </app-usuarios-bonus-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-bateria-minmax-chart
              contenedor="{{contenedor}}"
              [inicio]="startDate"
              [fin]="endDate"
              >
            </app-bateria-minmax-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-llenado-minmax-chart
              contenedor="{{contenedor}}"
              [inicio]="startDate"
              [fin]="endDate"
              >
            </app-llenado-minmax-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
    </div>
  </div>
  -->
</div>