import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LlenadoMinMax } from 'src/app/models/llenado-minmax.model';
import { ContenedoresService } from '../contenedores.service';

@Component({
  selector: 'app-llenado-minmax-chart',
  templateUrl: './llenado-minmax-chart.component.html',
  styleUrls: ['./llenado-minmax-chart.component.scss']
})
export class LlenadoMinmaxChartComponent implements OnInit {
  
  @Input() name: string;
  @Input() contenedor: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'line';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(0 , 159, 163, 1)',
      borderColor: 'rgba(0 , 159, 163, 1)',
      borderWidth: 2,
      pointRadius: 1,
    },
    {
      backgroundColor: 'rgba(255, 142, 71, 1)',
      borderColor: 'rgba(255, 142, 71, 1)',
      borderWidth: 2,
      pointRadius: 0,
    },
    {
      backgroundColor: 'rgba(255, 142, 71, 1)',
      borderColor: 'rgba(156, 72, 35, 1)',
      borderWidth: 2,
      pointRadius: 1
    },
  ];
  chartOptions: any = {
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Llenado por hora (cm)' },
    scales: {
      yAxes: [{
        ticks: { precision: 0 },
        stacked: false,
      }],
      xAxes: [{
        stacked: false,
      }]
    },
    elements: {
      point: {
        pointRadius: 0
      }
    }
  }
  constructor(
    private contenedoresService: ContenedoresService,
  ) { }

  ngOnInit(): void {
    this.loadLlenadoMinMax();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadLlenadoMinMax();
  }

  loadLlenadoMinMax() {
    const options: any = {};
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    this.contenedoresService.getContenedorLlenadoMinMax(this.contenedor, options).subscribe(
      resumen => this.createChartByDia(resumen)
    );
  }

  createChartByDia(llenados: LlenadoMinMax[]) {
    this.chartLabels = [];
    const data = [];
    const min = [];
    const max = [];
    let now;
    llenados.forEach(llenado => {
      llenado.horas.forEach(h => {
        const hoy = `${llenado.fecha}`;
        this.chartLabels.push(hoy !== now ? hoy : `${h.hora}`);
        data.push(h.llenado);
        min.push(llenado.min);
        max.push(llenado.max);
        if (hoy != now)
          now = hoy;
      })
    });
    this.chartDatasets = [
      {
        label: 'Llenado',
        data: data,
        fill: false,
      },
    ];
  }

}
