<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark ie-nav fixed-top" [containerInside]="false">
  <mdb-navbar-brand>
    <a class="logo navbar-brand" routerLink="/"><strong>ECOREADER</strong></a>
  </mdb-navbar-brand>
  <links>
     <!-- TODO Agregar filtro de menu basado en el rol del usuario conectado -->
    <ul class="navbar-nav mr-auto">
      <li *ngIf="isNoClient" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link " routerLink="/incidencias">Incidencias</a>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link " routerLink="/contenedores">Contenedores</a>
      </li>
      <li *ngIf="isNoClient" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/anillos">Anillos</a>
      </li>
      <li *ngIf="isNoClient" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/electronicas">Electrónicas</a>
      </li>
      <li *ngIf="isAdmin" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/usuarios">Usuarios</a>
      </li>
      <li *ngIf="isSuperAdmin" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/proyectos">Proyectos</a>
      </li>
      <li *ngIf="isAdmin" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/grupos">Grupos</a>
      </li>
      <li *ngIf="isAdmin" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/firmwares">Telecarga</a>
      </li>
      <li *ngIf="isSuperAdmin" class="nav-item waves-light" mdbWavesEffect routerLinkActive="active">
        <a class="nav-link" routerLink="/configuration">Configuración</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item dropdown" dropdown>
        <a mdbWavesEffect type="button" class="nav-link waves-light" mdbWavesEffect (click)="logout()">
          <mdb-icon fas icon="user"></mdb-icon> {{ name }}<span class="caret"></span>
          <mdb-icon fas icon="sign-out-alt"></mdb-icon>
        </a>
      </li>
    </ul>
  </links>
</mdb-navbar>