<mdb-card class="m-1 anillo-sensor" (click)="gotoAnillo()">
  <mdb-card-body>
    <mdb-card-title>
      <h5>{{ anillo.nombre }} <mdb-icon *ngIf="!anillo.activo" fas icon="wrench" class="red-text" size="xs" aria-hidden="true"></mdb-icon></h5>
    </mdb-card-title>
    <span *ngFor="let s of anillo.sensores">
      <mdb-icon fas [icon]="getIconSensor(s.sensor)" [class]="getSensorColor(s.ok, s.total)" size="m" 
      aria-hidden="true"></mdb-icon>
    </span>
  </mdb-card-body>
</mdb-card>
