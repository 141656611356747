import { Component, OnInit } from '@angular/core';
import { ConfigurationModalComponent } from 'src/app/configuration/configuration-modal/configuration-modal.component';
import { ToastService } from 'ng-uikit-pro-standard';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfigurationService } from '../configuration.service';

@Component({
  selector: 'app-configuration-dashboard',
  templateUrl: './configuration-dashboard.component.html',
  styleUrls: ['./configuration-dashboard.component.scss']
})
export class ConfigurationDashboardComponent implements OnInit {

  sintomas: Array<any> = [];
  actuaciones: Array<any> = [];
  modalRef: MDBModalRef;

  constructor(
    private modalService: MDBModalService,
    private configuracionService: ConfigurationService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.getConfiguraciones();
  }

  getConfiguraciones() {
    this.configuracionService.getConfiguraciones().subscribe(doc => {
      if (doc.error) {
        console.log(doc.error);
      } else {
        this.sintomas = doc.find(v => v.tipo === 'sintoma')?.data || [];
        this.actuaciones = doc.find(v => v.tipo === 'actuacion')?.data || [];
      }
    });
  }

  createSintoma() {
    const options = {
      data: {
        heading: 'Sintomas',
        content: { description: 'Crear un nuevo sintoma', action: 'create'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.postConfiguracion({nombre: result, tipo: 'sintoma'}).subscribe(doc => {
          if (doc) {
            this.toastService.success('El sintoma fue creado correctamente');
            this.getConfiguraciones();
          } else {
            this.toastService.error(`No pudo realizarse la operación solicitada`);
          }
        });
      this.modalRef.hide();
    });
  }

  createActuacion() {
    const options = {
      data: {
        heading: 'Actuaciones',
        content: { description: 'Crear un nuevo actuación', action: 'create'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.postConfiguracion({nombre: result, tipo: 'actuacion'}).subscribe(doc => {
          if (doc) {
            this.toastService.success('La actuación fue creado correctamente');
            this.getConfiguraciones();
          } else {
            this.toastService.error(`No pudo realizarse la operación solicitada`);
          }
        });
      this.modalRef.hide();
    });
  }

  deleteSintoma(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Sintomas',
        content: { nombre: nombre, description: '¿Desea eliminar este sintoma?', action: 'delete'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.deleteConfiguracion(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El sintoma fue eliminado correctamente');
              this.getConfiguraciones();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  deleteActuacion(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Actuaciones',
        content: { nombre: nombre, description: '¿Desea eliminar este actuación?', action: 'delete'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.deleteConfiguracion(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La actuación fue eliminado correctamente');
              this.getConfiguraciones();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  editSintoma(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Sintomas',
        content: { nombre: nombre, description: 'Editar sintoma:', action: 'edit'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.putConfiguracion(id, {nombre: result, tipo: 'sintoma'}).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El sintoma fue actualizado correctamente');
              this.getConfiguraciones();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  editActuacion(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Actuación',
        content: { nombre: nombre, description: 'Editar actuación:', action: 'edit'}
      }
    }
    this.modalRef = this.modalService.show(ConfigurationModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.configuracionService.putConfiguracion(id, {nombre: result, tipo: 'actuacion'}).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La actuación fue actualizado correctamente');
              this.getConfiguraciones();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }
}
