import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ReciclosService } from 'src/app/reciclos/reciclos.service';

import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { RecicloBonificado } from 'src/app/models/reciclo-bonificado.model';

@Component({
  selector: 'app-contenedor-bonificados-chart',
  templateUrl: './contenedor-bonificados-chart.component.html',
  styleUrls: ['./contenedor-bonificados-chart.component.scss']
})
export class ContenedorBonificadosChartComponent implements OnInit {

  @Input() datamatrix: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'bar';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(82, 182, 154, .75)',
      borderColor: 'rgba(82, 182, 154, .75)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(153, 217, 140, .75)',
      borderColor: 'rgba(153, 217, 140, .75)',
      borderWidth: 0,
    },
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Incentivables por día' },
    scales: {
      yAxes: [{
        ticks: { beginAtZero: true, precision: 0 },
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor(
    private reciclosService: ReciclosService,
  ) {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadData();
  }

  loadData() {
    const options = { inicio: this.inicio, fin: this.fin};
    this.reciclosService.getReciclosBonificadosByDatamatrix(this.datamatrix, options).subscribe(
      reciclos => this.createChart(reciclos)
    );
  }

  createChart(reciclos: Array<RecicloBonificado>) {
    const bonus = [];
    const nobonus = [];
    const labels = [];
    const data = {};
    reciclos.forEach(reciclo => {
      data[`${reciclo.fecha}`] = { bonus: reciclo.bonus, nobonus: reciclo.nobonus};
    });
    let dia = dayjs(this.inicio);
    while (dia.toISOString() < this.fin) {
      const fecha = dia.format('YYYYMMDD');
      if (data.hasOwnProperty(fecha)) {
        bonus.push(data[fecha].bonus);
        nobonus.push(data[fecha].nobonus);
      } else {
        bonus.push(0);
        nobonus.push(0);
      }
      labels.push(dia.format('ddd DD'));
      dia = dia.add(1, 'day');
    }
    this.chartLabels = labels;
    this.chartDatasets = [
      {label: 'Incentivables', data: bonus},
      {label: 'No Incentivables', data: nobonus}
    ];
  }
}
