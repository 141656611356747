import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BateriaMinMax } from 'src/app/models/bateria-minmax.model';
import { ContenedoresService } from '../contenedores.service';

@Component({
  selector: 'app-bateria-minmax-chart',
  templateUrl: './bateria-minmax-chart.component.html',
  styleUrls: ['./bateria-minmax-chart.component.scss']
})
export class BateriaMinmaxChartComponent implements OnInit {

  @Input() name: string;
  @Input() contenedor: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'line';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(0 , 159, 163, 1)',
      borderColor: 'rgba(0 , 159, 163, 1)',
      borderWidth: 2,
      pointRadius: 1
    },
    {
      backgroundColor: 'rgba(255, 142, 71, 1)',
      borderColor: 'rgba(255, 142, 71, 1)',
      borderWidth: 2,
      pointRadius: 0
    },
    {
      backgroundColor: 'rgba(255, 142, 71, 1)',
      borderColor: 'rgba(156, 72, 35, 1)',
      borderWidth: 2,
      pointRadius: 1
    },
  ];
  chartOptions: any = {
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Batería por hora (%)' },
    scales: {
      yAxes: [{
        ticks: { beginAtZero: true, precision: 0 },
        stacked: false,
      }],
      xAxes: [{
        stacked: false,
      }]
    },
    elements: {
      point: {
        pointRadius: 0
      }
    }
  }

  constructor(
    private contenedoresService: ContenedoresService,
  ) { }

  ngOnInit(): void {
    this.loadBateriaMinMax();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadBateriaMinMax();
  }

  loadBateriaMinMax() {
    const options: any = {};
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    this.contenedoresService.getContenedorBateriaMinMax(this.contenedor, options).subscribe(
      resumen => this.createChartByDia(resumen)
    );
  }

  createChartByDia(baterias: BateriaMinMax[]) {
    this.chartLabels = [];
    const data = [];
    const min = [];
    const max = [];
    let now;
    baterias.forEach(batt => {
      batt.horas.sort((a, b) => a.hora < b.hora ? -1 : 1);
      batt.horas.forEach(h => {
        const hoy = `${batt.fecha}`;
        this.chartLabels.push(hoy !== now ? hoy : `${h.hora}`);
        data.push(h.bateria);
        min.push(batt.min);
        max.push(batt.max);
        if (hoy != now)
          now = hoy;
      })
    });
    this.chartDatasets = [
      {
        label: 'Bateria',
        data: data,
        fill: false,
      },
    ];
  }
}
