import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { Contenedor } from '../models/contenedor.model';
import { ContenedorResumen } from '../models/contenedor-resumen.model';
import { ContenedorFirmware } from '../models/contenedor-firmware.model';
import { BateriaMinMax } from '../models/bateria-minmax.model';
import { LlenadoMinMax } from '../models/llenado-minmax.model';

@Injectable({
  providedIn: 'root'
})
export class ContenedoresService {

  private contenedoresUri = `${environment.endPointApi}/contenedor`;
  private bateriasUri = `${environment.endPointApi}/bateria`;
  private llenadosUri = `${environment.endPointApi}/llenado`;

  constructor(
    private http: HttpClient,
  ) { }
  
  getContenedores(options): Observable<Contenedor[]> {
    const query = this.buildQuery(options);
    return this.http.get<Contenedor[]>(`${this.contenedoresUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Contenedor[]>('getContenedores', []))
    );
  }

  getBasicData() {
    const uri = `${this.contenedoresUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  getContenedoresNotFirmware(firmware, options): Observable<ContenedorFirmware[]> {
    const query = this.buildQuery(options);
    return this.http.get<ContenedorFirmware[]>(`${this.contenedoresUri}/notfirmware/${firmware}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<ContenedorFirmware[]>('getContenedoresNotFirmware', []))
    );
  }

  getContenedoresNotFirmwareTotal(firmware, options): Observable<any> {
    const query = this.buildQuery(options);
    return this.http.get<any>(`${this.contenedoresUri}/notfirmwaretotal/${firmware}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any>('getContenedoresNotFirmware', 0))
    );
  }

  getContenedoresAvailablesByProyecto(proyecto: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.contenedoresUri}/rest/availables/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getContenedoresAvailablesByProyecto', []))
    );
  }

  getContenedoresByProyecto(proyecto: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.contenedoresUri}/rest/contenedores/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getContenedoresByProyecto', []))
    );
  }

  postContenedor(contenedor: Contenedor): Observable<any> {
    const uri = `${this.contenedoresUri}/crud`;
    return this.http.post<Contenedor>(uri, contenedor).pipe(
      catchError(this.handleError<Contenedor>('postContenedor', null))
    );
  }

  getContenedor(id: string): Observable<Contenedor> {
    const uri = `${this.contenedoresUri}/crud/${id}`;
    return this.http.get<Contenedor>(uri).pipe(
      catchError(this.handleError<Contenedor>('getContenedor', null))
    );
  }

  getContenedorByID(id: string): Observable<Contenedor> {
    const uri = `${this.contenedoresUri}/id/${id}`;
    return this.http.get<Contenedor>(uri).pipe(
      catchError(this.handleError<Contenedor>('getContenedorByID', null))
    );
  }

  getContenedorBySerial(serial: string): Observable<Contenedor> {
    const uri = `${this.contenedoresUri}/serial/${serial}`;
    return this.http.get<Contenedor>(uri).pipe(
      catchError(this.handleError<Contenedor>('getContenedorBySerial', null))
    );
  }

  putContenedor(id: string, contenedor: Contenedor): Observable<any> {
    const uri = `${this.contenedoresUri}/crud/${id}`;
    return this.http.put<Contenedor>(uri, contenedor).pipe(
      catchError(this.handleError<Contenedor>('putContenedor', null))
    );
  }

  putContenedorBySerial(serial: string, contenedor: any): Observable<any> {
    const uri = `${this.contenedoresUri}/rest/serial/${serial}`;
    return this.http.put<Contenedor>(uri, contenedor).pipe(
      catchError(this.handleError<Contenedor>('putContenedorBySerial', null))
    );
  }

  deleteContenedor(id: string): Observable<any> {
    const uri = `${this.contenedoresUri}/crud/${id}`;
    return this.http.delete<Contenedor>(uri).pipe(
      catchError(this.handleError<Contenedor>('deleteContenedor', null))
    );
  }

  getContenedorCount(): Observable<any> {
    const uri = `${this.contenedoresUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getContenedorCount', {total: 0}))
    );
  }

  getContenedorResumen(options: any): Observable<ContenedorResumen[]> {
    const uri = `${this.contenedoresUri}/resumen`;
    const query = this.buildQuery(options);
    return this.http.get<ContenedorResumen[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getContenedorResumen', []))
    );
  }

  getContenedorResumenCount(options: any): Observable<any> {
    const uri = `${this.contenedoresUri}/resumencount`;
    const query = this.buildQuery(options);
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Number>('getContenedorResumenCount', 0))
    );
  }

  getContenedorCoordinates(options: any): Observable<any> {
    const uri = `${this.contenedoresUri}/coordinates`;
    return this.http.get<any>(`${uri}`).pipe(
      catchError(this.handleError<Number>('getContenedorCoordinates', 0))
    );
  }

  updateContenedorFirmwareVersion(id, version, imeis): Observable<any> {
    const uri = `${this.contenedoresUri}/firmware/${id}`;
    const body = {
      version: version,
      imeis: imeis
    }
    return this.http.post<any>(uri, body).pipe(
      catchError(this.handleError<any>('updateContenedorFirmwareVersion', null))
    );
  }

  getContenedorBateriaResumen(imei, options: any): Observable<any[]> {
    const uri = `${this.bateriasUri}/resumen/${imei}`;
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getContenedorResumen', []))
    );
  }

  getContenedorBateriaMinMax(imei, options: BateriaMinMax): Observable<BateriaMinMax[]> {
    const uri = `${this.bateriasUri}/minmax/${imei}`;
    const query = this.buildQuery(options);
    return this.http.get<BateriaMinMax[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<BateriaMinMax[]>('getContenedorBateriaMinMax', []))
    );
  }

  getContenedorLlenadoMinMax(imei, options: LlenadoMinMax): Observable<LlenadoMinMax[]> {
    const uri = `${this.llenadosUri}/minmax/${imei}`;
    const query = this.buildQuery(options);
    return this.http.get<LlenadoMinMax[]>(`${uri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<LlenadoMinMax[]>('getContenedorLlenadoMinMax', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
