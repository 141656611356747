import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { Anillo } from '../models/anillo.model';

@Injectable({
  providedIn: 'root'
})
export class AnillosService {

  private anillosUri = `${environment.endPointApi}/anillo`;

  constructor(
    private http: HttpClient,
  ) { }

  getAnillos(options): Observable<Anillo[]> {
    const query = this.buildQuery(options);
    return this.http.get<Anillo[]>(`${this.anillosUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<Anillo[]>('getAnillos', []))
    );
  }

  getAnillosAvailablesByProyecto(proyecto: string) : Observable<any[]> {
    return this.http.get<any[]>(`${this.anillosUri}/rest/availables/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getAnillosAvailablesByProyecto', []))
    );
  }

  getAnillosAvailablesByProyectoWithoutContenedor(proyecto: string) : Observable<any[]> {
    return this.http.get<any[]>(`${this.anillosUri}/rest/availables/${proyecto}/contenedor`).pipe(
      catchError(this.handleError<any[]>('getAnillosAvailablesByProyecto', []))
    );
  }

  getAnillosByProyecto(proyecto: string) : Observable<any[]> {
    return this.http.get<any[]>(`${this.anillosUri}/rest/anillos/${proyecto}`).pipe(
      catchError(this.handleError<any[]>('getAnillosByProyectos', []))
    );
  }

  getAnillosCount(): Observable<any> {
    const uri = `${this.anillosUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getAnillosCount', {total: 0}))
    );
  }

  getAnillosByFirmware(version, options): Observable<any> {
    const query = this.buildQuery(options);
    const uri = `${this.anillosUri}/rest/firmware/${version}${query.length > 0 ? '?' + query : ''}`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getAnillosByFirmware', {total: 0}))
    );
  }

  getBasicData() {
    const uri = `${this.anillosUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  postAnillo(anillo: Anillo): Observable<any> {
    const uri = `${this.anillosUri}/crud`;
    return this.http.post<Anillo>(uri, anillo).pipe(
      catchError(this.handleError<Anillo>('postAnillo', null))
    );
  }

  getAnillo(id: string): Observable<Anillo> {
    const uri = `${this.anillosUri}/crud/${id}`;
    return this.http.get<Anillo>(uri).pipe(
      catchError(this.handleError<Anillo>('getAnillo', null))
    );
  }

  putAnillo(id: string, anillo: Anillo): Observable<any> {
    const uri = `${this.anillosUri}/crud/${id}`;
    return this.http.put<Anillo>(uri, anillo).pipe(
      catchError(this.handleError<Anillo>('putAnillo', null))
    );
  }

  getAnilloBySerial(serial: string): Observable<any> {
    const uri = `${this.anillosUri}/rest/serial/${serial}`;
    return this.http.get<Anillo>(uri).pipe(
      catchError(this.handleError<Anillo>('getAnilloBySku', null))
    );
  }

  putAnilloBySerial(serial: string, anillo: any): Observable<any> {
    const uri = `${this.anillosUri}/rest/serial/${serial}`;
    return this.http.put<Anillo>(uri, anillo).pipe(
      catchError(this.handleError<Anillo>('putAnilloBySku', null))
    );
  }

  deleteAnillo(id: string): Observable<any> {
    const uri = `${this.anillosUri}/crud/${id}`;
    return this.http.delete<Anillo>(uri).pipe(
      catchError(this.handleError<Anillo>('deleteAnillo', null))
    );
  }

  udpateFirmwareVersion(version: any, anillos: Array<String>) {
    const uri = `${this.anillosUri}/rest/firmware/${version.version}?ip=${version.ip}&port=${version.port}`;
    return this.http.put<any>(uri, anillos).pipe(
      catchError(this.handleError<any>('udpateFirmwareVersion', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
