<div class="row mt-2">
    <div class="col">
        <app-breadcrumb ></app-breadcrumb>
    </div>
</div>
  
  <div class="row mb-2">
    <div class="col panel-title">
      <h2 class="px-4 py-3 white-text z-depth-1-half eco-secondary lighten-1" style="border-radius: 5px;">
        <span>
          {{ nombre }}
        </span>
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mdb-card class="chart-settings">
        <mdb-card-body class="pb-2">
          <div class="row">
            <button mdbBtn color="danger" routerLink="/firmwares"  mdbWavesEffect size="sm">Cancelar</button>
            <button mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="actualizarVersiones()">
              <mdb-icon fas icon="sd-card"></mdb-icon> Actualizar
            </button>
            <button mdbBtn type="button" color="info" mdbWavesEffect size="sm" (click)="limpiarVersiones()">
              <mdb-icon fas icon="broom"></mdb-icon> Limpiar
            </button>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="flex">
        <mdb-card class="px-2 my-2">
          <div class="view overlay waves-light">
            <mdb-card-body>
              <table mdbTable small="true" hover="true">
                <thead>
                  <tr>
                    <th class="th-lg">Electrónicas</th>
                    <th class="th-lg">Versión Actual</th>
                    <th class="th-lg">Versión a Configurar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol *ngFor="let e of electronicas; let i = index">
                    <td>
                      <mdb-checkbox [checked]="isChecked(e.serial)" (change)="toggleIndex(e.serial)">{{e.nombre}}</mdb-checkbox>
                    </td>
                    <td>{{e.firmware || ''}}</td>
                    <td>{{e?.firmwareConfig?.version || ''}}</td>
                  </tr>
                </tbody>
              </table>
              <app-pagination dashboard="firmware" [total]="total" [limit]="limit" (changePage)="onChangePage($event)"></app-pagination>
            </mdb-card-body>
          </div>
        </mdb-card>
      </div>
    </div>
  </div> 