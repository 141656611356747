import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor (
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): boolean {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): boolean {
    const login = this.authService.isLoggedIn();
    if (login) {
      if (this.authService.isAdmin())
        return true;
      if (this.authService.isClient() && !url.startsWith('/contenedores') && !url.startsWith('/contenedores-contenedor')) {
        this.authService.redirectUrl = url;
        this.router.navigate(['/contenedores']);
      }
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    return false;
  }
}
