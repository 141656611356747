<div class="row my-2">
  <div class="col">
    <app-breadcrumb contenedor="{{contenedor}}" producto="{{producto}}" usuario="{{usuario}}"></app-breadcrumb>
  </div>
</div>

<div class="row mb-2">
  <div class="col panel-title">
    <h2 class="px-4 py-3 white-text z-depth-1-half eco-secondary lighten-1" style="border-radius: 5px;">
      <span>
        {{ user?.nombre }} {{ user?.apellidos}}
      </span>
      <span *ngIf="contenedor || producto" class="text-sm">
        {{ container?.nombre || product?.nombre }}
      </span>
    </h2>
  </div>
</div>

<div class="row mb-2">
  <div class="col">
    <app-search (changeRangeDated)="onRangeDated($event)" (exportCSV)="onExportCSV()"></app-search>
  </div>
</div>

<div class="row">
  <div class="col-lg-6 col-sm-12">
    <div class="flex">
      <mdb-card *ngIf="!contenedor" class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-contenedores-top-list uri="{{uri}}" usuario="{{usuario}}" producto="{{producto}}"
              [inicio]="startDate" [fin]="endDate">
            </app-contenedores-top-list>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card *ngIf="!producto" class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-productos-top-list uri="{{uri}}" contenedor="{{contenedor}}" usuario="{{usuario}}"
              [inicio]="startDate" [fin]="endDate">
            </app-productos-top-list>
          </mdb-card-body>
        </div>
      </mdb-card>
    </div>
  </div>
  <div class="col-lg-6 col-sm-12">
    <div class="flex">
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-usuarios-diario-chart
              usuario="{{usuario}}"
              contenedor="{{contenedor}}"
              producto="{{producto}}"
              [inicio]="startDate"
              [fin]="endDate">
            </app-usuarios-diario-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-productos-error-chart
              usuario="{{ usuario }}"
              contenedor="{{ contenedor }}"
              producto="{{ producto }}"
              [inicio]="startDate"
              [fin]="endDate">
            </app-productos-error-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
      <mdb-card class="px-2 my-2">
        <div class="view overlay waves-light">
          <mdb-card-body>
            <app-usuarios-hora-chart
              usuario="{{usuario}}"
              contenedor="{{contenedor}}"
              producto="{{producto}}"
              [inicio]="startDate"
              [fin]="endDate">
            </app-usuarios-hora-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
    </div>
  </div>
</div>
