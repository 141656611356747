import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecicladosService {

  private recicladoUri = `${environment.endPointApi}/reciclo`;

  constructor(
    private http: HttpClient,
  ) { }
  
  getRecicladoCSV(options): Observable<any> {
    const uri = `${this.recicladoUri}/csv`;
    const query = this.buildQuery(options);
    const requestOptions: Object = {
      headers: new HttpHeaders({Accept: 'plain/text'}),
      responseType: 'text',
    }
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`, requestOptions).pipe(
      catchError(this.handleError<any>('getRecicladoCSV', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
