<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
          <h3 class="white-text mx-3">Contenedor</h3>
        </div>
        <mdb-card-body>
          <form class="border border-light p-4" [formGroup]="formContenedor" (ngSubmit)="saveContenedor()">
            <div class="form-row">
              <div class="col col-lg-6">
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                  <label for="nombre">Nombre</label>
                  <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="ubicacion" class="form-control" formControlName="ubicacion">
                  <label for="ubicacion">Ubicación</label>
                  <mdb-error *ngIf="ubicacion.invalid && (ubicacion.dirty || ubicacion.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="ubicacion.valid && (ubicacion.dirty || ubicacion.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="serial" class="form-control" formControlName="serial">
                  <label for="serial">Serial</label>
                  <mdb-error *ngIf="serial.invalid && (serial.dirty || serial.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="serial.valid && (serial.dirty || serial.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form" [hidden]="!isSuperAdmin">
                  <mdb-select-2 label="Proyecto" id="proyecto" formControlName="proyecto">
                    <mdb-select-option *ngFor="let proyecto of proyectos" [value]="proyecto.uuid">{{proyecto.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Anillo" id="anillos" formControlName="anillos">
                    <mdb-select-option *ngFor="let anillo of anillos" [value]="anillo.serial">{{ anillo.nombre }}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 [multiple]=true id="grupos" label="Grupos" formControlName="grupos">
                    <mdb-select-option *ngFor="let grupo of grupos" [value]="grupo.uuid">{{grupo.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-checkbox formControlName="activo">Activo</mdb-checkbox>
                </div>
              </div>
              <div class="col col-lg-6">
                <div class="md-form">
                  <input mdbInput type="text" id="street" class="form-control" formControlName="street" (keydown)="handleKeyPress($event)" (keyup)="handleKeyUp($event)" >
                  <label for="street">Búsqueda</label>
                </div>
                <app-here-map [lat]="lat" [lng]="lng" [direction]="direction" width="100%" height="500px" (changePosition)="onChangePosition($event)"></app-here-map>
              </div>
            </div>
            <div class="form-row" hidden>
              <div class="col-4">
                <div class="md-form">
                  <input mdbInput mdbValidate type="number" id="longitude" class="form-control" formControlName="longitude"
                    (change)="upadateLongitude()">
                  <label for="longitude">Longitud</label>
                  <mdb-error *ngIf="longitude.invalid && (longitude.dirty || longitude.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="longitude.valid && (longitude.dirty || longitude.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="number" id="latitude" class="form-control" formControlName="latitude"
                    (change)="upadateLatitude()">
                  <label for="latitude">Latitud</label>
                  <mdb-error *ngIf="latitude.invalid && (latitude.dirty || latitude.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="latitude.valid && (latitude.dirty || latitude.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
              </div>
              
            </div>
            <button mdbBtn color="danger" routerLink="/contenedores"  mdbWavesEffect size="sm">Cancelar</button>
            <button *ngIf="formContenedor.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
            <button *ngIf="formContenedor.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
          </form>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</section>
