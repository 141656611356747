import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuariosService } from '../usuarios.service';
import { GruposService } from 'src/app/grupos/grupos.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  id: string;
  formUsuario: FormGroup;
  proyectos: Array<any>;
  grupos: Array<any>;

  constructor(
    private usuariosService: UsuariosService,
    private gruposService: GruposService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formUsuario = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      admin: new FormControl(false),
      client: new FormControl(false),
      correo: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      proyecto: new FormControl(''),
      grupos: new FormControl(''),
    });
    this.formUsuario.get('proyecto').valueChanges.subscribe((proyecto: any) => {
      this.gruposService.getGruposByProyecto(proyecto).subscribe((grupos: Array<any>) => {
        this.grupos = grupos;
      });
    });
  }

  loadBasicData() {
    this.usuariosService.getBasicData().subscribe( (data: any) => {
      this.proyectos = data.proyectos;
      this.grupos = data.grupos;
      this.formUsuario.get('proyecto').setValue(data.proyecto || '', { emitEvent: false });
    });
  }

  loadFormData() {
    this.usuariosService.getUsuario(this.id).subscribe(
      (res: any) => {
        if (res?.usuario === undefined)
          this.toastService.warning('Usuario no existe en base de datos');
        else {
          const usuario: Usuario = res.usuario;
          this.proyectos = res.proyectos;
          this.grupos = res.grupos;
          this.formUsuario.get('nombre').setValue(usuario.nombre);
          this.formUsuario.get('apellidos').setValue(usuario.apellidos);
          this.formUsuario.get('admin').setValue(usuario.admin);
          this.formUsuario.get('client').setValue(usuario.client);
          this.formUsuario.get('correo').setValue(usuario.correo);
          this.formUsuario.get('password').setValue(usuario.password);
          this.formUsuario.get('proyecto').setValue(usuario.proyecto, {emitEvent: false});
          this.formUsuario.get('grupos').setValue(usuario.grupos);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveUsuario() {
    const usuario: Usuario = new Usuario (
      this.formUsuario.get('nombre').value,
      this.formUsuario.get('apellidos').value,
      this.formUsuario.get('correo').value,
      this.formUsuario.get('password').value,
      this.formUsuario.get('proyecto').value,
      this.formUsuario.get('grupos').value,
      this.formUsuario.get('admin').value,
      this.formUsuario.get('client').value,
    );
    if (this.id)
      this.updateUsuario(usuario);
    else
      this.createUsuario(usuario);
  }

  createUsuario(usuario: Usuario) {
    this.usuariosService.postUsuario(usuario).subscribe(
      (doc: any) => {
        if (doc.error)
          this.toastService.error(`Usuario existe actualmente`);
        else
          this.toastService.success(`Usuario creado correctamente`);
        this.router.navigate(['/usuarios']);
      },
      (error: any) => {
        console.log(`Usuario - Create: ${error}`);
        this.toastService.error("No se pudo crear el usuario");
      }
    );
  }

  updateUsuario(usuario: Usuario) {
    this.usuariosService.putUsuario(this.id, usuario).subscribe(
      (prev: any) => {
        this.toastService.success(`Usuario actualizado correctamente`);
        this.router.navigate(['/usuarios']);
      },
      (error: any) => {
        console.log(`Usuario - Update: ${error}`);
        this.toastService.error("No se pudo crear el usuario");
      }
    );
  }

  showValidacion() {
    this.formUsuario.controls.nombre.markAsTouched();
    this.formUsuario.controls.apellidos.markAsTouched();
    this.formUsuario.controls.correo.markAsTouched();
    this.formUsuario.controls.password.markAsTouched();
  }

  get nombre() {return this.formUsuario.get('nombre');}
  get apellidos() {return this.formUsuario.get('apellidos');}
  get correo() {return this.formUsuario.get('correo');}
  get password() {return this.formUsuario.get('password');}

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
