<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button routerLink="/producto-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Productos</h3>
          <div></div>
        </div>
        <div class="px-4">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <tr>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Bonificado</th>
                <th>Acciones</th>
              </tr>
              <tr *ngFor="let p of productos">
                <td >{{p.nombre}}</td>
                <td >{{p.tipo}}</td>
                <td >{{p.bonus ? 'Si' : 'No'}}</td>
                <td>
                  <a routerLink="/productos-producto/{{p.sku}}" class="blue-text" mdbTooltip="Información" placement="top">
                    <mdb-icon fas icon="chart-line" class="px-1"></mdb-icon>
                  </a>
                  <a routerLink="/producto-editar/{{p._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                    <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                  </a>
                  <a class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(p._id, p.nombre, p.sku)">
                    <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <app-pagination [total]="total" [limit]=limit (changePage)="onChangePage($event)"></app-pagination>
      </mdb-card>
    </div>
  </div>
</section>
