import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ContenedoresService } from '../contenedores.service';
import { ContenedorResumen } from 'src/app/models/contenedor-resumen.model';
 
const MAX_PAGES:number = 9;
const HALF_PAGE:number = 4;

@Component({
  selector: 'app-contenedores-top-list',
  templateUrl: './contenedores-top-list.component.html',
  styleUrls: ['./contenedores-top-list.component.scss']
})
export class ContenedoresTopListComponent implements OnInit {

  @Input() uri: string;
  @Input() producto: string;
  @Input() usuario: string;
  @Input() inicio: string;
  @Input() fin: string;

  contenedores: ContenedorResumen [];
  total: number  = 0;
  limit: number = 10;
  skip: number = 0;

  constructor(
    public contenedoresService: ContenedoresService,
  ) { }

  ngOnInit(): void {
    this.contenedores = [];
    this.loadContenedores();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadContenedores();
  }

  loadContenedores(): void {
    const options: any = {};
    if (this.usuario)
      options.usuario = this.usuario;
    if (this.producto)
      options.producto = this.producto;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    options.limit = this.limit;
    options.skip = this.skip;
    this.contenedoresService.getContenedorResumenCount(options).subscribe(
      resultado => this.total = resultado.hasOwnProperty('total') ? resultado.total : 0
    );
    this.contenedoresService.getContenedorResumen(options).subscribe(
      contenedores => this.contenedores = contenedores
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.loadContenedores();
  }
}
