import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() contenedor: string;
  @Input() producto: string;
  @Input() usuario: string;

  rutas: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(
      (data) => {
        this.rutas = data.rutas.map(ruta => {
          if (ruta.parametros) {
            const parametros = ruta.parametros.map(element => this[element]);
            ruta.path = `${ruta.ruta}/${parametros.join('/')}`;
          } else {
            ruta.path = ruta.ruta;
          }
          return ruta;
        });
      }
    )
  }

  isVisible(n: number) {
    let length = this.rutas.length;
    if (length == 0)
      return false;
    length -= 1;
    if ((n < 2 && this.authService.isAdmin()) || (n > 1 && n < length))
      return true;
    return false;
  }

}
