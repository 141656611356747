import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UsuarioResumen } from 'src/app/models/usuario-resumen.model';
import { UsuariosService } from '../usuarios.service';

const MAX_PAGES:number = 9;
const HALF_PAGE:number = 4;

@Component({
  selector: 'app-usuarios-top-list',
  templateUrl: './usuarios-top-list.component.html',
  styleUrls: ['./usuarios-top-list.component.scss']
})
export class UsuariosTopListComponent implements OnInit {

  usuarios: UsuarioResumen[];v
  total: number  = 0;
  limit: number = 10;
  skip: number = 0;

  @Input() uri: string;
  @Input() producto: string;
  @Input() contenedor: string;
  @Input() inicio: string;
  @Input() fin: string;

  constructor(
    private usuariosService: UsuariosService,
  ) { }

  ngOnInit(): void {
    this.usuarios = [];
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadUsers();
  }

  loadUsers() {
    const options: any = {};
    if (this.producto)
      options.producto = this.producto;
    if (this.contenedor)
      options.contenedor = this.contenedor;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    options.limit = this.limit;
    options.skip = this.skip;
    this.usuariosService.getUsuarioResumenCount(options).subscribe(
      resultado => this.total = resultado.hasOwnProperty('total') ? resultado.total : 0
    );
    this.usuariosService.getUsuarioResumen(options).subscribe(
      usuarios => this.usuarios = usuarios
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.loadUsers();
  }
}
