<div class="row my-2">
  <div class="col">
    <app-breadcrumb></app-breadcrumb>
  </div>
</div>

<div class="row mb-2">
  <div class="col panel-title">
    <h2 class="px-4 py-3 white-text z-depth-1-half eco-secondary lighten-1" style="border-radius: 5px;">
      <span>{{ anillo?.nombre }}</span><small>({{ serial }})</small>
    </h2>
  </div>
</div>

<div class="row">
  <div class="col-lg-4 col-sm-12">
    <mdb-card class="px-2 my-2">
      <div class="view overlay waves-light">
        <mdb-card-body>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <i>Conectado: </i> <span [ngClass]="{'text-danger': isDisconectOK(anillo?.conectado, anillo?.desconectado), 'text-success': !isDisconectOK(anillo?.conectado, anillo?.desconectado)}">{{getUltimaConexion(anillo?.conectado, anillo?.desconectado)}}</span></li>
            <li class="list-group-item"><i>Base de datos:</i> {{ getDatabaseVersion(anillo?.version) }}</li>
            <li class="list-group-item"><i>Firmware:</i> {{ anillo?.firmware }}</li>
          </ul>
        </mdb-card-body>
      </div>
    </mdb-card>
    <mdb-card class="px-2 my-2">
      <div class="view overlay waves-light">
        <mdb-card-body>
          <div class="btn-toolbar mb-2">
            <div class="btn-group">
              <button mdbBtn type="button" color="success" class="waves-light mx-1" size="sm" (click)="prevStep()">
                <mdb-icon fas icon="step-backward" size="m" aria-hidden="true"></mdb-icon>
              </button>
              <button mdbBtn type="button" color="success" class="waves-light mx-1" size="sm" (click)="play()">
                <mdb-icon fas icon="play" size="m" aria-hidden="true"></mdb-icon>
              </button>
              <button mdbBtn type="button" color="success" class="waves-light mx-1" size="sm" (click)="nextStep()">
                <mdb-icon fas icon="step-forward" size="m" aria-hidden="true"></mdb-icon>
              </button>
              <button mdbBtn type="button" color="success" class="waves-light mx-1" size="sm" (click)="stop()">
                <mdb-icon fas icon="stop" size="m" aria-hidden="true"></mdb-icon>
              </button>
            </div>
          </div>
          <plotly-plot [data]="graph.data" [layout]="graph.layout" [config]="graph.config" [frames]="graph.frames"></plotly-plot>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
  <div class="col-lg-8 col-sm-12">
    <div class="d-flex flex-wrap">
      <mdb-card class="m-1 px-1  anillo-card" *ngFor="let sensor of sensores">
        <div class="view overlay waves-light">
          <mdb-card-title>
            <div class="row">
              <div class="col">
                {{ getSensorName(sensor._id) }}
              </div>
            </div>
            <div class="row">
              <div class="col"><span [ngClass]="{'text-danger': isDisconectOK(sensor.ultimoNoOK, sensor.ultimoOK), 'text-success': !isDisconectOK(sensor.ultimoNoOK, sensor.ultimoOK)}">{{ getUltimaConexion(sensor.ultimoNoOK, sensor.ultimoOK)}}</span></div>
              <div class="col text-right">
                <mdb-icon fas icon="heartbeat" [class]="getIconColor(sensor.ok, sensor.total)" size="m" aria-hidden="true"></mdb-icon>
                <small>{{ (100 * sensor.ok / (sensor.total || 1)).toFixed(2)}}%</small>
              </div>
            </div>
          </mdb-card-title>
          <mdb-card-body>
            <app-anillos-sensor-chart [data]="sensor.data" [titulo]="getSensorName(sensor._id)">
            </app-anillos-sensor-chart>
          </mdb-card-body>
        </div>
      </mdb-card>
    </div>
  </div>
</div>