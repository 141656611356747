import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Firmware } from 'src/app/models/firmware.model';
import { FirmwareService } from '../firmware.service';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit {

  id: string;
  formFirmware: FormGroup;

  constructor(
    private firmwareService: FirmwareService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
  }

  createForm() {
    this.formFirmware = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      version: new FormControl('', [Validators.required]),
      ip: new FormControl('', [Validators.required]),
      puerto: new FormControl(0, [Validators.required]),
    });
  }

  loadFormData() {
    this.firmwareService.getFirmware(this.id).subscribe(
      (res: any) => {
        if (res?.nombre === undefined)
          this.toastService.warning('Firmware no existe en base de datos');
        else {
          const firmware: Firmware = res;
          this.formFirmware.get('nombre').setValue(firmware.nombre);
          this.formFirmware.get('version').setValue(firmware.version);
          this.formFirmware.get('ip').setValue(firmware.ip);
          this.formFirmware.get('puerto').setValue(firmware.puerto);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveFirmware() {
    const firmware: Firmware = new Firmware (
      this.formFirmware.get('nombre').value,
      this.formFirmware.get('version').value,
      this.formFirmware.get('ip').value,
      this.formFirmware.get('puerto').value,
    );
    if (this.id)
      this.updateFirmware(firmware);
    else
      this.createFirmware(firmware);
  }

  createFirmware(contenedor: Firmware) {
    this.firmwareService.postFirmware(contenedor).subscribe(
      (doc: any) => {
        this.toastService.success(`Firmware creado correctamente`);
        this.router.navigate(['/firmwares']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el contenedor");
      }
    );
  }

  updateFirmware(contenedor: Firmware) {
    this.firmwareService.putFirmware(this.id, contenedor).subscribe(
      (doc: any) => {
        this.toastService.success(`Firmware actualizado correctamente`);
        this.router.navigate(['/firmwares']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el contenedor");
      }
    );
  }

  showValidacion() {
    this.formFirmware.controls.nombre.markAsTouched();
    this.formFirmware.controls.version.markAsTouched();
    this.formFirmware.controls.ip.markAsTouched();
    this.formFirmware.controls.puerto.markAsTouched();
  }

  get nombre() {return this.formFirmware.get('nombre');}
  get version() {return this.formFirmware.get('version');}
  get ip() {return this.formFirmware.get('ip');}
  get puerto() {return this.formFirmware.get('puerto');}

}
