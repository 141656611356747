<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div> 
            <button *ngIf="isAdmin" routerLink="/contenedor-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Contenedores</h3>
          <div></div>
        </div>
        <div class="px-4">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Ubicación</th>
                  <th *ngIf="isSuperAdmin">Proyecto</th>
                  <th>Anillo <small class="text-monospace">(IMEI)</small></th>
                  <th>Batería</th>
                  <th>Conectado</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let c of contenedores">
                  <td scope="row">
                    {{ c.nombre }}
                    <mdb-icon *ngIf="!c.activo" fas icon="wrench" class="red-text" size="x" aria-hidden="true"></mdb-icon>
                  </td>
                  <td>{{ c.ubicacion }}</td>
                  <td *ngIf="isSuperAdmin">{{ c.proyecto }}</td>
                  <td *ngIf="c.anillo; else elseBlock">
                    <a *ngIf="isNoClient" routerLink="/anillos-anillo/{{c.anillo_id}}" class="blue-text" mdbTooltip="Ver anillo" placement="top">
                      <mdb-icon fas icon="eye" class="px-1"></mdb-icon>
                    </a>
                    {{ c.anillo }}<small class="text-monospace">({{ c.anillo_id }})</small>
                  </td>
                  <td *ngIf="c.anillo">
                    <mdb-icon fas [icon]="getIconBattery(c.bateria1)" [class]="getIconColor(c.bateria1)" size="lg" 
                      aria-hidden="true"></mdb-icon> {{ c.bateria1 }}%
                      <mdb-icon fas [icon]="getIconBattery(c.bateria2)" [class]="getIconColor(c.bateria2)" size="lg" 
                      aria-hidden="true"></mdb-icon> {{ c.bateria2 }}%
                  </td>
                  <td *ngIf="c.anillo">
                    <mdb-icon *ngIf="isDisconectOK(c.conectado, c.desconectado)" fas icon="exclamation-triangle" class="mr-1 red-text" size="m" aria-hidden="true"></mdb-icon>
                    {{ getUltimaConexion(c.conectado, c.desconectado) }}
                  </td>
                  <ng-template #elseBlock>
                    <td></td>
                    <td></td>
                    <td></td>
                  </ng-template>
                  <td class="text-right">
                    <a routerLink="/contenedores-contenedor/{{c.serial}}"class="blue-text" mdbTooltip="Información" placement="top">
                      <mdb-icon fas icon="chart-bar" class="px-1"></mdb-icon>
                    </a>
                    <a *ngIf="isNoClient" routerLink="/contenedor-editar/{{c._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                      <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                    </a>
                    <a *ngIf="isAdmin" class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(c._id, c.nombre)">
                      <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-pagination dashboard="contenedor" [total]="total" [limit]="limit" (changePage)="onChangePage($event)"></app-pagination>
      </mdb-card>
    </div>
  </div>
</section>
