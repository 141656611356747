<div class="container-fluid">
  <app-breadcrumb></app-breadcrumb>
  <div class="row">
    <div class="col-lg-8">
      <h3>Disponibilidad de sensores menor al 60%</h3>
      <div class="card-group">
        <app-anillo-sensores *ngFor="let a of anillos" [anillo]="a">
        </app-anillo-sensores>
      </div>
    </div>
    <div class="col-lg-4">
      <h3>Offline</h3>
      <div class="card-group">
        <app-anillo-sensores *ngFor="let a of anillosOffline" [anillo]="a">
        </app-anillo-sensores>
      </div>
      <h3>Comunicación interrumpida</h3>
      <div class="card-group">
        <app-anillo-sensores *ngFor="let a of anillosDisconnect" [anillo]="a">
        </app-anillo-sensores>
      </div>
    </div>
  </div>
</div>
