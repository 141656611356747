<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
          <h3 class="white-text mx-3">Proyecto</h3>
        </div>
        <mdb-card-body>
          <form class="border border-light p-4" [formGroup]="formProyecto" (ngSubmit)="saveProyecto()">
            <div class="form-row">
              <div class="col col-lg-4">
                <div class="md-form" hidden>
                  <input mdbInput mdbValidate type="text" id="uuid" class="form-control" formControlName="uuid">
                  <label for="uuid">UUID</label>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                  <label for="nombre">Nombre</label>
                  <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="number" id="spike" class="form-control" formControlName="spike">
                  <label for="spike">Spike</label>
                  <mdb-error *ngIf="spike.invalid && (spike.dirty || spike.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="spike.valid && (spike.dirty || spike.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
              </div>
            </div>
            <button mdbBtn color="danger" routerLink="/proyectos"  mdbWavesEffect size="sm">Cancelar</button>
            <button *ngIf="formProyecto.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
            <button *ngIf="formProyecto.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
          </form>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</section>
