import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  action: Subject<any> = new Subject();
  heading: string;
  content: any;

  constructor(
    public modalRef: MDBModalRef
  ) { }

  ngOnInit(): void {
  }

  onYesClick() {
      this.action.next(true);
  }

  onNoClick() {
      this.action.next(false);
  }

}
