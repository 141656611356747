import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Incidencia } from 'src/app/models/incidencia.model';
import { IncidenciasService } from '../incidencias.service';
import { ConfigurationService } from 'src/app/configuration/configuration.service'
import { AuthService } from 'src/app/auth/auth.service';
import { saveAs } from 'file-saver';
import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-dashboard-incidencias',
  templateUrl: './dashboard-incidencias.component.html',
  styleUrls: ['./dashboard-incidencias.component.scss']
})
export class DashboardIncidenciasComponent implements OnInit {

  incidencias: Array<Incidencia> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;
  startDate: string;
  endDate: string;
  entrada: number = -1;
  estados: any = {
    label: 'Estado',
    options: [{value: '', label: ''}, {value: 'nuevo', label: 'Nuevo'}, {value: 'tratamiento', label: 'En tratamiento'}, {value: 'cerrado', label: 'Cerrado'}],
  } 
  estado: string;
  fallas: {};
  falla: string;
  contenedores: {};
  contenedor: string;
  anillos: {};
  anillo: string;
  electronicas: {};
  electronica: string;

  constructor(
    private incidenciasService: IncidenciasService,
    private modalService: MDBModalService,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
   }

  ngOnInit(): void {
    const now = dayjs();
    this.startDate = now.add(-7, 'day').startOf('day').toISOString();
    this.endDate = now.endOf('day').toISOString();
    this.loadFilters();
  }

  loadFilters() {
    const option = [{value: '', label: ''}];
    this.incidenciasService.getFiltros().subscribe((doc: {fallas: [], contenedores: [], anillos: [], electronicas: []}) => {
      this.fallas = {label: 'Sintomas', options: option.concat(doc.fallas)};
      this.contenedores = {label: 'Contenedores', options: option.concat(doc.contenedores)};
      this.anillos = {label: 'Anillos', options: option.concat(doc.anillos)};
      this.electronicas = {label: 'Electronicas', options: option.concat(doc.electronicas)};
    });
  }

  getIncidencias(): void {
    const options = {
      skip: this.skip,
      limit: this.limit,
      start: this.startDate,
      end: this.endDate,
      falla: this.falla || '',
      estado: this.estado || '',
      contenedor: this.contenedor || '',
      anillo: this.anillo || '',
      electronica: this.electronica || '',
    };
    this.incidenciasService.getIncidencias(options).subscribe(doc => {
      this.incidencias = doc.incidencias;
      this.total = doc.total;
    });
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }

  get isNoClient() {
    return !this.authService.isClient();
  }

  getFechaEntrada(fecha: string) {
    const fechaJS = dayjs(fecha);
    return fechaJS.format('DD/MMM/YYYY HH:mm');
  }

  setEntradaClass(index: number) {
    if (this.entrada === index)
      this.entrada = -1;
    else
      this.entrada = index;
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getIncidencias();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Incidencias',
        content: { heading: nombre, description: '¿Desea eliminar este incidencia?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.incidenciasService.deleteIncidencia(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El incidencia fue eliminada correctamente');
              this.getIncidencias();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  onRangeDated(doc: any) {
    if (doc.start)
      this.startDate = doc.start;
    if (doc.end)
      this.endDate = doc.end;
    this.getIncidencias();
  }

  onEstadoChange(event: any) {
   this.estado = event;
   this.getIncidencias();
  }

  onSintomaChange(event: any) {
    this.falla = event;
    this.getIncidencias();
  }
  
   onContenedorChange(event: any) {
    this.contenedor = event;
    this.getIncidencias();
   }
 
  onAnilloChange(event: any) {
    this.anillo = event;
    this.getIncidencias();
  }

  onElectronicaChange(event: any) {
    this.electronica = event;
    this.getIncidencias();
   }

  onExportCSV() {
    const options = {};
    if (this.startDate)
      options['start'] = this.startDate;
    if (this.endDate)
      options['end'] = this.endDate;
    if (this.falla)
      options['falla'] = this.falla;
    if (this.estado)
      options['estado'] = this.estado;
    if (this.contenedor)
      options['contenedor'] = this.contenedor;
    if (this.anillo)
      options['anillo'] = this.anillo;
    if (this.electronica)
      options['electronica'] = this.electronica;
    this.incidenciasService.getIncidenciasCSV(options).subscribe(
      resultado => saveAs(new Blob([resultado], {type: "text/plain;charset=utf-8"}), "incidencias.csv")
    );
  }
}
