<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
          <h3 class="white-text mx-3">Electrónica</h3>
        </div>
        <mdb-card-body>
          <form class="border border-light p-4" [formGroup]="formElectronica" (ngSubmit)="saveElectronica()">
            <div class="form-row">
              <div class="col col-lg-4">
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                  <label for="nombre">Nombre</label>
                  <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="serial" class="form-control" formControlName="serial">
                  <label for="serial">Serial</label>
                  <mdb-error *ngIf="serial.invalid && (serial.dirty || serial.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                  <mdb-success *ngIf="serial.valid && (serial.dirty || serial.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                </div>
                <div class="md-form" [hidden]="!isSuperAdmin">
                  <mdb-select-2 label="Proyecto" id="proyecto" formControlName="proyecto">
                    <mdb-select-option *ngFor="let proyecto of proyectos" [value]="proyecto.uuid">{{proyecto.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 [multiple]=true id="grupos" label="Grupos" formControlName="grupos">
                    <mdb-select-option *ngFor="let grupo of grupos" [value]="grupo.uuid">{{grupo.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
                <div class="md-form">
                  <mdb-select-2 label="Anillo" id="anillos" formControlName="anillo">
                    <mdb-select-option *ngFor="let anillo of anillos" [value]="anillo.uuid">{{anillo.nombre}}</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
              <div class="col col-lg-4">
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="tarjeta" class="form-control" formControlName="tarjeta">
                  <label for="tarjeta">Tarjeta SIM</label>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="database" class="form-control" formControlName="database" readonly>
                  <label for="database">Base de Datos</label>
                </div>
                <div class="md-form">
                  <input mdbInput mdbValidate type="text" id="firmware" class="form-control" formControlName="firmware" readonly>
                  <label for="firmware">Firmware</label>
                </div>
              </div>
              <div class="col col-lg-4">
                <label>Conexión remota</label>
                <div class="md-form my-1">
                  <input type="datetime-local" name="fecha" id="fecha" formControlName="fecha">
                </div>
              </div>
            </div>
            <button mdbBtn color="danger" routerLink="/electronicas"  mdbWavesEffect size="sm">Cancelar</button>
            <button *ngIf="formElectronica.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
            <button *ngIf="formElectronica.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
              <mdb-icon fas icon="save"></mdb-icon> Guardar
            </button>
          </form>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</section>