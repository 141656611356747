import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Firmware } from 'src/app/models/firmware.model';
import { FirmwareService } from '../firmware.service';

@Component({
  selector: 'app-dashboard-firmwares',
  templateUrl: './dashboard-firmwares.component.html',
  styleUrls: ['./dashboard-firmwares.component.scss']
})
export class DashboardFirmwaresComponent implements OnInit {

  firmwares: Array<Firmware> = [];
  total: number = 0;
  limit: number = 10;
  skip: number = 0;
  modalRef: MDBModalRef;

  constructor(
    private firmwareService: FirmwareService,
    private modalService: MDBModalService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  getFirmwares(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.firmwareService.getFirmwareCount().subscribe(
      resumen => this.total = resumen.total
    );
    this.firmwareService.getFirmwares(options).subscribe(
      firmwares => this.firmwares = firmwares
    );
  }

  onChangePage(event) {
    this.skip = event.skip;
    this.limit = event.limit;
    this.getFirmwares();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Telecargas',
        content: { heading: nombre, description: '¿Desea eliminar esta telecarga?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.firmwareService.deleteFirmware(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La telecarga fue eliminada correctamente');
              this.getFirmwares();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }
}
