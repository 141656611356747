import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { GruposService } from 'src/app/grupos/grupos.service';
import { Anillo } from 'src/app/models/anillo.model';
import { ContenedoresService } from 'src/app/contenedores/contenedores.service';
import { AnillosService } from '../anillos.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ElectronicasService } from 'src/app/electronicas/electronicas.service';

@Component({
  selector: 'app-anillo',
  templateUrl: './anillo.component.html',
  styleUrls: ['./anillo.component.scss']
})
export class AnilloComponent implements OnInit {

  id: string;
  formAnillo: FormGroup;
  bateria1: number = 0;
  bateria2: number = 0;
  conectado: Date;
  desconectado: Date;
  version: number = 0;
  firmware: any;
  config: any;
  contenedorActual: string;
  contenedores: Array<any>;
  proyectos: Array<any>;
  grupos: Array<any>;
  electronicas: Array<any>;

  constructor(
    private anillosService: AnillosService,
    private contenedoresService: ContenedoresService,
    private gruposService: GruposService,
    private electronicasService: ElectronicasService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formAnillo = new FormGroup({
      serial: new FormControl(''),
      nombre: new FormControl('', [Validators.required]),
      modelo: new FormControl('', [Validators.required]),
      proyecto: new FormControl(''),
      contenedores: new FormControl(''),
      grupos: new FormControl(''),
      activo: new FormControl(false),
    });
    this.formAnillo.get('proyecto').valueChanges.subscribe((proyecto: any) => {
      this.gruposService.getGruposByProyecto(proyecto).subscribe((grupos: Array<any>) => {
        this.grupos = grupos;
      });
      this.contenedoresService.getContenedoresAvailablesByProyecto(proyecto).subscribe((contenedores: Array<any>) => {
        this.contenedores = [{serial: '', nombre: ''}].concat(contenedores);
      });
      this.electronicasService.getElectronicasAvailablesByProyecto(proyecto).subscribe((electronicas: Array<any>) => {
        this.electronicas = electronicas;
      });
    });
  }

  loadBasicData() {
    this.anillosService.getBasicData().subscribe( (data: any) => {
      this.proyectos = data.proyectos;
      this.grupos = data.grupos;
      this.contenedores =  [{serial: '', nombre: ''}].concat(data.contenedores);
      this.formAnillo.get('proyecto').setValue(data.proyecto || '', { emitEvent: false });
    });
  }

  loadFormData() {
    this.anillosService.getAnillo(this.id).subscribe(
      (res: any) => {
        if (res?.anillo === undefined) {
          this.toastService.warning('Anillo no existe en base de datos');
          this.router.navigate(['/anillos']);
        } else {
          this.grupos = res.grupos;
          this.proyectos = res.proyectos;
          const anillo: Anillo = res.anillo;
          this.electronicas = [{serial: '', nombre: ''}].concat(res.electronicas);
          this.contenedores = [{serial: '', nombre: ''}].concat(res.contenedores);
          this.formAnillo.get('serial').setValue(anillo.serial);
          this.formAnillo.get('nombre').setValue(anillo.nombre);
          this.formAnillo.get('modelo').setValue(anillo.modelo);
          this.formAnillo.get('activo').setValue(anillo.activo);
          this.formAnillo.get('contenedores').setValue(anillo.contenedor);
          this.formAnillo.get('proyecto').setValue(anillo.proyecto, {emitEvent: false});
          this.formAnillo.get('grupos').setValue(anillo.grupos);
          this.contenedorActual = anillo.contenedor;
          this.bateria1 = anillo.bateria1;
          this.bateria2 = anillo.bateria2;
          this.conectado = anillo.conectado;
          this.desconectado = anillo.desconectado;
          this.version = anillo.version;
          this.config = anillo.config;
          this.firmware = anillo.firmware;
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  getDefaultConfig() {
    return {
      fecha: 0,
      dias: 0,
      hora: 0,
      minutos: 0,
      segundos: 0,
      repeticiones: 0,
      frecuencia: 0,
    }
  }

  getDefaultFirmware() {
    return {
    }
  }

  saveAnillo() {
    const contenedor = this.formAnillo.get('contenedores').value || null;
    const anillo: Anillo = new Anillo (
      this.formAnillo.get('serial').value,
      this.formAnillo.get('nombre').value,
      contenedor,
      this.formAnillo.get('modelo').value,
      this.bateria1,
      this.bateria2,
      this.conectado === undefined ? new Date() : this.conectado,
      this.desconectado === undefined ? new Date() : this.desconectado,
      this.version,
      this.config === undefined ? this.getDefaultConfig() : this.config,
      this.firmware === undefined ? this.getDefaultFirmware() : this.firmware,
      this.formAnillo.get('proyecto').value,
      this.formAnillo.get('grupos').value,
      this.formAnillo.get('activo').value,
    );
    if (contenedor !== this.contenedorActual)
      this.updateContenedor(anillo);
    if (this.id)
      this.updateAnillo(anillo);
    else
      this.createAnillo(anillo);
  }

  updateContenedor(anillo: Anillo) {
    console.log(this.contenedorActual, anillo.contenedor);
    if (this.contenedorActual)
      this.contenedoresService.putContenedorBySerial(this.contenedorActual, {anillo: null}).subscribe(
        (doc: any) => console.log(doc),
        (err: any) => console.log(err)
      );
    if (anillo.contenedor)
      this.contenedoresService.putContenedorBySerial(anillo.contenedor, {anillo: anillo.serial}).subscribe(
        (doc: any) => console.log(doc),
        (err: any) => console.log(err)
      );
  }

  createAnillo(anillo: Anillo) {
    this.anillosService.postAnillo(anillo).subscribe(
      (doc: any) => {
        this.toastService.success(`Anillo creado correctamente`);
        this.router.navigate(['/anillos']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el anillo");
      }
    );
  }

  updateAnillo(anillo: Anillo) {
    this.anillosService.putAnillo(this.id, anillo).subscribe(
      (doc: any) => {
        this.toastService.success(`Anillo actualizado correctamente`);
        this.router.navigate(['/anillos']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo actualizar el anillo");
      }
    );
  }

  showValidacion() {
    this.formAnillo.controls.nombre.markAsTouched();
    this.formAnillo.controls.modelo.markAsTouched();
  }

  get serial() {return this.formAnillo.get('serial');}
  get nombre() {return this.formAnillo.get('nombre');}
  get modelo() {return this.formAnillo.get('modelo');}
  get activo() {return this.formAnillo.get('activo');}

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
