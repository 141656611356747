<table mdbTable small="true" hover="true">
  <thead>
    <tr>
      <th [mdbTableSort]="usuarios" sortBy="usuario">Usuarios <mdb-icon fas icon="sort"></mdb-icon></th>
      <th [mdbTableSort]="usuarios" sortBy="nobonus" class="text-right">No Bonificados <mdb-icon fas icon="sort"></mdb-icon></th>
      <th [mdbTableSort]="usuarios" sortBy="total" class="text-right">Total <mdb-icon fas icon="sort"></mdb-icon></th>
    </tr>
  </thead>
  <tbody>
    <tr mdbTableCol *ngFor="let u of usuarios">
      <td>
        <a routerLink="/{{ uri }}-usuario/{{ u.usuario }}/{{ uri === 'contenedores' ? contenedor : producto }}"
          [queryParams]="{inicio: inicio, fin: fin}">
          {{ u.nombre }} {{ u.apellidos }}
        </a>
      </td>
      <td class="text-right">{{u.nobonus || '-'}}</td>
      <td class="text-right">{{u.total}}</td>
    </tr>
  </tbody>
</table>
<app-pagination [total]="total" (changePage)="onChangePage($event)"></app-pagination>
