<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button *ngIf="isAdmin" routerLink="/anillo-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Anillos</h3>
          <div></div>
        </div>
        <div class="px-4">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>IMEI</th>
                  <th>Contenedor</th>
                  <th>Batería</th>
                  <th *ngIf="isSuperAdmin">Proyecto</th>
                  <!--<th *ngIf="isSuperAdmin">Version</th>
                  <th *ngIf="isSuperAdmin">Firmware</th>-->
                  <th>Conectado</th>
                  <th>Sensores</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let a of anillos">
                  <td scope="row">
                    {{ a.nombre }}
                    <mdb-icon *ngIf="!a.activo" fas icon="wrench" class="red-text" size="x" aria-hidden="true"></mdb-icon>
                  </td>
                  <td>{{ a.serial }}</td>
                  <td>{{ a.contenedor }}</td>
                  <td>
                      <mdb-icon fas [icon]="getIconBattery(a.bateria1)" [class]="getIconColor(a.bateria1)" size="lg" 
                      aria-hidden="true"></mdb-icon> {{ a.bateria1 }}%
                      <mdb-icon fas [icon]="getIconBattery(a.bateria2)" [class]="getIconColor(a.bateria2)" size="lg" 
                      aria-hidden="true"></mdb-icon> {{ a.bateria2 }}%
                  </td>
                  <td *ngIf="isSuperAdmin">{{ a.proyecto }}</td>
                  <!--<td *ngIf="isSuperAdmin">{{ a.version == 0 ? '' : a.version }}</td>
                  <td *ngIf="isSuperAdmin">{{ a?.firmware?.version }}</td>-->
                  <td>
                    <mdb-icon *ngIf="isDisconectOK(a.conectado, a.desconectado)" fas icon="exclamation-triangle" class="mr-1 red-text" size="m" aria-hidden="true"></mdb-icon>
                    {{ getUltimaConexion(a.conectado, a.desconectado) }}
                  </td>
                  <td>
                    <span *ngFor="let s of sortSensores(a.sensores)">
                      <mdb-icon fas [icon]="getIconSensor(s.sensor)" [class]="getSensorColor(s.ok, s.total)" size="m" 
                      aria-hidden="true"></mdb-icon>
                    </span>
                  </td>
                  <td class="text-right">
                    <a routerLink="/anillos-anillo/{{a.serial}}" class="blue-text" mdbTooltip="Información" placement="top">
                      <mdb-icon fas icon="eye" class="px-1"></mdb-icon>
                    </a>
                    <a routerLink="/anillo-editar/{{a._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                      <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                    </a>
                    <a *ngIf="isAdmin" class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(a._id, a.serial)">
                      <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-pagination dashboard="anillo" [total]="total" [limit]="limit" (changePage)="onChangePage($event)"></app-pagination>
      </mdb-card>
    </div>
  </div>
</section>
