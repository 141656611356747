import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup;

  constructor(
    public authService: AuthService,
    public router: Router,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.formLogin = new FormGroup({
      usuario: new FormControl('', Validators.required),
      clave: new FormControl('', Validators.required),
    });
  }

  login() {
    const usuario = this.formLogin.get('usuario').value;
    const clave = this.formLogin.get('clave').value;
    this.authService.login(usuario, clave).subscribe(res => {
      let redirect:any = this.router.parseUrl('/login');
      if (res.error)
        this.toastService.error('Usuario o contraseña invalida');
      else
        redirect = this.router.parseUrl('/');
      console.log(`redirect ${redirect}`);
      this.formLogin.reset();
      this.router.navigateByUrl(redirect);
    });
  }

  logout() {
    this.authService.logout();
  }  

  showValidacion() {
    this.formLogin.controls.usuario.markAsTouched();
    this.formLogin.controls.clave.markAsTouched();
  }

  get usuario() {return this.formLogin.get('usuario');}
  get clave() {return this.formLogin.get('clave');}
}
