import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string;

  private authURI = `${environment.endPointApi}`
  private helper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  login(usuario: string, clave: string) {
    const uri = `${this.authURI}/login`;
    return this.http.post(uri, {usuario: usuario, clave: clave}).pipe(
      catchError(this.handleError<any>('login', false)),
      tap(res => this.setSession(res))
    )
  }
  
  register(nombre: string, apellidos: string, correo: string, clave: string) {
    const user = {
      nombre: nombre,
      apellidos: apellidos,
      correo: correo,
      password: clave,
    };
    const uri = `${this.authURI}/register`
    return this.http.post(uri, user).pipe(
      catchError(this.handleError<any>('register', false)),
      //tap(res => this.setSession(res))
    )
  }

  private setSession(authResult) {
    if (!authResult && authResult.error)
      return;
    localStorage.setItem('token', authResult.token);
  }

  logout(): void {
    localStorage.removeItem("token");
    this.router.navigate(['/login']);
  }

  public isLoggedIn() {
    const token = localStorage.getItem('token');
    return  token && !this.helper.isTokenExpired(token);
  }

  public isClient() {
    const token = localStorage.getItem('token');
    const data = this.helper.decodeToken(token);
    return data ? data.client : false;
  }

  public isAdmin() {
    const token = localStorage.getItem('token');
    const data = this.helper.decodeToken(token);
    return data ? data.admin : false;
  }

  public isSuperAdmin() {
    const token = localStorage.getItem('token');
    const data = this.helper.decodeToken(token);
    return data ? data.superAdmin : false;
  }

  public username(): string {
    const token = localStorage.getItem('token');
    const data = this.helper.decodeToken(token);
    return data ? data.nombre : '';
  }

  public ownUserRoute() {
    const token = localStorage.getItem('token');
    const data = this.helper.decodeToken(token);
    return data ? data.uuid : '';
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }
}
