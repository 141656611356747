export class Contenedor {
    constructor(
        public nombre: string,
        public ubicacion: string,
        public serial: string,
        public coordenada: {type: string, coordinates: Array<number>},
        public activo: boolean,
        public proyecto: string,
        public grupos: Array<string>,
        public anillo: string,
    ) {}
}