import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { Configuracion } from '../models/configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configuracionesUri = `${environment.endPointApi}/configuracion`;

  constructor(
    private http: HttpClient,
  ) { }

  getConfiguraciones(): Observable<any> {
    const uri = `${this.configuracionesUri}/`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getConfiguraciones', []))
    );
  }

  postConfiguracion(configuracion: Configuracion): Observable<any> {
    const uri = `${this.configuracionesUri}/crud`;
    return this.http.post<Configuracion>(uri, configuracion).pipe(
      catchError(this.handleError<Configuracion>('postConfiguracion', null))
    );
  }

  putConfiguracion(id: string, configuracion: Configuracion): Observable<any> {
    const uri = `${this.configuracionesUri}/crud/${id}`;
    return this.http.put<Configuracion>(uri, configuracion).pipe(
      catchError(this.handleError<Configuracion>('putConfiguracion', null))
    );
  }

  deleteConfiguracion(id: string): Observable<any> {
    const uri = `${this.configuracionesUri}/crud/${id}`;
    return this.http.delete<Configuracion>(uri).pipe(
      catchError(this.handleError<Configuracion>('deleteConfiguracion', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
