import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

import * as dayjs from 'dayjs';

@Component({
  selector: 'app-incidencias-modal',
  templateUrl: './incidencias-modal.component.html',
  styleUrls: ['./incidencias-modal.component.scss']
})
export class IncidenciasModalComponent implements OnInit {

  action: Subject<any> = new Subject();
  heading: string;
  content: any;
  formIncidencias: FormGroup;
  fallas: Array<any>;
  correcciones: Array<any>;
  fallaSelected: Array<any>;
  correccionSelected: Array<any>;

  constructor(
    public modalRef: MDBModalRef,
  ) { }

  ngOnInit(): void {
    this.formIncidencias = new FormGroup({
      descripcion: new FormControl('', [Validators.required]),
      falla: new FormControl(''),
      correccion: new FormControl(''),
      fecha: new FormControl(''),
    });
    if (this.content.action === 'create') {
      const now = dayjs();
      const fecha = now.format('YYYY-MM-DDTHH:mm');
      this.fallas = this.content.fallas.map(k => { return { id: k.id, nombre: k.nombre, selected: false }; });
      this.formIncidencias.get('fecha').setValue(fecha, {emitEvent: false});
      this.correcciones = this.content.correcciones.map(k => { return { id: k.id, nombre: k.nombre, selected: false }; });
      this.fallaSelected = [];
      this.correccionSelected = [];
    } else {
      const now = this.content.fecha ? dayjs(this.content.fecha) : dayjs();
      const fecha = now.format('YYYY-MM-DDTHH:mm');
      this.formIncidencias.get('descripcion').setValue(this.content.descripcion, {emitEvent: false});
      this.formIncidencias.get('fecha').setValue(fecha, {emitEvent: false});
      this.fallaSelected = this.content.fallas.filter(k => this.content.fallaSelected.includes(k.nombre)).map(k => k.id);
      this.correccionSelected = this.content.correcciones.filter(k => this.content.correccionSelected.includes(k.nombre)).map(k => k.id);
      this.fallas = this.content.fallas.map(k => { return {id: k.id, nombre: k.nombre, selected: this.fallaSelected.includes(k.id)}; });
      this.correcciones = this.content.correcciones.map(k => { return {id: k.id, nombre: k.nombre, selected: this.correccionSelected.includes(k.id)}; });
    }
  }

  onSaveClick() {
    const descripcion = this.formIncidencias.get('descripcion').value;
    const fecha = dayjs(this.formIncidencias.get('fecha').value).toISOString()
    const fallas = this.fallaSelected;
    const correcciones = this.correccionSelected;
    this.action.next({ descripcion, fecha: fecha, fallas, correcciones });
  }

  onFallaChange(event) {
    const value = event.element.value;
    if (event.checked) {
      this.fallaSelected.push(value);
    } else {
      this.fallaSelected = this.fallaSelected.filter(k => k != value);
    }
  }

  onCorreccionChange(event) {
    const value = event.element.value;
    if (event.checked) {
      this.correccionSelected.push(value);
    } else {
      this.correccionSelected = this.correccionSelected.filter(k => k != value);
    }
  }

  onNoClick() {
    this.action.next(false);
  }

  showValidacion() {
    this.formIncidencias.controls.descripcion.markAsTouched();
  }

  get correcciones1() { return this.correcciones.slice(0, 20);}
  get correcciones2() { return this.correcciones.slice(20, 40);}
  get correcciones3() { return this.correcciones.slice(40);}

  get descripcion() { return this.formIncidencias.get('descripcion'); }
}
