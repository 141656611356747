import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Telecarga } from '../models/telecarga.model';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

@Injectable({
  providedIn: 'root'
})
export class TelecargaService {

  private telecargaUri = `${environment.endPointApi}/telecarga`;

  constructor(
    private http: HttpClient,
  ) {
    dayjs.extend(utc);
  }

  postUsuarioTelecarga(codigo:string, tipo: string): Observable<Telecarga> {
    const telecarga: Telecarga = new Telecarga(
      this.getCurrentDate(),
      tipo,
      'create',
      codigo
    );
    return this.http.post<Telecarga>(`${this.telecargaUri}/crud`, telecarga).pipe(
      catchError(this.handleError<any>('getProductoResumen', null))
    );
  }

  deleteUsuarioTelecarga(codigo: string, tipo: string): Observable<Telecarga> {
    const telecarga: Telecarga = new Telecarga(
      this.getCurrentDate(),
      tipo,
      'delete',
      codigo
    );
    return this.http.post<Telecarga>(`${this.telecargaUri}/crud`, telecarga).pipe(
      catchError(this.handleError<any>('getProductoResumen', null))
    );
  }

  postProductoTelecarga(producto: string): Observable<Telecarga> {
    const telecarga: Telecarga = new Telecarga(
      this.getCurrentDate(),
      'producto',
      'create',
      producto
    );
    return this.http.post<Telecarga>(`${this.telecargaUri}/crud`, telecarga).pipe(
      catchError(this.handleError<any>('getProductoResumen', null))
    );
  }

  deleteProductoTelecarga(producto: string): Observable<Telecarga> {
    const telecarga: Telecarga = new Telecarga(
      this.getCurrentDate(),
      'producto',
      'delete',
      producto
    );
    return this.http.post<Telecarga>(`${this.telecargaUri}/crud`, telecarga).pipe(
      catchError(this.handleError<any>('getProductoResumen', null))
    );
  }

  private getCurrentDate(): number {
    const now = new Date();
    const utcMilllisecondsSinceEpoch = now.getTime();
    const utcSecondsSinceEpoch = Math.ceil(utcMilllisecondsSinceEpoch / 1000);
    return utcSecondsSinceEpoch;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
