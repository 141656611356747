export class Incidencia {
  constructor(
      public titulo: string,
      public descripcion: string,
      public estado: string,
      public fecha: Date,
      public responsable: string,
      public contenedor: string,
      public anillo: string,
      public electronica: string,
      public proyecto: string,
      public grupos: Array<string>,
      public fallas: Array<Number>,
      public correcciones: Array<Number>,
  ) {}
}