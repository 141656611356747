import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  formRegister: FormGroup;  

  constructor(
    public authService: AuthService,
    public router: Router,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.formRegister = new FormGroup({
      nombre: new FormControl('', Validators.required),
      apellidos: new FormControl('', Validators.required),
      correo: new FormControl('', [Validators.required, Validators.email]),
      clave: new FormControl('', Validators.required),
    });
  }

  register() {
    const nombre = this.formRegister.get('nombre').value;
    const apellidos = this.formRegister.get('apellidos').value;
    const correo = this.formRegister.get('correo').value;
    const clave = this.formRegister.get('clave').value;
    this.authService.register(nombre, apellidos, correo, clave).subscribe(res => {
      let redirect:any = this.router.parseUrl('/register');
      if (res.error)
        this.toastService.error('Formulario no valido');
      else {
        this.toastService.info('Usuario registrado correctamente');
        redirect = this.router.parseUrl('/login');
      }
      this.formRegister.reset();
      this.router.navigateByUrl(redirect);
    });
  }

  showValidacion() {
    this.formRegister.get('nombre').markAsTouched();
    this.formRegister.get('apellidos').markAsTouched();
    this.formRegister.get('correo').markAsTouched();
    this.formRegister.get('clave').markAsTouched();
  }

  get nombre() { return this.formRegister.get('nombre'); }
  get apellidos() { return this.formRegister.get('apellidos'); }
  get correo() { return this.formRegister.get('correo'); }
  get clave() { return this.formRegister.get('clave'); }
}
