import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Producto } from 'src/app/models/producto.model';
import { TelecargaService } from 'src/app/telecarga/telecarga.service';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-dashboard-productos',
  templateUrl: './dashboard-productos.component.html',
  styleUrls: ['./dashboard-productos.component.scss']
})
export class DashboardProductosComponent implements OnInit {

  productos: Array<Producto> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 20;
  skip: number = 0;

  constructor(
    private productosService : ProductosService,
    private telecargaService: TelecargaService,
    private toastService: ToastService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
    this.getProductos();
  }

  getProductos(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.productosService.getProductoCount().subscribe(
      resumen => this.total = resumen.total
    );
    this.productosService.getProductos(options).subscribe(
      productos => this.productos = productos
    );
  }

  onChangePage(event) {
    this.skip = event.skip;
    this.limit = event.limit;
    this.getProductos();
  }

  openModal(id: string, nombre: string, sku: string) {
    const options = {
      data: {
        heading: 'Productos',
        content: { heading: nombre, description: '¿Desea eliminar este producto?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.productosService.deleteProducto(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El producto fue eliminado correctamente');
              if (doc.bonus)
                this.telecargaService.deleteProductoTelecarga(sku).subscribe(
                  (doc: any) => console.log(doc),
                  (error: any) => console.log(error)
                );
              this.getProductos();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }
}
