<section class="my-3">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <mdb-card cascade="true" narrower="true">
          <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3">
            <h3 class="white-text mx-3">Producto</h3>
          </div>
          <mdb-card-body>
            <form class="border border-light p-4" [formGroup]="formProducto" (ngSubmit)="saveProducto()">
              <div class="md-form">
                <input mdbInput mdbValidate type="text" id="nombre" class="form-control" formControlName="nombre">
                <label for="nombre">Nombre</label>
                <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
              </div>
              <div class="md-form">
                <input mdbInput mdbValidate type="text" id="tipo" class="form-control" formControlName="tipo">
                <label for="tipo">Tipo</label>
                <mdb-error *ngIf="tipo.invalid && (tipo.dirty || tipo.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                <mdb-success *ngIf="tipo.valid && (tipo.dirty || tipo.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
              </div>
              <div class="md-form">
                <input mdbInput mdbValidate type="text" id="sku" class="form-control" formControlName="sku">
                <label for="sku">SKU</label>
                <mdb-error *ngIf="sku.invalid && (sku.dirty || sku.touched)"><mdb-icon fas icon="times">Campo requerido</mdb-icon></mdb-error>
                <mdb-success *ngIf="sku.valid && (sku.dirty || sku.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
              </div>
              <div class="md-form">
                <mdb-checkbox formControlName="bonus">Bonificado</mdb-checkbox>
              </div>
              <button mdbBtn color="danger" routerLink="/productos"  mdbWavesEffect size="sm">Cancelar</button>
              <button *ngIf="formProducto.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
              <button *ngIf="formProducto.valid" mdbBtn type="submit" color="success" mdbWavesEffect size="sm">
                <mdb-icon fas icon="save"></mdb-icon> Guardar
              </button>
            </form>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </section>
