import { Component, Input, OnInit } from '@angular/core';
import { ContenedoresService } from '../contenedores.service';

@Component({
  selector: 'app-bateria-info-chart',
  templateUrl: './bateria-info-chart.component.html',
  styleUrls: ['./bateria-info-chart.component.scss']
})
export class BateriaInfoChartComponent implements OnInit {

  data: Array<Number> = [];
  
  @Input() contenedor: string;

  constructor(
    private contenedoresService: ContenedoresService,
  ) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.contenedoresService.getContenedorBateriaResumen(this.contenedor, {}).subscribe(
      dataGrafica => this.data = dataGrafica
    );
  }
}
