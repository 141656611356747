import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Contenedor } from 'src/app/models/contenedor.model';
import { ContenedoresService } from '../contenedores.service';
import { GruposService } from 'src/app/grupos/grupos.service';
import { AnillosService } from 'src/app/anillos/anillos.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-contenedor',
  templateUrl: './contenedor.component.html',
  styleUrls: ['./contenedor.component.scss']
})
export class ContenedorComponent implements OnInit {

  id: string;
  formContenedor: FormGroup;
  lng: number = 0;
  lat: number = 0;
  direction: string;
  bateria: number = 0;
  timer: any;
  timeoutVal: number = 500;
  conectado: Date;
  version: number = 0;
  firmware: any;
  config: any;
  proyectos: Array<any>;
  grupos: Array<any>;
  anillos : Array<any>;
  anilloActual: string;

  constructor(
    private contenedoresService: ContenedoresService,
    private gruposService: GruposService,
    private anillosService: AnillosService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formContenedor = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      ubicacion: new FormControl('', [Validators.required]),
      serial: new FormControl('', [Validators.required]),
      street: new FormControl(''),
      latitude: new FormControl(0, [Validators.required]),
      longitude: new FormControl(0, [Validators.required]),
      activo: new FormControl(false),
      proyecto: new FormControl(''),
      grupos: new FormControl(''),
      anillos: new FormControl(''),
    });
    this.formContenedor.get('proyecto').valueChanges.subscribe((proyecto: any) => {
      this.gruposService.getGruposByProyecto(proyecto).subscribe((grupos: Array<any>) => {
        this.grupos = grupos;
      });
      this.anillosService.getAnillosAvailablesByProyectoWithoutContenedor(proyecto).subscribe((anillos: Array<any>) => {
        this.anillos = [{serial: '', nombre: ''}].concat(anillos);
      });
    });
  }

  handleKeyPress(e) {
    window.clearTimeout(this.timer);
  }

  handleKeyUp(e) {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.direction = this.formContenedor.get('street').value;
    }, this.timeoutVal);
  }

  onChangePosition(event) {
    this.lat = event.lat;
    this.lng = event.lng;
    this.formContenedor.get('longitude').setValue(event.lng);
    this.formContenedor.get('latitude').setValue(event.lat);
  }

  loadBasicData() {
    this.contenedoresService.getBasicData().subscribe( (data: any) => {
      this.proyectos = data.proyectos;
      this.grupos = data.grupos;
      this.anillos =  [{serial: '', nombre: ''}].concat(data.anillos);
      this.formContenedor.get('proyecto').setValue(data.proyecto || '', { emitEvent: false });
    });
  }

  loadFormData() {
    this.contenedoresService.getContenedor(this.id).subscribe(
      (res: any) => {
        if (res?.contenedor === undefined)
          this.toastService.warning('Contenedor no existe en base de datos');
        else {
          this.grupos = res.grupos;
          this.proyectos = res.proyectos;
          const contenedor: Contenedor = res.contenedor;
          this.anillos = [{serial: '', nombre: ''}].concat(res.anillos);
          this.formContenedor.get('nombre').setValue(contenedor.nombre);
          this.formContenedor.get('ubicacion').setValue(contenedor.ubicacion);
          this.formContenedor.get('proyecto').setValue(contenedor.proyecto, {emitEvent: false});
          this.formContenedor.get('grupos').setValue(contenedor.grupos);
          this.formContenedor.get('serial').setValue(contenedor.serial);
          this.formContenedor.get('longitude').setValue(contenedor.coordenada.coordinates[0]);
          this.formContenedor.get('latitude').setValue(contenedor.coordenada.coordinates[1]);
          this.formContenedor.get('activo').setValue(contenedor.activo);
          this.formContenedor.get('anillos').setValue(contenedor.anillo);
          this.anilloActual = contenedor.anillo;
          this.lng = contenedor.coordenada.coordinates[0];
          this.lat = contenedor.coordenada.coordinates[1];
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  getDefaultConfig() {
    return {
      fecha: 0,
      dias: 0,
      hora: 0,
      minutos: 0,
      segundos: 0,
      repeticiones: 0,
      frecuencia: 0,
    }
  }

  getDefaultFirmware() {
    return {
    }
  }

  saveContenedor() {
    const anillo = this.formContenedor.get('anillos').value || null;
    const contenedor: Contenedor = new Contenedor (
      this.formContenedor.get('nombre').value,
      this.formContenedor.get('ubicacion').value,
      this.formContenedor.get('serial').value,
      {
        type: 'Point',
        coordinates: [
          parseFloat(this.formContenedor.get('longitude').value),
          parseFloat(this.formContenedor.get('latitude').value)
        ]
      },
      this.formContenedor.get('activo').value,
      this.formContenedor.get('proyecto').value,
      this.formContenedor.get('grupos').value,
      anillo,
    );
    if (anillo !== this.anilloActual)
      this.updateAnillo(contenedor);
    if (this.id)
      this.updateContenedor(contenedor);
    else
      this.createContenedor(contenedor);
  }

  updateAnillo(contenedor: Contenedor) {
    if (this.anilloActual)
      this.anillosService.putAnilloBySerial(this.anilloActual, {contenedor: null}).subscribe(
        (doc: any) => console.log(doc),
        (err: any) => console.log(err)
      );
    if (contenedor.anillo)
      this.anillosService.putAnilloBySerial(contenedor.anillo, {contenedor: contenedor.serial}).subscribe(
        (doc: any) => console.log(doc),
        (err: any) => console.log(err)
      );
  }

  createContenedor(contenedor: Contenedor) {
    this.contenedoresService.postContenedor(contenedor).subscribe(
      (doc: any) => {
        this.toastService.success(`Contenedor creado correctamente`);
        this.router.navigate(['/contenedores']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el contenedor");
      }
    );
  }

  updateContenedor(contenedor: Contenedor) {
    this.contenedoresService.putContenedor(this.id, contenedor).subscribe(
      (doc: any) => {
        this.toastService.success(`Contenedor actualizado correctamente`);
        this.router.navigate(['/contenedores']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el contenedor");
      }
    );
  }

  showValidacion() {
    this.formContenedor.controls.nombre.markAsTouched();
    this.formContenedor.controls.ubicacion.markAsTouched();
    this.formContenedor.controls.serial.markAsTouched();
    this.formContenedor.controls.street.markAsTouched();
    this.formContenedor.controls.latitude.markAsTouched();
    this.formContenedor.controls.longitude.markAsTouched();
  }

  upadateLatitude() {
    this.lat = this.formContenedor.get('latitude').value;
  }

  upadateLongitude() {
    this.lng = this.formContenedor.get('longitude').value;
  }

  get nombre() {return this.formContenedor.get('nombre');}
  get ubicacion() {return this.formContenedor.get('ubicacion');}
  get serial() {return this.formContenedor.get('serial');}
  get street() {return this.formContenedor.get('street');}
  get longitude() {return this.formContenedor.get('longitude');}
  get latitude() {return this.formContenedor.get('latitude');}
  get activo() {return this.formContenedor.get('activo');}
  get proyecto() { return this.formContenedor.get('proyecto'); }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
