<div class="d-flex justify-content-center">
  <nav aria-label="Page navigation example" class="text-center">
    <ul class="pagination pagination-circle pg-blue">
      <li class="page-item" [class.disabled]="page === 0" (click)="navegar(0)">
        <button class="page-link" aria-label="Previous" mdbWavesEffect>
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </button>
      </li>
      <li *ngFor="let p of pages" class="page-item" [class.active]="p === page">
        <button class="page-link" mdbWavesEffect (click)="navegar(p)">
          {{ p + 1 }}
        </button>
      </li>
      <li
        class="page-item"
        [class.disabled]="page === last"
        (click)="navegar(last)"
      >
        <button class="page-link" aria-label="Next" mdbWavesEffect>
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </button>
      </li>
    </ul>
  </nav>
</div>
