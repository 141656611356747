import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const MAX_PAGES:number = 9;
const HALF_PAGE:number = 4;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
 
  @Input() total: number  = 0;
  
  @Input() limit: number = 10;
  @Input() page: number = 0;
  @Input() dashboard: string;

  @Output() changePage = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (typeof this.limit === 'string')
      this.limit = parseInt(this.limit);
    const doc = sessionStorage.getItem('page');
    let page;
    try {
      page = JSON.parse(doc);
    } catch (err) {
      page = undefined
    }
    if (page?.dashboard === this.dashboard) {
      this.page = page.value;
    } else 
      this.setDashboardPage();
    this.changePage.emit({ limit: this.limit, skip: this.limit * this.page })
  }

  get pages(): Array<number>[] {
    const totalPages = Math.ceil(this.total / this.limit);
    let start = 0;
    if (totalPages > MAX_PAGES && this.page - HALF_PAGE > 0)
      start = this.page - HALF_PAGE - (this.page + HALF_PAGE >= totalPages ? this.page + HALF_PAGE + 1 - totalPages: 0);
    return Array(totalPages > MAX_PAGES ? MAX_PAGES : totalPages).fill(start).map((k, i) => k + i);
  }

  get last(): number {
    return Math.ceil(this.total / this.limit) - 1;
  }

  navegar(page: number) {
    this.page = page;
    this.setDashboardPage();
    this.changePage.emit({ limit: this.limit, skip: this.limit * this.page });
  }

  setDashboardPage() {
    const doc = {dashboard: this.dashboard, value: this.page };
    sessionStorage.setItem('page', JSON.stringify(doc));
  }
}
