import { Component, OnInit } from '@angular/core';
import { ContenedoresService } from '../contenedores/contenedores.service';
import { Contenedor } from '../models/contenedor.model';
import { SensorlogsService } from '../sensorlogs/sensorlogs.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  anillos: Array<any>;
  anillosOffline: Array<any>;
  anillosDisconnect: Array<any>;

  constructor(
    private sensorlogsService: SensorlogsService,
  ) { }

  ngOnInit(): void {
    this.getSensores();
  }

  getSensores() {
    this.sensorlogsService.getAnillosByAvailability().subscribe(
      (doc: any) => {
        this.anillos = doc.availability.filter(v => v.activo);
        this.anillosOffline = doc.offline;
        this.anillosDisconnect = doc.disconnect;
      },
      (error: any) => console.log(error)
    );
  }

}
