import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from 'src/app/auth/auth.service';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Electronica } from 'src/app/models/electronica.model';
import { ElectronicasService } from '../electronicas.service';
import { environment } from 'src/environments/environment';

import 'dayjs/locale/es'
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-dashboard-electronicas',
  templateUrl: './dashboard-electronicas.component.html',
  styleUrls: ['./dashboard-electronicas.component.scss']
})
export class DashboardElectronicasComponent implements OnInit {

  electronicas: Array<Electronica> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;

  constructor(
    private toastService: ToastService,
    private modalService: MDBModalService,
    private authService: AuthService,
    private electronicaService: ElectronicasService,
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getElectronicas();
  }

  gotoURL(serial: string) {
    const token = localStorage.getItem('token');
    window.open(`${environment.socketIO}/${serial}?token=${token}`, '_blank');
  }

  getConexionRemota(fecha) {
    if (!fecha)
      return '';
    const now = dayjs(fecha);
    return now > dayjs() ? (now.fromNow() + now.format(' (DD/MM/YYYY HH:mm)')) : '';
  }

  getElectronicas(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.electronicaService.getElectronicasCount().subscribe(
      resumen => this.total = resumen?.total || 0
    );
    this.electronicaService.getElectronicas(options).subscribe(
      electronicas => this.electronicas = electronicas 
    );
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Electrónicas',
        content: { heading: nombre, description: '¿Desea eliminar esta electrónica?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.electronicaService.deleteElectronica(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('La electrónica fue eliminada correctamente');
              this.getElectronicas();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }

  upgradeVersion(electronica: any) {
    const firmware = electronica?.firmware;
    const version = electronica?.firmwareConfig?.version;
    return version && (firmware != version);
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
