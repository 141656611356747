import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Contenedor } from 'src/app/models/contenedor.model';
import { ContenedoresService } from '../contenedores.service';
import { saveAs } from 'file-saver';

import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { RecicladosService } from 'src/app/reciclados/reciclados.service';

@Component({
  selector: 'app-dashboard-contenedor',
  templateUrl: './dashboard-contenedor.component.html',
  styleUrls: ['./dashboard-contenedor.component.scss']
})
export class DashboardContenedorComponent implements OnInit {

  contenedor: string;
  uri: string;
  startDate: string;
  endDate: string;
  datamatrix: string;
  container: Contenedor;

  constructor(
    private activatedRoute: ActivatedRoute,
    private contenedorService: ContenedoresService,
    private recicladosService: RecicladosService,
  ) {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  ngOnInit(): void {
    this.contenedor = this.activatedRoute.snapshot.params.contenedor;
    this.uri = this.activatedRoute.snapshot.data.uri;
    const now = dayjs();
    this.startDate = now.add(-7, 'day').startOf('day').toISOString();
    this.endDate = now.endOf('day').toISOString();
    this.contenedorService.getContenedorBySerial(this.contenedor).subscribe(container => this.container = container);
  }

  onRangeDated(doc: any) {
    if (doc.start)
      this.startDate = doc.start;
    if (doc.end)
      this.endDate = doc.end;
  }

  onExportCSV() {
    const options = {
      datamatrix: this.contenedor
    };
    if (this.startDate)
      options['inicio'] = this.startDate;
    if (this.endDate)
      options['fin'] = this.endDate;
    this.recicladosService.getRecicladoCSV(options).subscribe(
      resultado => saveAs(new Blob([resultado], {type: "text/plain;charset=utf-8"}), "resultados.csv")
    );
  }
}
