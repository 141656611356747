import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SensorlogsService {
  private sensorLogsUri = `${environment.endPointApi}/sensorlog`;

  constructor(
    private http: HttpClient,
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  getAnillosByAvailability() {
    return this.http.get<any[]>(`${this.sensorLogsUri}/rest/anillos/availability`).pipe(
      catchError(this.handleError<any[]>('getAnillosByAvailability', []))
    );
  }

  getSensorLogsBySerialAndDates(serial: string, options: any) : Observable<any[]> {
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${this.sensorLogsUri}/rest/serial/${serial}${query.length ? '?': ''}${query}`).pipe(
      catchError(this.handleError<any[]>('getSensorLogsBySerialAndDates', []))
    );
  }

  getLastMatrixLogBySerial(serial: string) : Observable<any> {
    return this.http.get<any>(`${this.sensorLogsUri}/rest/serial/${serial}/matrix`).pipe(
      catchError(this.handleError<any>('getLastMatrixLogBySerial', {}))
    );
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
