import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DatasetChartJs } from 'src/app/models/dataset-chart-js.model';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-productos-error-chart',
  templateUrl: './productos-error-chart.component.html',
  styleUrls: ['./productos-error-chart.component.scss']
})
export class ProductosErrorChartComponent implements OnInit {

  @Input() name: string;
  @Input() producto: string;
  @Input() contenedor: string;
  @Input() usuario: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'bar';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(101, 166, 3, .85)',
      borderColor: 'rgba(101, 166, 3, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(171, 217, 4, .85)',
      borderColor: 'rgba(171, 217, 4, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(215, 242, 119, .85)',
      borderColor: 'rgba(215, 242, 119, .95)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(174, 191, 111, .65)',
      borderColor: 'rgba(174, 191, 111, .95)',
      borderWidth: 0,
    },
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Resumen de Estatus por Día' },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          precision: 0
        },
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor(
    private productoService: ProductosService,
  ) { }

  ngOnInit(): void {
    this.name = this.name ? `${this.name}.productosErrorChart` : 'productosErrorChart';
    this.loadProducts();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadProducts();
  }

  loadProducts() {
    const options: any = {};
    if (this.producto)
      options.producto = this.producto;
    if (this.contenedor)
      options.contenedor = this.contenedor;
    if (this.usuario)
      options.usuario = this.usuario;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    this.productoService.getProductoErrorResumen(options).subscribe(
      resumen => this.createChartByDate(resumen)
    );
  }

  createChartByDate(resumen: DatasetChartJs) {
    this.chartLabels = resumen.labels;
    this.chartDatasets = resumen.chartDatasets;
  }
}
