import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Producto } from 'src/app/models/producto.model';
import { TelecargaService } from 'src/app/telecarga/telecarga.service';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {

  id: string;
  formProducto: FormGroup;

  constructor(
    private productosService: ProductosService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private telecargaService: TelecargaService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
  }
  
  createForm() {
    this.formProducto = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      tipo: new FormControl('', [Validators.required]),
      sku: new FormControl('', [Validators.required]),
      bonus: new FormControl(false),
    });
  }

  loadFormData() {
    this.productosService.getProducto(this.id).subscribe(
      (res: any) => {
        if (res?.nombre === undefined)
          this.toastService.warning('Producto no existe en base de datos');
        else {
          const producto: Producto = res;
          this.formProducto.get('nombre').setValue(producto.nombre);
          this.formProducto.get('tipo').setValue(producto.tipo);
          this.formProducto.get('sku').setValue(producto.sku);
          this.formProducto.get('bonus').setValue(producto.bonus);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveProducto() {
    const producto: Producto = new Producto (
      this.formProducto.get('nombre').value,
      this.formProducto.get('tipo').value,
      this.formProducto.get('sku').value,
      this.formProducto.get('bonus').value,
    );
    if (this.id)
      this.updateProducto(producto);
    else
      this.createProducto(producto);
  }

  createProducto(producto: Producto) {
    this.productosService.postProducto(producto).subscribe(
      (producto: any) => {
        this.toastService.success(`Producto creado correctamente`);
        if (producto.bonus)
          this.telecargaService.postProductoTelecarga(producto.sku).subscribe(
            (telecarga: any) => console.log(telecarga),
            (error: any) => console.log(error)
          );
        this.router.navigate(['/productos']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el producto");
      }
    );
  }

  updateProducto(producto: Producto) {
    this.productosService.putProducto(this.id, producto).subscribe(
      (old_doc: any) => {
        console.log(old_doc)
        console.log(producto)
        if (producto.sku !== old_doc.sku) {
          if (old_doc.bonus)
            this.telecargaService.deleteProductoTelecarga(old_doc.sku).subscribe(
              (telecarga: any) => console.log(telecarga),
              (error: any) => console.log(error)
            );
          if (producto.bonus)
            this.telecargaService.postProductoTelecarga(producto.sku).subscribe(
              (telecarga: any) => console.log(telecarga),
              (error: any) => console.log(error)
            );
        }else if (producto.bonus !== old_doc.bonus) {
          const method =  old_doc.bonus ? this.telecargaService.deleteProductoTelecarga(producto.sku)
            : this.telecargaService.postProductoTelecarga(producto.sku);
          method.subscribe(
            (telecarga: any) => console.log(telecarga),
            (error: any) => console.log(error)
          );
        }
        this.toastService.success(`Producto actualizado correctamente`);
        this.router.navigate(['/productos']);
      },
      (error: any) => {
        console.log("Mensaje de error");
        this.toastService.error("No se pudo crear el producto");
      }
    );
  }

  showValidacion() {
    this.formProducto.controls.nombre.markAsTouched();
    this.formProducto.controls.tipo.markAsTouched();
    this.formProducto.controls.sku.markAsTouched();
    this.formProducto.controls.bonus.markAsTouched();
  }

  get nombre() {return this.formProducto.get('nombre');}
  get tipo() {return this.formProducto.get('tipo');}
  get sku() {return this.formProducto.get('sku');}
  get bonus() {return this.formProducto.get('bonus');}

}
