import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { ElectronicaFirmware} from 'src/app/models/electronica-firmware.model';
import { ElectronicasService } from 'src/app/electronicas/electronicas.service';
import { FirmwareService } from '../firmware.service';

@Component({
  selector: 'app-dashboard-firmware',
  templateUrl: './dashboard-firmware.component.html',
  styleUrls: ['./dashboard-firmware.component.scss']
})
export class DashboardFirmwareComponent implements OnInit {

  firmware: string;
  version: Object;
  nombre: string;
  electronicas: Array<ElectronicaFirmware> = [];
  total: number = 0;
  limit: number =  10;
  skip: number = 0;
  selected: Array<String> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private firmwareService: FirmwareService,
    private electronicasService: ElectronicasService,
  ) { }

  ngOnInit(): void {
    this.firmware = this.activatedRoute.snapshot.params.id;
  }

  loadElectronicas() {
    this.firmwareService.getFirmware(this.firmware).subscribe(
      firmware => {
        this.nombre = firmware ? firmware.nombre : '';
        const name = firmware ? firmware.version : '';
        this.version = {
          version: name,
          ip: firmware.ip,
          port: firmware.puerto
        };
        this.electronicasService.getElectronicasByFirmware(name, {skip: this.skip, limit: this.limit}).subscribe(doc => {
          this.electronicas = doc['electronicas'];
          this.total = doc['total'];
        });
      }
    );
  }

  toggleIndex(id) {
    const index = this.selected.findIndex(idx => idx === id);
    if (index < 0)
      this.selected.push(id);
    else 
      this.selected.splice(index, 1);
  }

  isChecked(id) {
    return this.selected.findIndex(idx => idx === id) >= 0;
  }

  actualizarVersiones() {
    this.electronicasService.udpateFirmwareVersion(this.version, this.selected).subscribe(
      resultado => {
        this.toastService.success(`Version de telecarga actualizada`);
        this.router.navigate(['/firmwares']);
      }
    );
  }

  limpiarVersiones() {
    this.electronicasService.cleanFirmwareVersion(this.version, this.selected).subscribe(
      resultado => {
        this.toastService.success(`Version de telecarga actualizada`);
        this.router.navigate(['/firmwares']);
      }
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.loadElectronicas();
  }
}
