import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Proyecto } from 'src/app/models/proyecto.model';
import { ProyectosService } from '../proyectos.service';

@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html',
  styleUrls: ['./proyecto.component.scss']
})
export class ProyectoComponent implements OnInit {

  id: string;
  formProyecto: FormGroup;

  constructor(
    private proyectosService: ProyectosService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,    
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
  }

  createForm() {
    this.formProyecto = new FormGroup({
      uuid: new FormControl(''),
      nombre: new FormControl('', [Validators.required]),
      spike: new FormControl('', [Validators.required]),
    });
  }

  loadFormData() {
    this.proyectosService.getProyecto(this.id).subscribe(
      (res: any) => {
        if (res?._id === undefined)
          this.toastService.warning('Proyecto no existe en base de datos');
        else {
          const proyecto: Proyecto = res;
          this.formProyecto.get('uuid').setValue(proyecto.uuid);
          this.formProyecto.get('nombre').setValue(proyecto.nombre);
          this.formProyecto.get('spike').setValue(proyecto.spike);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveProyecto() {
    const proyecto: Proyecto = new Proyecto (
      this.formProyecto.get('uuid').value || undefined,
      this.formProyecto.get('nombre').value,
      this.formProyecto.get('spike').value,
    );
    if (this.id)
      this.updateProyecto(proyecto);
    else
      this.createProyecto(proyecto);
  }

  createProyecto(proyecto: Proyecto) {
    this.proyectosService.postProyecto(proyecto).subscribe(
      (doc: any) => {
        this.toastService.success(`Proyecto creado correctamente`);
        this.router.navigate(['/proyectos']);
      },
      (error: any) => {
        console.log(`Proyecto - Create: ${error}`);
        this.toastService.error("No se pudo crear el proyecto");
      }
    );
  }

  updateProyecto(proyecto: Proyecto) {
    this.proyectosService.putProyecto(this.id, proyecto).subscribe(
      (prev: any) => {
        this.toastService.success(`Proyecto actualizado correctamente`);
        this.router.navigate(['/proyectos']);
      },
      (error: any) => {
        console.log(`Proyecto - Update: ${error}`);
        this.toastService.error("No se pudo crear el proyecto");
      }
    );
  }

  showValidacion() {
    this.formProyecto.controls.nombre.markAsTouched();
  }

  get uuid() { return this.formProyecto.get('uuid'); }
  get nombre() { return this.formProyecto.get('nombre'); }
  get spike() { return this.formProyecto.get('spike'); }
}
