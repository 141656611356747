import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

import { Grupo } from 'src/app/models/grupo.models';
import { GruposService } from '../grupos.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit {

  id: string;
  formGrupo: FormGroup;
  proyectos: Array<any>;

  constructor(
    private gruposService: GruposService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.createForm();
    if (this.id)
      this.loadFormData();
    else
      this.loadBasicData();
  }

  createForm() {
    this.formGrupo = new FormGroup({
      uuid: new FormControl(''),
      nombre: new FormControl('', [Validators.required]),
      proyectos: new FormControl('', [Validators.required]),
    });
  }

  loadBasicData() {
    this.gruposService.getBasicData().subscribe( (data: any) => {
      this.proyectos = data.proyectos;
      this.formGrupo.get('proyectos').setValue(data.proyecto || '', { emitEvent: false });
    });
  }

  loadFormData() {
    this.gruposService.getGrupo(this.id).subscribe(
      (res: any) => {
        if (res?.grupo === undefined)
          this.toastService.warning('Grupo no existe en base de datos');
        else {
          const grupo: Grupo = res.grupo;
          this.proyectos = res.proyectos;
          this.formGrupo.get('uuid').setValue(grupo.uuid);
          this.formGrupo.get('nombre').setValue(grupo.nombre);
          this.formGrupo.get('proyectos').setValue(grupo.proyecto);
        }
      },
      (error: any) => this.toastService.error('No es posible comunicarse con el servidor')
    );
  }

  saveGrupo() {
    const grupo: Grupo = new Grupo (
      this.formGrupo.get('uuid').value || undefined,
      this.formGrupo.get('nombre').value,
      this.formGrupo.get('proyectos').value,
    );
    if (this.id)
      this.updateGrupo(grupo);
    else
      this.createGrupo(grupo);
  }

  createGrupo(grupo: Grupo) {
    this.gruposService.postGrupo(grupo).subscribe(
      (doc: any) => {
        if (doc.error)
          this.toastService.error(`Grupo existe actualmente`);
        else
          this.toastService.success(`Grupo creado correctamente`);
        this.router.navigate(['/grupos']);
      },
      (error: any) => {
        console.log(`Grupo - Create: ${error}`);
        this.toastService.error("No se pudo crear el grupo");
      }
    );
  }

  updateGrupo(grupo: Grupo) {
    this.gruposService.putGrupo(this.id, grupo).subscribe(
      (prev: any) => {
        this.toastService.success(`Grupo actualizado correctamente`);
        this.router.navigate(['/grupos']);
      },
      (error: any) => {
        console.log(`Grupo - Update: ${error}`);
        this.toastService.error("No se pudo crear el grupo");
      }
    );
  }

  showValidacion() {
    this.formGrupo.controls.nombre.markAsTouched();
  }

  get uuid() { return this.formGrupo.get('uuid'); }
  get nombre() { return this.formGrupo.get('nombre'); }
  get proyecto() { return this.formGrupo.get('proyectos'); }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }
}
