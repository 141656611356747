import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ProductoResumen } from 'src/app/models/producto-resumen.model';
import { ProductosService } from '../productos.service';

const MAX_PAGES:number = 9;
const HALF_PAGE:number = 4;

@Component({
  selector: 'app-productos-top-list',
  templateUrl: './productos-top-list.component.html',
  styleUrls: ['./productos-top-list.component.scss']
})
export class ProductosTopListComponent implements OnInit {

  @Input() uri;
  @Input() usuario: string;
  @Input() contenedor: string;
  @Input() inicio: string;
  @Input() fin: string;

  productos: ProductoResumen[];
  total: number  = 0;
  limit: number = 10;
  skip: number = 0;

  constructor(
    public productoService: ProductosService,
  ) { }

  ngOnInit(): void {
    this.productos = [];
    this.loadProductos();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadProductos();
  }

  loadProductos() {
    const options: any = {};
    if (this.usuario)
      options.usuario = this.usuario;
    if (this.contenedor)
      options.contenedor = this.contenedor;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    options.limit = this.limit;
    options.skip = this.skip;
    this.productoService.getProductoResumenCount(options).subscribe(
      resultado => this.total = resultado.hasOwnProperty('total') ? resultado.total : 0
    );
    this.productoService.getProductoResumen(options).subscribe(
      productos => this.productos = productos
    );
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.loadProductos();
  }
}
