import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Anillo } from 'src/app/models/anillo.model';
import { AnillosService } from '../anillos.service';

import 'dayjs/locale/es'
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { SensorlogsService } from 'src/app/sensorlogs/sensorlogs.service';

@Component({
  selector: 'app-dashboard-anillo',
  templateUrl: './dashboard-anillo.component.html',
  styleUrls: ['./dashboard-anillo.component.scss']
})
export class DashboardAnilloComponent implements OnInit {

  serial: string;
  anillo: Anillo;
  sensores: Array<any>;
  sensorNames: Array<string>;
  interval: any;
  index: number = 0;
  lastIndex: number = 0;
  total: number = 0;
  speed: number = 0;

  graph = {
    data: [],
    layout: {
      width: 500, height: 500, title: 'Llenado', showlegend: false,
      margin: {
        l: 25,
        r: 25,
        b: 25,
        t: 25,
      },
      scene: {
        xaxis: {
          showticklabels: false,
          title: {text: ''}
        },
        yaxis: {
          showticklabels: false,
          title: {text: ''}
        },
        zaxis: {
          nticks: 20,
          range: [0, 2000],
          title: {text: 'Altura(mm)'}
        }
      },
    },
    config: { displaylogo: false },
    frames: [],
  };
/*
  @HostListener('mouseenter')
  mouseenter() {
    console.log("OMG It's a Mouse!!!");
  }

  @HostListener('mouseover')
  mouseover() {
    console.log("OMG It's still here!!!");
  }

  @HostListener('mouseout')
  mouseout() {
    console.log('Phew thank god it left!')
  }
*/
  constructor(
    private activatedRoute: ActivatedRoute,
    private anillosService: AnillosService,
    private sensorLogsService: SensorlogsService,
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
    this.serial = this.activatedRoute.snapshot.params.serial;
    this.anillosService.getAnilloBySerial(this.serial).subscribe(anillo => {this.anillo = anillo; console.log(anillo);});
    const now = new Date();
    const inicio = (new Date(now.getTime() - 24 * 60 * 60 * 1000)).toISOString();
    const fin = now.toISOString();
    this.sensorLogsService.getSensorLogsBySerialAndDates(this.serial, { inicio: inicio, fin: fin }).subscribe(sensores => this.sensores = sensores);
    this.sensorLogsService.getLastMatrixLogBySerial(this.serial).subscribe(logs => {
      const frames = [];
      const titles = [];
      logs.forEach((l: any) => {
        titles.push(dayjs(l.fecha).format('ddd DD, HH:mm:ss'));
        frames.push({
          z: l.medidas,
          type: 'surface'
        });
      });
      this.total = frames.length;
      this.index = frames.length - 1;
      if (frames.length > 0) {
        this.lastIndex = this.index;
        this.interval = setInterval(() => {
          if (this.lastIndex != this.index) {
            this.graph.data = [frames[this.index]];
            this.graph.layout.title = titles[this.index];
            this.lastIndex = this.index;
          }
          if (this.speed) {
            this.index = (this.index + this.speed) % this.total;
          }
        }, 500);
      }
      if (frames.length) {
        this.graph.data = [frames[this.index]];
        this.graph.layout.title = titles[this.index];
      }
    });
    this.sensorNames = ['Usuario', 'Llenado', 'Caida', 'Batería 1', 'Batería 2', 'Lector', 'RFID', 'Producto', 'Modem'];
  }

  play() {
    if (this.total) {
      this.speed = 1;
      this.index = (this.index + this.speed) % this.total;
    }
  }

  stop() {
    this.speed = 0;
    this.index = this.lastIndex;
  }

  prevStep() {
    if (this.total && this.speed === 0) {
      this.index = this.index === 0 ? this.total - 1 : (this.index - 1);
    }
  }

  nextStep() {
    if (this.total && this.speed === 0) {
      this.index = (this.index + 1) === this.total ? 0 : (this.index + 1);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getIconColor(ok: number, total: number) {
    let icon = '';
    const percent = total ? 100 * ok / total : 0;
    if (percent <= 25)
      icon = 'mr-1 red-text';
    else if (percent > 25 && percent <= 50)
      icon = 'mr-1 amber-text';
    else if (percent > 50 && percent <= 90)
      icon = 'mr-1 orange-text';
    else
      icon = 'mr-1 green-text';
    return icon;
  }

  getUltimaConexion(conectado: Date, desconectado: Date) {
    const now = dayjs(this.isDisconectOK(conectado, desconectado) ? conectado : desconectado);
    return now.fromNow();
  }

  isDisconectOK(conectado: Date, desconectado: Date) {
    return (!desconectado || (conectado && conectado > desconectado));
  }

  getDatabaseVersion(version: number) {
    if (version === undefined)
      return '';
    const versionDate = new Date(version * 1000);
    const now = dayjs(versionDate);
    return now.fromNow();
  }

  getSensorName(codigo: number) {
    return this.sensorNames[codigo];
  }
}
