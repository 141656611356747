import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReciclosService {

  private recicloUri = `${environment.endPointApi}/reciclo`;

  constructor(
    private http: HttpClient,
  ) { }

  getReciclosBonificadosByDatamatrix(datamatrix, options): Observable<any[]> {
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${this.recicloUri}/bonificados/${datamatrix}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getReciclosBonificadosByRange', []))
    );
  }

  getReciclosEstatusByDatamatrix(datamatrix, options): Observable<any[]> {
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${this.recicloUri}/estatus/${datamatrix}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getReciclosEstatusByDatamatrix', []))
    );
  }

  getReciclosIncentivablesByDatamatrix(datamatrix, options): Observable<any[]> {
    const query = this.buildQuery(options);
    return this.http.get<any[]>(`${this.recicloUri}/incentivables/${datamatrix}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any[]>('getReciclosIncentivablesByDatamatrix', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
