<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="onNoClick()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <h5>{{ content.heading }}</h5>
    <p>{{ content.description }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="onNoClick()" mdbWavesEffect>Cancelar</button>
    <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="onYesClick()">Aceptar</button>
  </div>
