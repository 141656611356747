<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button routerLink="/electronica-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Electrónicas</h3>
          <div></div>
        </div>
        <div class="px-4">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>IMEI</th>
                  <th>Anillo</th>
                  <th *ngIf="isAdmin">Firmware</th>
                  <th *ngIf="isAdmin">Base de Datos</th>
                  <th *ngIf="isSuperAdmin">Proyecto</th>
                  <th>Conexión Remota</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let e of electronicas">
                  <td scope="row">
                    {{ e.nombre }}
                  </td>
                  <td>{{ e.serial }}</td>
                  <td>{{ e.anillo }}</td>
                  <td *ngIf="isAdmin">{{ e.firmware || '' }}<small *ngIf="upgradeVersion(e)" class="red-text">({{ e?.firmwareConfig?.version }})</small></td>
                  <td *ngIf="isAdmin">{{ e.database !== 0 ? e.database : '' }}</td>
                  <td *ngIf="isSuperAdmin">{{ e.proyecto }}</td>
                  <td>{{ getConexionRemota(e.fecha) }}</td>
                  <td class="text-right">
                    <a (click)="gotoURL(e.serial)" class="blue-grey-text" mdbTooltip="Terminal" placement="top">
                      <mdb-icon fas icon="terminal" class="px-1"></mdb-icon>
                    </a>
                    <a routerLink="/electronica-editar/{{e._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                      <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                    </a>
                    <a *ngIf="isAdmin" class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(e._id, e.serial)">
                      <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-pagination dashboard="electronica" [total]="total" [limit]="limit" (changePage)="onChangePage($event)"></app-pagination>
      </mdb-card>
    </div>
  </div>
</section>
