import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { Contenedor } from 'src/app/models/contenedor.model';
import { ContenedoresService } from '../contenedores.service';
import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard-contenedores',
  templateUrl: './dashboard-contenedores.component.html',
  styleUrls: ['./dashboard-contenedores.component.scss']
})
export class DashboardContenedoresComponent implements OnInit {

  contenedores: Array<Contenedor> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;

  constructor(
    private contenedoresService: ContenedoresService,
    private modalService: MDBModalService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  getContenedores(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.contenedoresService.getContenedorCount().subscribe(
      resumen => this.total = resumen?.total || 0
    );
    this.contenedoresService.getContenedores(options).subscribe(
      contenedores => this.contenedores = contenedores
    );
  }

  getIconBattery(baterry){
    let icon='';
    if(baterry <= 5)
      icon = 'battery-empty';
    else if(baterry > 5 && baterry <= 25)
      icon = 'battery-quarter';
    else if(baterry > 25 && baterry <= 50)
      icon = 'battery-half';
    else if(baterry > 50 && baterry <= 75)
      icon = 'battery-three-quarters';
    else
      icon = 'battery-full';
    return icon;
  }

  getIconColor(baterry){
    let icon='';
    if(baterry <= 25)
      icon = 'mr-1 red-text';
    else if(baterry > 25 && baterry <= 50)
      icon = 'mr-1 amber-text';
    else if(baterry > 50 && baterry <= 75)
      icon = 'mr-1 orange-text';
    else
      icon = 'mr-1 green-text';
    return icon;
  }

  getUltimaConexion(conectado, desconectado) {
    const now = dayjs(this.isDisconectOK(conectado, desconectado) ? conectado : desconectado);
    return now.fromNow();
  }

  isDisconectOK(conectado, desconectado) {
    return (!desconectado || (conectado && conectado > desconectado));
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getContenedores();
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }

  get isNoClient() {
    return !this.authService.isClient();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Contenedores',
        content: { heading: nombre, description: '¿Desea eliminar este contenedor?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.contenedoresService.deleteContenedor(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El contenedor fue eliminado correctamente');
              this.getContenedores();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }
}
