<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <app-search
        (changeRangeDated)="onRangeDated($event)"
        [opcion1]="estados" (changeOpcion1)="onEstadoChange($event)"
        [opcion2]="fallas" (changeOpcion2)="onSintomaChange($event)"
        [opcion3]="contenedores" (changeOpcion3)="onContenedorChange($event)"
        [opcion4]="anillos" (changeOpcion4)="onAnilloChange($event)"
        [opcion5]="electronicas" (changeOpcion5)="onElectronicaChange($event)"
        (exportCSV)="onExportCSV()" 
        ></app-search>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button *ngIf="isNoClient" routerLink="/incidencia-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Incidencias</h3>
          <div></div>
        </div>
        <div class="px-4">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Titulo</th>
                  <th>Estado</th>
                  <th *ngIf="isSuperAdmin" >Proyecto</th>
                  <th>Responsable</th>
                  <th>Contenedor</th>
                  <th>Anillo</th>
                  <th>Electrónica</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let i of incidencias, index as idx ">
                  <tr mdbTableCol>
                    <td scope="row">
                      <a class="teal-text" mdbTooltip="Mostrar" placement="top" (click)="setEntradaClass(idx)">
                        <mdb-icon fas [icon]="idx !== entrada ? 'caret-down' : 'caret-up' " class="px-1"></mdb-icon>
                      </a>
                      {{ getFechaEntrada(i.fecha) }}
                    </td>
                    <td>{{ i.titulo }}</td>
                    <td>{{ i.estado }}</td>
                    <td *ngIf="isSuperAdmin" >{{ i.proyecto }}</td>
                    <th>{{ i.nombre }} {{ i.apellidos }}</th>
                    <td>{{ i.contenedor }}</td>
                    <td>{{ i.anillo }}</td>
                    <td>{{ i.electronica }}</td>
                    <td class="text-right">
                      <a routerLink="/incidencia-editar/{{i._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                        <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                      </a>
                      <a *ngIf="isAdmin" class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(i._id, i.titulo)">
                        <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                      </a>
                    </td>
                  </tr>
                  <tr [ngClass]="entrada !== idx ? 'd-none': ''">
                    <td colspan="9">
                      <table mdbTable small="true">
                        <thead class="teal-text">
                          <tr>
                            <th>Fecha</th>
                            <th>Autor</th>
                            <th>Description</th>
                            <th *ngIf="false">Averias</th>
                            <th>Actuaciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let e of i.entradas">
                            <td>{{getFechaEntrada(e.fecha)}}</td>
                            <td>{{e.usuario}}</td>
                            <td>{{e.descripcion}}</td>
                            <td *ngIf="false">{{e.fallas.join(', ')}}</td>
                            <td>{{e.correcciones.join(', ')}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <app-pagination dashboard="incidencia" [total]="total" [limit]="limit" (changePage)="onChangePage($event)"></app-pagination>
      </mdb-card>
    </div>
  </div>
</section>