import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { saveAs } from 'file-saver';
import { ContenedoresService } from 'src/app/contenedores/contenedores.service';
import { Contenedor } from 'src/app/models/contenedor.model';
import { Producto } from 'src/app/models/producto.model';
import { Usuario } from 'src/app/models/usuario.model';
import { ProductosService } from 'src/app/productos/productos.service';
import { RecicladosService } from 'src/app/reciclados/reciclados.service';
import { UsuariosService } from '../usuarios.service';

@Component({
  selector: 'app-dashboard-usuario',
  templateUrl: './dashboard-usuario.component.html',
  styleUrls: ['./dashboard-usuario.component.scss']
})
export class DashboardUsuarioComponent implements OnInit {

  usuario: string;
  producto: string;
  contenedor: string;
  uri: string;
  startDate: string;
  endDate: string;
  user: Usuario;
  product: Producto;
  container: Contenedor;

  constructor(
    private activatedRoute: ActivatedRoute,
    private recicladoService: RecicladosService,
    private usuarioService: UsuariosService,
    private productoService: ProductosService,
    private contenedorService: ContenedoresService,
  ) { }

  ngOnInit(): void {
    this.usuario = this.activatedRoute.snapshot.params.usuario;
    this.producto = this.activatedRoute.snapshot.params.producto;
    this.contenedor = this.activatedRoute.snapshot.params.contenedor;
    this.uri = this.activatedRoute.snapshot.data.uri;
    const inicio = this.activatedRoute.snapshot.queryParams.inicio;
    const fin = this.activatedRoute.snapshot.queryParams.fin;
    if (inicio)
      this.startDate = inicio;
    if (fin)
      this.endDate = fin;
    this.usuarioService.getUsuarioByUUID(this.usuario).subscribe(user => this.user = user);
    if (this.producto)
      this.productoService.getProductoBySKU(this.producto).subscribe(producto => this.product = producto);
    if (this.contenedor)
      this.contenedorService.getContenedorBySerial(this.contenedor).subscribe(container => this.container = container);
  }

  onRangeDated(doc: any) {
    if (doc.start)
      this.startDate = doc.start;
    if (doc.end)
      this.endDate = doc.end;
  }

  onExportCSV() {
    const options = {};
    ['contenedor', 'producto', 'usuario'].forEach(k => {
      if (this[k])
        options[k] = this[k];
    });
    if (this.startDate)
      options['inicio'] = this.startDate;
    if (this.endDate)
      options['fin'] = this.endDate;
    this.recicladoService.getRecicladoCSV(options).subscribe(
      resultado => saveAs(new Blob([resultado], {type: "text/plain;charset=utf-8"}), "resultados.csv")
    );
  }
}
