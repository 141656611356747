<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="onNoClick()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h4>{{ content.description }}</h4>
      </div>
    </div>
  </div>
  <form class="border border-light px-2" [formGroup]="formIncidencias">
  <div class="row">
    <div class="col-1">
      <div class="md-form">
        <label>Fecha</label>
      </div>
    </div>
    <div class="col-4">
      <div class="md-form">
        <input type="datetime-local" name="fecha" id="fecha" formControlName="fecha">
      </div>
    </div>
  </div>
  <div class="row py-2">
    <div class="col">
      <div class="md-form">
        <textarea mdbInput mdbValidate type="text" id="descripcion" class="form-control md-textarea" rows="10" formControlName="descripcion"></textarea>
        <label for="descripcion">Descripción</label>
        <mdb-error *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo requerido</mdb-error>
        <mdb-success *ngIf="descripcion.valid && (descripcion.dirty || descripcion.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
      </div>
    </div>
    <div *ngIf="false" class="col">
      <h5>Síntomas</h5>
      <div class="md-form">
        <mdb-checkbox *ngFor="let falla of fallas" [value]="falla.id" (change)="onFallaChange($event)" [checked]="falla.selected">
          {{falla.nombre}}
        </mdb-checkbox>
      </div>
    </div>
    <div class="col">
      <h5>Actuaciones</h5>
      <div class="md-form">
        <mdb-checkbox *ngFor="let correccion of correcciones1" [value]="correccion.id" (change)="onCorreccionChange($event)" [checked]="correccion.selected">
          {{correccion.nombre}}
        </mdb-checkbox>
      </div>
    </div>
    <div *ngIf="correcciones2" class="col">
      <div class="md-form">
        <mdb-checkbox *ngFor="let correccion of correcciones2" [value]="correccion.id" (change)="onCorreccionChange($event)" [checked]="correccion.selected">
          {{correccion.nombre}}
        </mdb-checkbox>
      </div>
    </div>
    <div *ngIf="correcciones3.length > 0" class="col">
      <div class="md-form">
        <mdb-checkbox *ngFor="let correccion of correcciones3" [value]="correccion.id" (change)="onCorreccionChange($event)" [checked]="correccion.selected">
          {{correccion.nombre}}
        </mdb-checkbox>
      </div>
    </div>
  </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" mdbBtn color="danger" class="waves-light" size="sm" (click)="onNoClick()" mdbWavesEffect>Cancelar</button>
  <button *ngIf="formIncidencias.invalid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="showValidacion()">
    <mdb-icon fas icon="save"></mdb-icon> Guardar
  </button>
  <button *ngIf="formIncidencias.valid" mdbBtn type="button" color="success" mdbWavesEffect size="sm" (click)="onSaveClick()">
    <mdb-icon fas icon="save"></mdb-icon> Guardar
  </button>
  <!--
  <button *ngIf="content.action === 'create'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onCreateClick()">Crear</button>
  <button *ngIf="content.action === 'edit'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onSaveClick()">Guardar</button>
  <button *ngIf="content.action === 'delete'" type="button" mdbBtn color="success" class="relative waves-light" mdbWavesEffect (click)="onYesClick()">Borrar</button>
  -->
</div>