export class Anillo {
    constructor(        
        public serial: string,
        public nombre: string,
        public contenedor: string,
        public modelo: string,
        public bateria1: number,
        public bateria2: number,
        public conectado: Date,
        public desconectado: Date,
        public version: number,
        public config: {
            fecha: number,
            dias: number,
            hora: number,
            minutos: number,
            segundos: number,
            repeticiones: number,
            frecuencia: number,
        },
        public firmware: {
            version: string,
            id: string,
            actualizado: Date,
            configurado: Date,
        },
        public proyecto: string,
        public grupos: string[],
        public activo: boolean,
    ) {}
}