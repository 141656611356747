<section class="my-3">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <mdb-card cascade="true" narrower="true">
          <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
            <div>
              <button routerLink="/firmware-crear" mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
                <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
              </button>
            </div>
            <h3 class="white-text mx-3">Telecargas</h3>
            <div></div>
          </div>
          <div class="px-4">
            <div class="table-responsive">
              <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>ID</th>
                    <th>IP</th>
                    <th>Port</th>
                    <th class="th-sm">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol *ngFor="let f of firmwares">
                    <td scope="row">{{ f.nombre }}</td>
                    <td>{{ f.version == 0 ? '' : f.version }}</td>
                    <td>{{ f.ip }}</td>
                    <td>{{ f.puerto }}</td>
                    <td>
                      <a routerLink="/firmwares-firmware/{{f._id}}" class="blue-text" mdbTooltip="Telecarga" placement="top">
                        <mdb-icon fas icon="upload" class="px-1"></mdb-icon>
                      </a>
                      <a routerLink="/firmware-editar/{{f._id}}"class="teal-text" mdbTooltip="Editar" placement="top">
                        <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                      </a>
                      <a class="red-text" mdbTooltip="Eliminar" placement="top" (click)="openModal(f._id, f.nombre)">
                        <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <app-pagination dashboard="contenedor" [limit]="limit" [total]="total" (changePage)="onChangePage($event)"></app-pagination>
        </mdb-card>
      </div>
    </div>
  </section>
