import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ToastService } from 'ng-uikit-pro-standard';
import { ModalComponent } from 'src/app/comunes/modal/modal.component';
import { AnillosService } from '../anillos.service';
import { Anillo } from 'src/app/models/anillo.model';
import { AuthService } from 'src/app/auth/auth.service';
import 'dayjs/locale/es' 
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-anillos',
  templateUrl: './dashboard-anillos.component.html',
  styleUrls: ['./dashboard-anillos.component.scss']
})
export class DashboardAnillosComponent implements OnInit {

  anillos: Array<Anillo> = [];
  total: number = 0;
  modalRef: MDBModalRef;
  limit: number = 10;
  skip: number = 0;

  constructor(
    private anillosService: AnillosService,
    private modalService: MDBModalService,
    private authService: AuthService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    dayjs.locale('es');
    dayjs.extend(relativeTime);
  }

  getAnillos(): void {
    const options = {
      skip: this.skip,
      limit: this.limit
    };
    this.anillosService.getAnillosCount().subscribe(
      resumen => this.total = resumen?.total || 0
    );
    this.anillosService.getAnillos(options).subscribe(
      anillos => this.anillos = anillos
    );
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  get isAdmin() {
    return this.authService.isSuperAdmin() || this.authService.isAdmin();
  }

  gotoURL(serial: string) {
    const token = localStorage.getItem('token');
    window.open(`${environment.socketIO}/${serial}?token=${token}`, '_blank');
  }

  sortSensores(sensores: Array<any>) {
    if (!sensores)
      return [];
    return sensores.sort((a, b) => a.sensor - b.sensor);
  }

  getIconSensor(sensor: number){
    return [
      'user', 'prescription-bottle', 'fill-drip', 'battery-full', 'battery-full',
      'barcode', 'wave-square', 'box', 'network-wired',
    ][sensor];
  }

  getSensorColor(ok: number, total: number) {
    let icon='';
    const avg = total ? ok / total : 0;
    if(avg <= 0.2)
      icon = 'mr-1 red-text';
    else if(avg > 0.2 && avg <= 0.5)
      icon = 'mr-1 amber-text';
    else if(avg > 0.5 && avg <= 0.9)
      icon = 'mr-1 orange-text';
    else
      icon = 'mr-1 green-text';
    return icon;
  }

  getUltimaConexion(conectado, desconectado) {
    const now = dayjs(this.isDisconectOK(conectado, desconectado) ? conectado : desconectado);
    return now.fromNow();
  }

  isDisconectOK(conectado, desconectado) {
    return (!desconectado || (conectado && conectado > desconectado));
  }

  getIconColor(baterry){
    let icon='';
    if(baterry <= 25)
      icon = 'mr-1 red-text';
    else if(baterry > 25 && baterry <= 50)
      icon = 'mr-1 amber-text';
    else if(baterry > 50 && baterry <= 75)
      icon = 'mr-1 orange-text';
    else
      icon = 'mr-1 green-text';
    return icon;
  }

  getIconBattery(baterry){
    let icon='';
    if(baterry <= 5)
      icon = 'battery-empty';
    else if(baterry > 5 && baterry <= 25)
      icon = 'battery-quarter';
    else if(baterry > 25 && baterry <= 50)
      icon = 'battery-half';
    else if(baterry > 50 && baterry <= 75)
      icon = 'battery-three-quarters';
    else
      icon = 'battery-full';
    return icon;
  }

  onChangePage(event) {
    this.limit = event.limit;
    this.skip = event.skip;
    this.getAnillos();
  }

  openModal(id: string, nombre: string) {
    const options = {
      data: {
        heading: 'Anillos',
        content: { heading: nombre, description: '¿Desea eliminar este anillo?'}
      }
    }
    this.modalRef = this.modalService.show(ModalComponent, options);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result)
        this.anillosService.deleteAnillo(id).subscribe(
          doc => {
            if (doc) {
              this.toastService.success('El anillo fue eliminado correctamente');
              this.getAnillos();
            } else {
              this.toastService.error(`No pudo realizarse la operación solicitada`);
            }
          }
        );
      this.modalRef.hide();
    });
  }
}
function Inject(DOCUMENT: any) {
  throw new Error('Function not implemented.');
}

