import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Incidencia } from '../models/incidencia.model';

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService {

  private incidenciasUri = `${environment.endPointApi}/incidencia`;

  constructor(
    private http: HttpClient,
  ) { }

  getIncidencias(options): Observable<any> {
    const query = this.buildQuery(options);
    return this.http.get<any>(`${this.incidenciasUri}${query.length > 0 ? '?' + query : ''}`).pipe(
      catchError(this.handleError<any>('getIncidencias', {}))
    );
  }

  getBasicData() {
    const uri = `${this.incidenciasUri}/rest/basic`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getBasicData',  {}))
    );
  }

  getIncidenciasCount(): Observable<any> {
    const uri = `${this.incidenciasUri}/count`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getIncidenciasCount', {total: 0}))
    );
  }

  postIncidencia(incidencia: Incidencia): Observable<any> {
    const uri = `${this.incidenciasUri}/crud`;
    return this.http.post<Incidencia>(uri, incidencia).pipe(
      catchError(this.handleError<Incidencia>('postIncidencia', null))
    );
  }

  getIncidencia(id: string): Observable<Incidencia> {
    const uri = `${this.incidenciasUri}/crud/${id}`;
    return this.http.get<Incidencia>(uri).pipe(
      catchError(this.handleError<Incidencia>('getIncidencia', null))
    );
  }

  getFiltros(): Observable<any> {
    const uri = `${this.incidenciasUri}/rest/filters`;
    return this.http.get<any>(uri).pipe(
      catchError(this.handleError<any>('getFiltros', {}))
    );
  }

  getIncidenciasCSV(options): Observable<any> {
    const uri = `${this.incidenciasUri}/csv`;
    const query = this.buildQuery(options);
    const requestOptions: Object = {
      headers: new HttpHeaders({Accept: 'plain/text'}),
      responseType: 'text',
    }
    return this.http.get<any>(`${uri}${query.length > 0 ? '?' + query : ''}`, requestOptions).pipe(
      catchError(this.handleError<any>('getIncidenciasCSV', null))
    );
  }

  putIncidencia(id: string, incidencia: Incidencia): Observable<any> {
    const uri = `${this.incidenciasUri}/crud/${id}`;
    return this.http.put<Incidencia>(uri, incidencia).pipe(
      catchError(this.handleError<Incidencia>('putIncidencia', null))
    );
  }

  deleteIncidencia(id: string): Observable<any> {
    const uri = `${this.incidenciasUri}/crud/${id}`;
    return this.http.delete<Incidencia>(uri).pipe(
      catchError(this.handleError<Incidencia>('deleteIncidencia', null))
    );
  }

  deleteEntrada(id: string, entrada_id: string): Observable<any> {
    const uri = `${this.incidenciasUri}/rest/incidencia/${id}/entrada/${entrada_id}`;
    return this.http.delete<Incidencia>(uri).pipe(
      catchError(this.handleError<Incidencia>('deleteIncidencia', null))
    );
  }

  editEntrada(id: string, entrada_id: string, entrada: any): Observable<any> {
    const uri = `${this.incidenciasUri}/rest/incidencia/${id}/entrada/${entrada_id}`;
    return this.http.put<Incidencia>(uri, entrada).pipe(
      catchError(this.handleError<Incidencia>('editIncidencia', null))
    );
  }

  putIncidenciaEntrada(id: string, entrada: any): Observable<any> {
    const uri = `${this.incidenciasUri}/rest/entradas/${id}`;
    return this.http.put<any>(uri, entrada).pipe(
      catchError(this.handleError<Incidencia>('putIncidenciaEntrada', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    }
  }

  private buildQuery(options: any): string {
    const query = [];
    for(const [key, value] of Object.entries(options))
      query.push(`${key}=${value}`);
    return query.join('&');
  }
}
