import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrls: ['./configuration-modal.component.scss']
})
export class ConfigurationModalComponent implements OnInit {

  action: Subject<any> = new Subject();
  heading: string;
  content: any;
  formConfiguration: FormGroup;

  constructor(
    public modalRef: MDBModalRef
  ) { }

  ngOnInit(): void {
    this.formConfiguration = new FormGroup({
      nombreSintoma: new FormControl(this.content.nombre || ''),
    });
  }

  onCreateClick() {
    const nombre = this.formConfiguration.get('nombreSintoma').value;
    if (nombre)
      this.action.next(nombre);
    else
      this.action.next(false);
  }

  onYesClick() {
      this.action.next(true);
  }

  onSaveClick() {
    const nombre = this.formConfiguration.get('nombreSintoma').value;
    if (nombre)
      this.action.next(nombre);
    else
      this.action.next(false);
  }

  onNoClick() {
      this.action.next(false);
  }
}
