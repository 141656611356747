<header>
    <section class="view intro intro-2">
      <div class="full-bg-img rgba-stylish-strong mask flex-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">
              <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
                  <mdb-card-body>
                    <!--Header-->
                    <div class="form-header primary-color">
                      <h3><mdb-icon fas icon="user" class="mt-2 mb-2"></mdb-icon> ecoReader</h3>
                    </div>
                    <!--Body-->
                    <form [formGroup]="formRegister" (ngSubmit)="register()">
                      <div class="md-form">
                        <input mdbInput mdbValidate type="text" formControlName="nombre" id="nombre" class="form-control">
                        <label for="nombre" class="">Nombre</label>
                        <mdb-error *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo requerido</mdb-error>
                        <mdb-success *ngIf="nombre.valid && (nombre.dirty || nombre.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                      </div>
                      <div class="md-form">
                        <input mdbInput mdbValidate type="text" formControlName="apellidos" id="apellidos" class="form-control">
                        <label for="apellidos" class="">Apellidos</label>
                        <mdb-error *ngIf="apellidos.invalid && (apellidos.dirty || apellidos.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo requerido</mdb-error>
                        <mdb-success *ngIf="apellidos.valid && (apellidos.dirty || apellidos.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                      </div>
                      <div class="md-form">
                        <input mdbInput mdbValidate type="email" formControlName="correo" class="form-control">
                        <label for="correo" class="">Correo</label>
                        <mdb-error *ngIf="correo.invalid && (correo.dirty || correo.touched)">
                          <mdb-icon fas icon="times"></mdb-icon>
                          <span *ngIf="correo.errors.required"> Campo requerido</span>
                          <span *ngIf="correo.errors.email"> Dirección de correo no valida</span>
                        </mdb-error>
                        <mdb-success *ngIf="correo.valid && (correo.dirty || correo.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                      </div>
                      <div class="md-form">
                        <input mdbInput mdbValidate type="password" id="clave" formControlName="clave" class="form-control">
                        <label for="clave" class="">Contraseña</label>
                        <mdb-error *ngIf="clave.invalid && (clave.dirty || clave.touched)"><mdb-icon fas icon="times"></mdb-icon> Campo requerido</mdb-error>
                        <mdb-success *ngIf="clave.valid && (clave.dirty || clave.touched)"><mdb-icon fas icon="check"></mdb-icon></mdb-success>
                      </div>
                      <div class="text-center">
                        <button *ngIf="formRegister.invalid" class="waves-effect waves-light primary-color text-white" mdbBtn
                          (click)="showValidacion()" size="lg" mdbWavesEffect type="button">
                            Registrar
                        </button>
                        <button *ngIf="formRegister.valid" class="waves-effect waves-light primary-color text-white" mdbBtn
                          size="lg" mdbWavesEffect type="submit">
                          Registrar
                        </button>
                        <a mdbBtn class="primary-color text-white" routerLink="/login"
                          size="lg" mdbWavesEffect >
                          Cancelar
                        </a>
                      </div>
                    </form>
                  </mdb-card-body>
                </mdb-card>
            </div>
            </div>
          </div>
      </div>
    </section>
  </header>
  