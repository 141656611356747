import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ResumenHora } from 'src/app/models/resumen-hora.model';
import { UsuariosService } from '../usuarios.service';

@Component({
  selector: 'app-usuarios-hora-chart',
  templateUrl: './usuarios-hora-chart.component.html',
  styleUrls: ['./usuarios-hora-chart.component.scss']
})
export class UsuariosHoraChartComponent implements OnInit {

  @Input() name: string;
  @Input() producto: string;
  @Input() contenedor: string;
  @Input() usuario: string;
  @Input() inicio: string;
  @Input() fin: string;

  chartType: string = 'bar';
  chartDatasets: Array<any> = [{data: []}];
  chartLabels: Array<any> = [];
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(58, 90, 64, .75)',
      borderColor: 'rgba(58, 90, 64, .75)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(88, 129, 87, .75)',
      borderColor: 'rgba(88, 129, 87, .75)',
      borderWidth: 0,
    },
    {
      backgroundColor: 'rgba(163, 177, 138, .75)',
      borderColor: 'rgba(163, 177, 138, .75)',
      borderWidth: 0,
    }
  ];
  chartOptions: any = {
    elements: { rectangle: { borderWidth: 2} },
    responsive: true,
    legend: { position: 'bottom'},
    title: { display: true, text: 'Resumen por Horas' },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          precision: 0
        },
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }]
    }
  }

  constructor(
    private usuariosService: UsuariosService,
  ) { }

  ngOnInit(): void {
    this.name = this.name ? `${this.name}.productosByHoraChart` : 'productosByHoraChart'
    this.loadUsers();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inicio']?.currentValue || changes['fin']?.currentValue)
      this.loadUsers();
  }

  loadUsers() {
    const options: any = {};
    if (this.producto)
      options.producto = this.producto;
    if (this.contenedor)
      options.contenedor = this.contenedor;
    if (this.usuario)
      options.usuario = this.usuario;
    if (this.inicio)
      options.inicio = this.inicio;
    if (this.fin)
      options.fin = this.fin;
    this.usuariosService.getUsuarioResumenHora(options).subscribe(
      usuarios => this.createChartByHora(usuarios)
    );
  }

  createChartByHora(usuarios: ResumenHora[]) {
    this.chartLabels = usuarios.map(row => row.hora);
    this.chartDatasets = [
      {
        label: 'Bonificado',
        data: usuarios.map(row => row.bonus),
      },
      {
        label: 'No bonificado',
        data: usuarios.map(row => row.bonusnok),
      },
      {
        label: 'No Incentivable',
        data: usuarios.map(row => row.nobonus),
      },
  ];
  }
}
