import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {IMyOptions, MDBDatePickerComponent, LocaleService} from 'ng-uikit-pro-standard';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('datePickerInicio', { static: true }) datePickerInicio: MDBDatePickerComponent;
  @ViewChild('datePickerFin', { static: true }) datePickerFin: MDBDatePickerComponent;

  public myDatePickerOptions: IMyOptions = {};

  public locales = {
    'es': {
      dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
      dayLabelsFull: { su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miercoles', th: 'Jueves', fr: 'Viernes', sa: 'Sabado' },
      monthLabels: {
        1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'
      },
      monthLabelsFull: {
        1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio',
        8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'
      },
      dateFormat: 'dd mmmm yyyy',
      todayBtnTxt: 'Hoy',
      clearBtnTxt: 'Limpiar',
      closeBtnTxt: 'Cerrar',
      firstDayOfWeek: 'su',
      sunHighlight: true,
    }
  };

  dateOptionsSelect = [
    { value: '1', label: 'Hoy' },
    { value: '2', label: 'Ayer' },
    { value: '3', label: 'Últimas 24 Horas' },
    { value: '4', label: 'Últimos 7 Días' },
    { value: '5', label: 'Últimos 30 Días' },
  ];
  startDate: string;
  endDate: string;
  inicio: Date;
  fin: Date;
  @Input() opcion1: any;
  @Input() opcion2: any;
  @Input() opcion3: any;
  @Input() opcion4: any;
  @Input() opcion5: any;

  @Output() changeRangeDated = new EventEmitter<any>();
  @Output() changeOpcion1 = new EventEmitter<any>();  
  @Output() changeOpcion2 = new EventEmitter<any>();
  @Output() changeOpcion3 = new EventEmitter<any>();
  @Output() changeOpcion4 = new EventEmitter<any>();
  @Output() changeOpcion5 = new EventEmitter<any>();
  @Output() exportCSV = new EventEmitter<any>();

  constructor(private localeService: LocaleService) {
    this.localeService.setLocaleOptions(this.locales);
  }

  ngOnInit(): void {
    const now = dayjs();
    this.startDate = now.subtract(7, 'day').startOf('day').toISOString();
    this.endDate = now.endOf('day').toISOString();
    this.inicio = now.subtract(7, 'day').startOf('day').toDate();
    this.fin = now.endOf('day').toDate();
  }

  onSelectChange(option) {
    const now = dayjs();
    this.endDate = now.toISOString();
    if (option === '1')
      this.startDate = now.startOf('day').toISOString();
    else if (option === '2') {
      this.startDate = now.subtract(1, 'day').startOf('day').toISOString();
      this.endDate = now.subtract(1, 'day').endOf('day').toISOString();
    } else if (option === '3')
      this.startDate = now.subtract(24, 'hour').toISOString();
    else if (option === '4')
      this.startDate = now.subtract(7, 'day').startOf('day').toISOString();
    else if (option === '5')
      this.startDate = now.subtract(30, 'day').startOf('day').toISOString();
    this.inicio = new Date(this.startDate);
    this.fin = new Date(this.endDate);
    this.updateChartData();
  }

  onOpcion1SelectChange(option) {
    this.changeOpcion1.emit(option);
  }

  onOpcion2SelectChange(option) {
    this.changeOpcion2.emit(option);
  }

  onOpcion3SelectChange(option) {
    this.changeOpcion3.emit(option);
  }

  onOpcion4SelectChange(option) {
    this.changeOpcion4.emit(option);
  }

  onOpcion5SelectChange(option) {
    this.changeOpcion5.emit(option);
  }

  get additionalOptions() {
    return this.opcion1 || this.opcion2 || this.opcion3 || this.opcion4 || this.opcion5;
  }

  updateChartData() {
    const data = {start: this.startDate, end: this.endDate};
    this.changeRangeDated.emit(data);
  }

  onInicioDatePicker(option) {
    if (option.jsdate === null)
      return;
    const now = dayjs(option.epoc * 1000).startOf('day').toDate();
    this.startDate = now.toISOString();
    if (this.startDate <= this.endDate)
      this.updateChartData();
  }

  onFinDatePicker(option) {
    const now = dayjs(option.epoc * 1000).endOf('day').toDate();
    this.endDate = now.toISOString();
    if (this.startDate <= this.endDate)
      this.updateChartData();
  }

  exportAsCSV() {
    if (this.startDate <= this.endDate)
      this.exportCSV.emit();
  }
}
