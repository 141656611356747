export class Usuario {
	constructor(
		public nombre: string,
		public apellidos: string,
		public correo: string,
		public password: string,
		public proyecto: string,
		public grupos: Array<string>,
		public admin: boolean,
		public client: boolean,
	) {}
}