<section class="my-3">
  <div class="row">
    <div class="col">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect (click)="createSintoma()">
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Sintomas</h3>
          <div></div>
        </div>
        <div class="px-4 mb-2">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let s of sintomas">
                  <td>{{ s.nombre }}</td>
                  <td class="text-right">
                    <a class="teal-text" mdbTooltip="Editar" placement="top" (click)="editSintoma(s.id, s.nombre)">
                      <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                    </a>
                    <a class="red-text" mdbTooltip="Eliminar" placement="top" (click)="deleteSintoma(s.id, s.nombre)">
                      <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card>
    </div>
    <div class="col-6">
      <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header eco-secondary narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
          <div>
            <button mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect (click)="createActuacion()">
              <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
            </button>
          </div>
          <h3 class="white-text mx-3">Actuaciones</h3>
          <div></div>
        </div>
        <div class="px-4 mb-2">
          <div class="table-responsive">
            <table class="mb-0" mdbTable mdbTableScroll scrollX="true" hover="true">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th class="th-sm text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let a of actuaciones">
                  <td>{{ a.nombre }}</td>
                  <td class="text-right">
                    <a class="teal-text" mdbTooltip="Editar" placement="top" (click)="editActuacion(a.id, a.nombre)">
                      <mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon>
                    </a>
                    <a class="red-text" mdbTooltip="Eliminar" placement="top" (click)="deleteActuacion(a.id, a.nombre)">
                      <mdb-icon fas icon="times" class="px-1"></mdb-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card>
    </div>
  </div>
</section>
