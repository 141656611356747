export class Electronica {
  constructor(
      public nombre: string,
      public serial: string,
      public anillo: string,
      public tarjeta: string,
      public firmware: string,
      public database: string,
      public proyecto: string,
      public grupos: string[],
      public fecha: Date,
  ) {}
}