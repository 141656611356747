import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ContenedorComponent } from './contenedores/contenedor/contenedor.component';
import { DashboardContenedorComponent } from './contenedores/dashboard-contenedor/dashboard-contenedor.component';
import { DashboardContenedoresComponent } from './contenedores/dashboard-contenedores/dashboard-contenedores.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { DashboardUsuariosComponent } from './usuarios/dashboard-usuarios/dashboard-usuarios.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { DashboardFirmwaresComponent } from './firmware/dashboard-firmwares/dashboard-firmwares.component';
import { FirmwareComponent } from './firmware/firmware/firmware.component';
import { DashboardFirmwareComponent } from './firmware/dashboard-firmware/dashboard-firmware.component';
import { DashboardAnillosComponent } from './anillos/dashboard-anillos/dashboard-anillos.component';
import { DashboardProyectosComponent } from './proyectos/dashboard-proyectos/dashboard-proyectos.component';
import { DashboardGruposComponent } from './grupos/dashboard-grupos/dashboard-grupos.component';
import { AnilloComponent } from './anillos/anillo/anillo.component';
import { ProyectoComponent } from './proyectos/proyecto/proyecto.component';
import { GrupoComponent } from './grupos/grupo/grupo.component';
import { DashboardIncidenciasComponent } from './incidencias/dashboard-incidencias/dashboard-incidencias.component';
import { IncidenciaComponent } from './incidencias/incidencia/incidencia.component';
import { DashboardAnilloComponent } from './anillos/dashboard-anillo/dashboard-anillo.component';
import { DashboardElectronicasComponent } from './electronicas/dashboard-electronicas/dashboard-electronicas.component';
import { ElectronicasComponent } from './electronicas/electronicas/electronicas.component';
import { ConfigurationDashboardComponent } from './configuration/configuration-dashboard/configuration-dashboard.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/inicio', pathMatch: 'full'},
  {
    path: 'inicio', component: InicioComponent, canActivate: [AuthGuard],
    data: {rutas: [{ruta: '/inicio', texto: 'Inicio'}]}
  },
  {
    path: 'contenedores', component: DashboardContenedoresComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/contenedores', texto: 'Contenedores'},
      ]
    }
  },
  {
    path: 'contenedor-crear', component: ContenedorComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/contenedores', texto: 'Contenedores'},
        {ruta: '/contenedor-crear', texto: 'Nuevo contenedor'},
      ]
    }
  },
  {
    path: 'contenedor-editar/:id', component: ContenedorComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/contenedores', texto: 'Contenedores'},
        {ruta: '/contenedor-editar', texto: 'Editar contenedor'},
      ]
    }
  },
  {
    path: 'contenedores-contenedor/:contenedor', component: DashboardContenedorComponent, canActivate: [AuthGuard],
    data: {
      uri: 'contenedores',
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/contenedores', texto: 'Contenedores'},
        {ruta: '/contenedores-contenedor', texto: 'Contenedor'},
      ]
    }
  },
  {
    path: 'incidencias', component: DashboardIncidenciasComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/incidencias', texto: 'Incidencias'},
      ]
    }
  },
  {
    path: 'incidencia-crear', component: IncidenciaComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/incidencias', texto: 'Incidencias'},
        {ruta: '/incidencia-crear', texto: 'Nueva incidencia'},
      ]
    }
  },
  {
    path: 'incidencia-editar/:id', component: IncidenciaComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/incidencias', texto: 'Incidencias'},
        {ruta: '/incidencia-editar', texto: 'Editar incidencia'},
      ]
    }
  },
  {
    path: 'anillos', component: DashboardAnillosComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/anillos', texto: 'Anillos'},
      ]
    }
  },
  {
    path: 'anillos-anillo/:serial', component: DashboardAnilloComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/anillos', texto: 'Anillos'},
        {ruta: '/anillos-anillo', texto: 'Anillo'},
      ]
    }
  },
  {
    path: 'anillo-crear', component: AnilloComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/anillos', texto: 'Anillos'},
        {ruta: '/anillo-crear', texto: 'Nuevo anillo'},
      ]
    }
  },
  {
    path: 'anillo-editar/:id', component: AnilloComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/anillos', texto: 'Anillos'},
        {ruta: '/anillo-editar', texto: 'Editar anillo'},
      ]
    }
  },
  {
    path: 'electronicas', component: DashboardElectronicasComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/electronicas', texto: 'Electrónicas'},
      ]
    }
  },
  {
    path: 'electronica-crear', component: ElectronicasComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/electronicas', texto: 'Electrónicas'},
        {ruta: '/electronica-crear', texto: 'Nueva electrónica'},
      ]
    }
  },
  {
    path: 'electronica-editar/:id', component: ElectronicasComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/electronicas', texto: 'Electrónicas'},
        {ruta: '/electronica-editar', texto: 'Editar electrónica'},
      ]
    }
  },
  {
    path: 'usuarios', component: DashboardUsuariosComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/usuarios', texto: 'Usuarios'},
      ]
    }
  },
  {
    path: 'usuario-crear', component: UsuarioComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/usuarios', texto: 'Usuarios'},
        {ruta: '/usuario-crear', texto: 'Nuevo usuario'},
      ]
    }
  },
  {
    path: 'usuario-editar/:id', component: UsuarioComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/usuarios', texto: 'Usuarios'},
        {ruta: '/usuario-editar', texto: 'Editar usuario'},
      ]
    }
  },
  {
    path: 'proyectos', component: DashboardProyectosComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/proyectos', texto: 'Proyectos'},
      ]
    }
  },
  {
    path: 'proyecto-crear', component: ProyectoComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/proyectos', texto: 'Proyectos'},
        {ruta: '/proyecto-crear', texto: 'Nuevo proyecto'},
      ]
    }
  },
  {
    path: 'proyecto-editar/:id', component: ProyectoComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/proyectos', texto: 'Proyectos'},
        {ruta: '/proyecto-editar', texto: 'Editar proyecto'},
      ]
    }
  },
  {
    path: 'grupos', component: DashboardGruposComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/grupos', texto: 'Grupos'},
      ]
    }
  },
  {
    path: 'grupo-crear', component: GrupoComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/grupos', texto: 'Grupos'},
        {ruta: '/grupo-crear', texto: 'Nuevo grupo'},
      ]
    }
  },
  {
    path: 'grupo-editar/:id', component: GrupoComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/grupos', texto: 'Proyectos'},
        {ruta: '/grupo-editar', texto: 'Editar grupo'},
      ]
    }
  },  
  {
    path: 'firmwares', component: DashboardFirmwaresComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/firmwares', texto: 'Telecarga'},
      ]
    }
  },
  {
    path: 'firmware-crear', component: FirmwareComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/firmwares', texto: 'Telecarga'},
        {ruta: '/firmware-crear', texto: 'Nueva Telecarga'},
      ]
    }
  },
  {
    path: 'firmware-editar/:id', component: FirmwareComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/firmwares', texto: 'Telecarga'},
        {ruta: '/firmware-editar', texto: 'Editar Telecarga'},
      ]
    }
  },
  {
    path: 'firmwares-firmware/:id', component: DashboardFirmwareComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/firmwares', texto: 'Telecarga'},
        {ruta: '/firmwares-firmware', texto: 'Actualizar Versión Telecarga'},
      ]
    }
  },
  {
    path: 'configuration', component: ConfigurationDashboardComponent, canActivate: [AuthGuard],
    data: {
      rutas: [
        {ruta: '/inicio', texto: 'Inicio'},
        {ruta: '/configuration', texto: 'Configuración'},
      ]
    }
  },
  { path: '**', redirectTo: '/inicio' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
